var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { FormLabel, Grid, IconButton, TextField, Tooltip, Typography, } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatacenterSelectorDialog } from "./DatacenterSelectorDialog";
import { SplitButton } from "../SplitButton";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { writeFileInVFS } from "../../apps/utils";
export function DatacenterSelector({ pyodide, hookFormRegisterFunc, setValue, formKey, label, error, disabled = false, datacenterArgs, defaultValue, }) {
    const [openDialog, setOpenDialog] = useState(false);
    const formValue = useWatch({
        name: formKey,
        defaultValue: defaultValue,
    });
    const [fileLabel, setFileLabel] = useState("");
    const hasSelection = !!formValue;
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (defaultValue) {
            setFileLabel("default");
        }
    }, []);
    useEffect(() => {
        if (!formValue) {
            setFileLabel("");
            return;
        }
        let label = formValue;
        if (formValue.startsWith("dc://")) {
            const dcFileNameSplit = formValue.split("=");
            if (dcFileNameSplit.length <= 1)
                return;
            label = dcFileNameSplit[1];
        }
        else {
            const fileName = formValue.split("/").pop();
            if (fileName)
                label = fileName;
        }
        setFileLabel(label);
    }, [formValue]);
    const clearSelection = () => {
        setValue(formKey, "");
    };
    const handleClick = (option, data) => __awaiter(this, void 0, void 0, function* () {
        switch (option) {
            case "datacenter":
                setOpenDialog(true);
                break;
            case "local":
                setIsLoading(true);
                const filePath = yield writeFileInVFS(pyodide, data);
                setIsLoading(false);
                setValue(formKey, filePath);
                break;
            default:
                throw Error("unknown option!");
        }
    });
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleSelect = (e) => {
        setValue(formKey, `dc://${e.id}`);
        setFileLabel(e.filepath);
        setOpenDialog(false);
    };
    const _a = hookFormRegisterFunc(formKey, {
        disabled: disabled,
        defaultValue: defaultValue,
    }), { ref } = _a, inputProps = __rest(_a, ["ref"]);
    const [selectedOptionIdx, setSelectedOptionIdx] = useState(0);
    const handleOptionIdxChange = (newIdx) => {
        setSelectedOptionIdx(newIdx);
    };
    return (_jsxs(Grid, Object.assign({ container: true, direction: "column" }, { children: [_jsxs(Grid, Object.assign({ item: true, container: true, direction: "row", justifyContent: "space-between", alignItems: "center", spacing: 1, xs: true }, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto", sx: { display: "none" } }, { children: _jsx(TextField, Object.assign({ inputRef: ref }, inputProps, { defaultValue: defaultValue, disabled: disabled, label: label, error: !!error, helperText: error, inputProps: { readOnly: true }, size: "small", sx: { width: "100%" } })) })), _jsx(Grid, Object.assign({ item: true, zeroMinWidth: true }, { children: _jsx(FormLabel, { children: label }) })), _jsx(Grid, Object.assign({ item: true, xs: true, zeroMinWidth: true }, { children: _jsx(Tooltip, Object.assign({ title: fileLabel, arrow: true, placement: "top" }, { children: _jsx(Typography, Object.assign({ style: { textOverflow: "ellipsis", overflow: "hidden" } }, { children: fileLabel })) })) })), _jsxs(Grid, Object.assign({ item: true, xs: "auto" }, { children: [" ", hasSelection ? (_jsx(Tooltip, Object.assign({ title: "Clear", arrow: true, placement: "right" }, { children: _jsx(IconButton, Object.assign({ onClick: clearSelection, size: "small" }, { children: _jsx(DeleteIcon, { fontSize: "small" }) })) }))) : (_jsx(SplitButton, { onClick: handleClick, options: options, isLoading: isLoading, selectedOptionIdx: selectedOptionIdx, handleOptionIdxChange: handleOptionIdxChange, disabled: disabled }))] }))] })), error ? (_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(Typography, Object.assign({ variant: "caption", sx: { color: "#d32f2f" } }, { children: error })) }))) : null, _jsx(DatacenterSelectorDialog, { pyodide: pyodide, open: openDialog, handleClose: handleCloseDialog, handleSelect: handleSelect, datasetArgs: datacenterArgs })] })));
}
const options = [
    {
        label: "From datacenter",
        key: "datacenter",
        isInput: false,
        icon: _jsx(CloudDownloadIcon, {}),
    },
    {
        label: "From local file system",
        key: "local",
        isInput: true,
        icon: _jsx(DriveFolderUploadIcon, {}),
    },
];
