// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { catchIndexEdgeCasesAndBark, selectSingleIndex, catchSequenceEdgeCasesAndBark, constructSequenceFromEndpoints, selectIndexSequence, } from "./utils/parser-utils";
function indexSelector(inputString, refArray) {
    const selectedEntries = [];
    if (inputString.toLowerCase() === "all") {
        const sequence = constructSequenceFromEndpoints(1, refArray.length);
        selectedEntries.push(...selectIndexSequence(refArray, sequence));
        return selectedEntries;
    }
    const compoundIndices = inputString.split(",");
    compoundIndices.forEach((idx) => {
        const index = /^\s*-?\d*\s*$/; /* integer (positive or negative) */
        const indexSeq = /^\s*\d+-+\d+\d*\s*$/; /* integer, hyphen, integer */
        const isIndex = index.test(idx);
        const isIndexSeq = indexSeq.test(idx);
        catchIndexEdgeCasesAndBark(idx);
        if (!isIndex && !isIndexSeq)
            throw new Error(`"${idx}" is not index or index sequence!`);
        if (isIndex)
            selectedEntries.push(selectSingleIndex(refArray, Number(idx)));
        if (isIndexSeq) {
            const seqEndpoints = idx.split("-");
            const start = Number(seqEndpoints[0]);
            const end = Number(seqEndpoints[1]);
            catchSequenceEdgeCasesAndBark(Number(idx), start, end, refArray);
            const sequence = constructSequenceFromEndpoints(start, end);
            selectedEntries.push(...selectIndexSequence(refArray, sequence));
        }
    });
    return selectedEntries;
}
export default indexSelector;
