import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * @author Timur Kuzhagaliyev <tim.kuzh@gmail.com>
 * @author Panagiotis Tourlas <p.tourlas@acellera.com>
 * @copyright 2022
 * @license MIT
 */
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import { forwardRef, useCallback, useContext } from "react";
import { selectFileActionData } from "../../redux/selectors";
import { useParamSelector } from "../../redux/store";
import { CustomVisibilityState } from "../../types/action.types";
import { useFileActionProps, useFileActionTrigger, } from "../../util/file-actions";
import { useLocalizedFileActionStrings } from "../../util/i18n";
import { ChonkyIconContext } from "../../util/icon-helper";
import { c, important, makeGlobalChonkyStyles } from "../../util/styles";
export const ToolbarDropdownButton = forwardRef((props, ref) => {
    const { text, active, icon, onClick, disabled } = props;
    const classes = useStyles();
    const ChonkyIcon = useContext(ChonkyIconContext);
    const className = c({
        [classes.baseButton]: true,
        [classes.activeButton]: active,
    });
    return (_jsxs(MenuItem, Object.assign({ ref: ref, className: className, onClick: onClick, disabled: disabled }, { children: [icon && (_jsx(ListItemIcon, Object.assign({ className: classes.icon }, { children: _jsx(ChonkyIcon, { icon: icon, fixedWidth: true }) }))), _jsx(ListItemText, Object.assign({ primaryTypographyProps: { className: classes.text } }, { children: text }))] })));
});
const useStyles = makeGlobalChonkyStyles((theme) => ({
    baseButton: {
        lineHeight: important(theme.toolbar.lineHeight),
        height: important(theme.toolbar.size),
        minHeight: important("auto"),
        minWidth: important("auto"),
        padding: important(20),
    },
    icon: {
        fontSize: important(theme.toolbar.fontSize),
        minWidth: important("auto"),
        color: important("inherit"),
        marginRight: 8,
    },
    text: {
        fontSize: important(theme.toolbar.fontSize),
    },
    activeButton: {
        color: important(theme.colors.textActive),
    },
}));
export const SmartToolbarDropdownButton = forwardRef((props, ref) => {
    const { fileActionId, onClickFollowUp } = props;
    const action = useParamSelector(selectFileActionData, fileActionId);
    const triggerAction = useFileActionTrigger(fileActionId);
    const { icon, active, disabled } = useFileActionProps(fileActionId);
    const { buttonName } = useLocalizedFileActionStrings(action);
    // Combine external click handler with internal one
    const handleClick = useCallback(() => {
        triggerAction();
        if (onClickFollowUp)
            onClickFollowUp();
    }, [onClickFollowUp, triggerAction]);
    if (!action)
        return null;
    const { button } = action;
    if (!button)
        return null;
    if (action.customVisibility !== undefined &&
        action.customVisibility() === CustomVisibilityState.Hidden)
        return null;
    return (_jsx(ToolbarDropdownButton, { ref: ref, text: buttonName, icon: icon, onClick: handleClick, active: active, disabled: disabled }));
});
