var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Tooltip, Typography } from "@mui/material";
import { useRef, useState } from "react";
export function TypographyWithTooltipIfTruncated(_a) {
    var { typopgraphyText, typographySx, typographyGutterBottom, typographyVariant, truncateStart } = _a, tooltipPops = __rest(_a, ["typopgraphyText", "typographySx", "typographyGutterBottom", "typographyVariant", "truncateStart"]);
    const textRef = useRef(null);
    const [enableTooltip, setEnableTooltip] = useState(false);
    const handleMouseOver = () => {
        var _a, _b;
        const cw = (_a = textRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth;
        const sw = (_b = textRef.current) === null || _b === void 0 ? void 0 : _b.scrollWidth;
        if (cw && sw)
            setEnableTooltip(sw > cw);
    };
    const tooltipRequiredStyle = {
        textTransform: "none",
        textAlign: "left",
        width: "100%",
    };
    const truncateStartSx = truncateStart
        ? {
            direction: "rtl",
            whiteSpace: "nowrap",
            "&:after": {
                position: "absolute",
            },
        }
        : {};
    return (_jsx(Box, Object.assign({ onMouseOver: handleMouseOver }, { children: _jsx(Tooltip, Object.assign({ disableHoverListener: !enableTooltip }, tooltipPops, { children: _jsx(Typography, Object.assign({ ref: textRef, noWrap: true, gutterBottom: typographyGutterBottom, variant: typographyVariant, sx: Object.assign(Object.assign(Object.assign({}, tooltipRequiredStyle), (typographySx ? typographySx : {})), truncateStartSx) }, { children: typopgraphyText })) })) })));
}
