import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box } from "@mui/material";
import { FormFromManifest } from "../../FormGenerator/FormFromManifest/FormFromManifest";
import { AppListStore } from "./appList.store";
import { shallow } from "zustand/shallow";
import { useEffect } from "react";
// this component has no reason to exist (quite grim, I know, but still)
export function AppPanel({ manifest, pyodide, }) {
    const { appManifestFormFields, setAppManifestFormFields } = AppListStore((state) => ({
        appManifestFormFields: state.appManifestFormFields,
        setAppManifestFormFields: state.setAppManifestFormFields,
    }), shallow);
    const appName = manifest.name;
    const appParams = manifest.params;
    useEffect(() => {
        if (!(appName in appManifestFormFields)) {
            setAppManifestFormFields(appParams, appName);
        }
    }, [appName, appParams]);
    return (_jsx(Box, Object.assign({ sx: { pb: 2 } }, { children: appName in appManifestFormFields && (_jsx(FormFromManifest, { appManifest: manifest, pyodide: pyodide })) })));
}
