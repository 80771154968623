// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * Returns a debounced version of the function passed as argument.
 *
 * When debouncing, previous calls will be ignored until the last one.
 * By default, the debounced function will wait for 200ms until called.
 * @example const debounced_f = debounce((x) => f(x), 1000);
 * debounced_f(x);
 * */
export default function debounce(func, wait = 200) {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}
