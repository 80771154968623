var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { createVolumeRepresentationParams } from "molstar/lib/mol-plugin-state/helpers/volume-representation-params";
import { StateTransforms } from "molstar/lib/mol-plugin-state/transforms";
import { Volume } from "molstar/lib/mol-model/volume";
/*
  Give the ref to the viewer as a parameter so that TS stops complaining
  about it being possibly undefined.
*/
export function renderVolume(data, molstar, volumeParams) {
    return new Promise((resolve, reject) => {
        molstar.dataTransaction(() => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const isoVals = getMinMaxIsoValues(data);
            const _data = yield molstar.builders.data.rawData({ data: data });
            const parsed = yield ((_a = molstar.dataFormats
                .get("cube")) === null || _a === void 0 ? void 0 : _a.parse(molstar, _data));
            const firstVolume = parsed.volume;
            if (!(firstVolume === null || firstVolume === void 0 ? void 0 : firstVolume.isOk)) {
                reject();
                return;
            }
            const repr = molstar.build();
            const volume = parsed.volume;
            const volumeData = volume.cell.obj.data;
            const defaultVolumeParams = {
                visuals: (volumeParams === null || volumeParams === void 0 ? void 0 : volumeParams.type)
                    ? volumeParams.type
                    : "wireframe" /* TS is so dumb sometimes.*/,
                alpha: (volumeParams === null || volumeParams === void 0 ? void 0 : volumeParams.alpha) ? volumeParams.alpha : 0.4,
                isoValue: (volumeParams === null || volumeParams === void 0 ? void 0 : volumeParams.currentIsoValue)
                    ? volumeParams.currentIsoValue
                    : 0.0005,
                color: (volumeParams === null || volumeParams === void 0 ? void 0 : volumeParams.color) ? volumeParams.color : 0xff6600,
            };
            const representation = repr.to(parsed.volume.ref).apply(StateTransforms.Representation.VolumeRepresentation3D, createVolumeRepresentationParams(molstar, firstVolume.data, {
                type: "isosurface",
                typeParams: {
                    visuals: [defaultVolumeParams.visuals],
                    alpha: defaultVolumeParams.alpha,
                    isoValue: Volume.adjustedIsoValue(volumeData, defaultVolumeParams.isoValue, "absolute"),
                },
                color: "uniform",
                colorParams: { value: defaultVolumeParams.color },
            })).selector;
            yield repr.commit();
            const _volume = {
                //ref: representation,
                volumeData: firstVolume.data,
                params: defaultVolumeParams,
                maxIsoVal: isoVals.max,
                minIsoVal: isoVals.min,
                visibility: true,
            };
            resolve([_volume, representation]);
        }));
    });
}
function getMinMaxIsoValues(cubeFileData) {
    const lines = cubeFileData.split("\n");
    for (let i = 0; i < 7; i++) {
        lines.shift();
    }
    const uniqueValues = new Set();
    lines.forEach((line) => {
        const numbers = line.split(/\s+/);
        numbers.shift();
        numbers.forEach((num) => uniqueValues.add(parseFloat(num)));
    });
    const sortedValues = Array.from(uniqueValues).sort((a, b) => a - b);
    return {
        min: sortedValues[0],
        max: sortedValues[sortedValues.length - 1],
    };
}
