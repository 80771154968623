import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography, Box, IconButton, Tooltip, } from "@mui/material";
function sortExceptFirst(arr) {
    if (arr.length <= 1) {
        return arr;
    }
    const firstElement = arr[0];
    const restArray = arr.slice(1).sort((a, b) => {
        if (a.name < b.name)
            return -1;
        if (a.name > b.name)
            return 1;
        return 0;
    });
    return [firstElement, ...restArray];
}
import SchoolIcon from "@mui/icons-material/School";
import { useLocation } from "wouter";
export function AppCards() {
    // TO DO: Get app data from manifest instead
    const [, setLocation] = useLocation();
    const extraApps = process.env.REACT_APP_EXTRA_APPS
        ? JSON.parse(process.env.REACT_APP_EXTRA_APPS)
        : [];
    let _appData;
    if (extraApps.includes("crypticscout")) {
        appData.push({
            name: "CrypticScout",
            description: "CrypticScout scans for pockets on a receptor using small molecule probes.",
            citations: [
                {
                    url: "https://pubs.acs.org/doi/10.1021/acs.jcim.9b01209",
                    bibtex: "@article{doi:10.1021/acs.jcim.9b01209,author = {Martinez-Rosell, Gerard and Lovera, Silvia and Sands, Zara A. and De Fabritiis, Gianni},title = {PlayMolecule CrypticScout: Predicting Protein Cryptic Sites Using Mixed-Solvent Molecular Simulations},          journal = {Journal of Chemical Information and Modeling},          volume = {60},          number = {4},          pages = {2314-2324},          year = {2020},          doi = {10.1021/acs.jcim.9b01209},          }",
                },
            ],
            image: "/static/images/landingPage/crypticpocket_thumbnail.png",
        });
        _appData = sortExceptFirst(appData);
    }
    else {
        _appData = appData;
    }
    return (_jsx(Box, Object.assign({ sx: {
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignContent: "stretch",
            justifyContent: "space-evenly",
            flexDirection: "row",
            gap: 2,
        } }, { children: _appData.map((app) => (_jsx(Box, { children: _jsxs(Card, Object.assign({ sx: { width: "20rem" } }, { children: [_jsxs(CardActionArea, Object.assign({ onClick: () => {
                            if (app.name === "PlayMolecule Viewer")
                                setLocation("/");
                            else
                                setLocation(`/tools/${app.name.toLowerCase()}`);
                        } }, { children: [_jsx(CardMedia, { component: "img", height: "140", image: app.image }), _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ gutterBottom: true, variant: "h5", component: "div", sx: { color: "rgb(11, 83, 148)" } }, { children: app.name })), _jsx(Typography, Object.assign({ variant: "body2", color: "text.secondary", sx: { height: "5rem", overflowY: "auto" } }, { children: app.description }))] })] })), _jsxs(CardActions, Object.assign({ sx: {
                            display: "flex",
                            justifyContent: "space-between",
                            // gap: 2,
                        } }, { children: [_jsx(Button, Object.assign({ size: "small", color: "secondary", href: "https://www.acellera.com/contact-us", target: "_blank", rel: "noopener" }, { children: "Request collaboration" })), !!app.citations && (_jsx(Tooltip, Object.assign({ title: "Read publication" }, { children: _jsx(IconButton, Object.assign({ color: "primary", size: "small", href: app.citations[0].url, target: "_blank", rel: "noreferrer" }, { children: _jsx(SchoolIcon, {}) })) })))] }))] })) }, `landing-apps-${app.name.replace(" ", "_")}`))) })));
}
const appData = [
    {
        name: "PlayMolecule Viewer",
        description: "Web-based data visualization toolkit designed to streamline the exploration of data resulting from computer-aided drug design efforts.",
        citations: [
            {
                url: "https://pubs.acs.org/doi/10.1021/acs.jcim.3c01776",
                bibtex: "citation_bibtex: |\n@article{doi:10.1021/acs.jcim.3c01776,author = {Torrens-Fontanals, Mariona and Tourlas, Panagiotis and Doerr, Stefan and De Fabritiis, Gianni},title = {PlayMolecule Viewer: A Toolkit for the Visualization of Molecules and Other Data},journal = {Journal of Chemical Information and Modeling},volume = {64},number = {3},pages = {584-589},year = {2024},doi = {10.1021/acs.jcim.3c01776},    note ={PMID: 38266194}URL = { https://doi.org/10.1021/acs.jcim.3c01776},eprint = { https://doi.org/10.1021/acs.jcim.3c01776}}",
            },
        ],
        image: "/static/images/landingPage/pmviewer_thumbnail.png",
    },
    {
        name: "AceDock",
        description: "AceDock docks small molecules to proteins. It provides a variety of models including free docking, restraints docking and tethered docking.",
        citations: [
            {
                url: "https://pubs.acs.org/doi/10.1021/acs.jcim.0c00143",
                bibtex: "citation_bibtex: |\n@article{doi:10.1021/acs.jcim.0c00143,\nauthor = {Varela-Rial, Alejandro and Majewski, Maciej and Cuzzolin, Alberto and Martínez-Rosell, Gerard and De Fabritiis, Gianni},\ntitle = {SkeleDock: A Web Application for Scaffold Docking in PlayMolecule},\njournal = {Journal of Chemical Information and Modeling},\nvolume = {60},\nnumber = {6},\npages = {2673-2677},\nyear = {2020},\ndoi = {10.1021/acs.jcim.0c00143},\nnote ={PMID: 32407111},\n}",
            },
        ],
        image: "/static/images/landingPage/acedock_thumbnail.png",
    },
    {
        name: "AcePrep",
        description: "Protonate, generate 3D conformers and generally prepare a ligand dataset in SDF format.",
        image: "/static/images/landingPage/aceprep_thumbnail.png",
    },
    {
        name: "AceProfiler",
        description: "AceProfiler searches for deposited structures of proteins similar to your query (or identical, if you provide a uniprot ID) and aligns them to a common reference.",
        image: "/static/images/landingPage/aceprofiler_thumbnail.png",
    },
    {
        name: "DeepSite",
        description: "Predict ligand binding pockets in your protein of interest using a neural network-based predictor.",
        citations: [
            {
                url: "https://academic.oup.com/bioinformatics/article/33/19/3036/3859178",
                bibtex: "@article{jimenez2017deepsite,\ntitle={DeepSite: protein-binding site predictor using 3D-convolutional neural networks},\nauthor={Jim{\\'e}nez, Jos{\\'e} and Doerr, Stefan and Mart{\\'i}nez-Rosell, Gerard and Rose, Alexander S and De Fabritiis, Gianni},\njournal={Bioinformatics},\nvolume={33},\nnumber={19},\npages={3036--3042},\nyear={2017},\npublisher={Oxford University Press}\n}",
            },
        ],
        image: "/static/images/landingPage/deepsite_thumbnail.png",
    },
    {
        name: "Generative",
        description: "A fast and chemically reasonable generative model based on SMILES fragmentation patterns.",
        image: "/static/images/landingPage/generative_thumbnail.png",
    },
    {
        name: "KDeep",
        description: "Predict the binding affinity of a set of ligands docked in a protein using a state-of-the-art neural network-based predictor.",
        citations: [
            {
                url: "http://pubs.acs.org/doi/10.1021/acs.jcim.7b00650",
                bibtex: "@article{jimenez2018k,\ntitle={K DEEP: protein--ligand absolute binding affinity prediction via 3D-convolutional neural networks},\nauthor={Jim{\\'e}nez, Jos{\\'e} and Skalic, Miha and Martinez-Rosell, Gerard and De Fabritiis, Gianni},\njournal={Journal of chemical information and modeling},\nvolume={58},\nnumber={2},\npages={287--296},\nyear={2018},\npublisher={ACS Publications}\n}",
            },
        ],
        image: "/static/images/landingPage/kdeep_thumbnail.png",
    },
    {
        name: "KDeepTrainer",
        description: "Trains a Kdeep affinity predictor model on a user-provided data set. The resulting model can then be used in the Kdeep app.",
        citations: [
            {
                url: "http://pubs.acs.org/doi/10.1021/acs.jcim.7b00650",
                bibtex: "@article{jimenez2018k,\ntitle={K DEEP: protein--ligand absolute binding affinity prediction via 3D-convolutional neural networks},\nauthor={Jim{\\'e}nez, Jos{\\'e} and Skalic, Miha and Martinez-Rosell, Gerard and De Fabritiis, Gianni},\njournal={Journal of chemical information and modeling},\nvolume={58},\nnumber={2},\npages={287--296},\nyear={2018},\npublisher={ACS Publications}\n}",
            },
        ],
        image: "/static/images/landingPage/kdeeptrainer_thumbnail.png",
    },
    {
        name: "MembraneBuilder",
        description: "Build bilayer membranes of any size and lipid composition.",
        citations: [
            {
                url: "https://pubs.acs.org/doi/10.1021/acs.jctc.7b00480",
                bibtex: "@article{doi:10.1021/acs.jctc.7b00480,\nauthor = {Doerr, Stefan and Giorgino, Toni and Martínez-Rosell, Gerard and Damas, João M. and De Fabritiis, Gianni},\ntitle = {High-Throughput Automated Preparation and Simulation of Membrane Proteins with HTMD},\njournal = {Journal of Chemical Theory and Computation},\nvolume = {13},\nnumber = {9},\npages = {4003-4011},\nyear = {2017},\ndoi = {10.1021/acs.jctc.7b00480},\nnote ={PMID: 28723224},\n}",
            },
        ],
        image: "/static/images/landingPage/membranebuilder_thumbnail.png",
    },
    {
        name: "Parameterize",
        description: "Parameterize application assigns parameters to small molecules and fixes dihedral angle parameters using neural network potentials.",
        citations: [
            {
                url: "https://pubs.acs.org/doi/full/10.1021/acs.jcim.9b00439",
                bibtex: "@article{galvelis2019scalable,\ntitle={A Scalable Molecular Force Field Parameterization Method Based on Density Functional Theory and Quantum-Level Machine Learning},\nauthor={Galvelis, Raimondas and Doerr, Stefan and Damas, Jo{\\~a}o M and Harvey, Matt J and De Fabritiis, Gianni},\njournal={Journal of chemical information and modeling},\nvolume={59},\nnumber={8},\npages={3485--3493},\nyear={2019},\npublisher={ACS Publications}\n}",
            },
        ],
        image: "/static/images/landingPage/parameterize_thumbnail.png",
    },
    {
        name: "PKace",
        description: "Machine learning tool and model for the estimation of micro-pKa values of protonation sites in small molecules.",
        image: "/static/images/landingPage/pkace_thumbnail.png",
    },
    {
        name: "PlexView",
        description: "PlexView is an application that computes and visualizes protein-ligand interactions.",
        image: "/static/images/landingPage/plexview_thumbnail.png",
    },
    {
        name: "ProteinPrepare",
        description: "ProteinPrepare despite it's name prepares proteins but also other systems including nucleic acids.",
        citations: [
            {
                url: "https://pubs.acs.org/doi/abs/10.1021/acs.jcim.7b00190",
                bibtex: "",
            },
        ],
        image: "/static/images/landingPage/proteinprepare_thumbnail.png",
    },
    {
        name: "SimpleRun",
        description: "SimpleRun executes equilibration and production MD runs.",
        image: "/static/images/landingPage/simplerun_thumbnail.png",
        citations: [
            {
                url: "https://pubs.acs.org/doi/10.1021/acs.jctc.6b00049",
                bibtex: "",
            },
        ],
    },
    {
        name: "SystemBuilder",
        description: "SystemBuilder builds systems for MD simulation.",
        citations: [
            {
                url: "https://doi.org/10.1021/acs.jctc.6b00049",
                bibtex: "@article{doi:10.1021/acs.jctc.6b00049,\nauthor = {Doerr, S. and Harvey, M. J. and Noé, Frank and De Fabritiis, G.},\ntitle = {HTMD: High-Throughput Molecular Dynamics for Molecular Discovery},\njournal = {Journal of Chemical Theory and Computation},\nvolume = {12},\nnumber = {4},\npages = {1845-1852},\nyear = {2016},\ndoi = {10.1021/acs.jctc.6b00049},\n note ={PMID: 26949976}\n}",
            },
        ],
        image: "/static/images/landingPage/systembuilder_thumbnail.png",
    },
];
