import { Logger } from "./logger";
export class FileHelper {
    static isDirectory(file) {
        // Not a directory by default
        return !!file && file.isDir === true;
    }
    static isHidden(file) {
        // Not hidden by default
        return !!file && file.isHidden === true;
    }
    static isSymlink(file) {
        // Not a symlink by default
        return !!file && file.isSymlink === true;
    }
    static isEncrypted(file) {
        // Not encrypted by default
        return !!file && file.isEncrypted === true;
    }
    static isClickable(file) {
        // Clickable by default
        return !!file;
    }
    static isOpenable(file) {
        // Openable by default
        return !!file && file.openable !== false;
    }
    static isSelectable(file) {
        // Selectable by default
        return !!file && file.selectable !== false;
    }
    static isDraggable(file) {
        // File & folders are draggable by default, `null` is not
        return !!file && file.draggable !== false;
    }
    static isDroppable(file) {
        // Folders are droppable by default, files are not
        if (!file)
            return false;
        if (file.isDir && file.droppable !== false)
            return true;
        return file.droppable === true;
    }
    static isDndOpenable(file) {
        // Folders are DnD openable by default, files are not
        if (!FileHelper.isOpenable(file))
            return false;
        if (file.isDir && file.dndOpenable !== false)
            return true;
        return file.dndOpenable === true;
    }
    static getModDate(file) {
        if (!file || file.modDate === null || file.modDate === undefined)
            return null;
        return FileHelper.parseDate(file.modDate);
    }
    static parseDate(maybeDate) {
        if (typeof maybeDate === "string" || typeof maybeDate === "number") {
            // We allow users to provide string and numerical representations of dates.
            try {
                return new Date(maybeDate);
            }
            catch (error) {
                Logger.error(`Could not convert provided string/number into a date: ${error.message} `, "Invalid value:", maybeDate);
            }
        }
        if (maybeDate instanceof Date && !isNaN(maybeDate.getTime())) {
            // We only allow valid dates objects
            return maybeDate;
        }
        // If we have an invalid date representation, we just return null.
        Logger.warn("Unsupported date representation:", maybeDate);
        return null;
    }
    static getChildrenCount(file) {
        if (!file || typeof file.childrenCount !== "number")
            return null;
        return file.childrenCount;
    }
}
