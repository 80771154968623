import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState, memo } from "react";
import { useSelector } from "react-redux";
import { selectFileData, selectIsDnDDisabled, selectIsFileSelected, } from "../../redux/selectors";
import { useParamSelector } from "../../redux/store";
import { FileViewMode } from "../../types/file-view.types";
import { FileHelper } from "../../util/file-helper";
import { makeGlobalChonkyStyles } from "../../util/styles";
import { ClickableWrapper, } from "../internal/ClickableWrapper";
import { CompactEntry } from "./CompactEntry";
import { useFileClickHandlers } from "./FileEntry-hooks";
import { GridEntry } from "./GridEntry";
import { ListEntry } from "./ListEntry";
const disabledDndState = {
    dndIsDragging: false,
    dndIsOver: false,
    dndCanDrop: false,
};
export const SmartFileEntry = memo(({ fileId, displayIndex, fileViewMode }) => {
    const classes = useStyles();
    // Basic properties
    const file = useParamSelector(selectFileData, fileId);
    const selected = useParamSelector(selectIsFileSelected, fileId);
    const dndDisabled = useSelector(selectIsDnDDisabled);
    // Clickable wrapper properties
    const fileClickHandlers = useFileClickHandlers(file, displayIndex);
    const [focused, setFocused] = useState(false);
    const clickableWrapperProps = Object.assign(Object.assign({ wrapperTag: "div", passthroughProps: { className: classes.fileEntryClickableWrapper } }, (FileHelper.isClickable(file) ? fileClickHandlers : undefined)), { setFocused, children: {} });
    // File entry properties
    const fileEntryProps = {
        file,
        selected,
        focused,
    };
    let EntryComponent;
    if (fileViewMode === FileViewMode.List)
        EntryComponent = ListEntry;
    else if (fileViewMode === FileViewMode.Compact)
        EntryComponent = CompactEntry;
    else
        EntryComponent = GridEntry;
    // dndDisabled ? (
    return (_jsx(ClickableWrapper, Object.assign({}, clickableWrapperProps, { children: _jsx(EntryComponent, Object.assign({}, fileEntryProps, { dndState: disabledDndState })) })));
    // ) : (
    //   <DnDFileEntry file={file}>
    //     {(dndState) => (
    //       <ClickableWrapper {...clickableWrapperProps}>
    //         <EntryComponent {...fileEntryProps} dndState={dndState} />
    //       </ClickableWrapper>
    //     )}
    //   </DnDFileEntry>
    // );
});
SmartFileEntry.displayName = "SmartFileEntry";
const useStyles = makeGlobalChonkyStyles(() => ({
    fileEntryClickableWrapper: {
        // We disable default browser outline because Chonky provides its own outline
        // (which doesn't compromise accessibility, hopefully)
        outline: "none !important",
        position: "relative",
        height: "100%",
    },
}));
