var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "@mui/material";
import { dispatchControlledNotification, dispatchDeleteControlledNotification, } from "../../NotificationSystem/utils";
import { addPathToNap, dsInfoFromId, getFilesToDownload, } from "../../apps/results/utils";
import { loadAllFileTypes } from "../../FileLoaders";
import { flushFolderInVFS } from "../../apps/utils";
import { downloadDatasetFiles } from "../../apps/results/fetchDcData";
import { ActiveDataset } from "../datacenterBrowser.store";
export function DisplayDatasetFolder({ currentFolderContent, currentFolderId, pyodide, vss, molstar, NAPGenericStore, folderName, dataset, disabled, }) {
    const hasDCid = currentFolderContent.length > 0 &&
        /^dc:\/\/\d+$/.test(currentFolderContent[0].parentId);
    const configFile = folderName && hasDCid ? obtainConfigFile(dataset, folderName) : undefined;
    const handleClick = () => __awaiter(this, void 0, void 0, function* () {
        if (!configFile)
            return;
        const loadingDCFile = NAPGenericStore.getState().loadingDCFile;
        if (loadingDCFile)
            return;
        const { ds_id } = dsInfoFromId(currentFolderId);
        if (!ds_id)
            return;
        const dcFiles = getFilesToDownload(configFile);
        if (dcFiles.length === 0) {
            console.error("No files detected");
            return;
        }
        addPathToNap(configFile, currentFolderId);
        const setLoadingDCFile = NAPGenericStore.getState().setLoadingDCFile;
        setLoadingDCFile(true);
        dispatchControlledNotification({
            idx: currentFolderId,
            type: "info",
            message: `Loading data...`,
        });
        yield downloadDatasetFiles(ds_id, pyodide, dcFiles);
        dispatchDeleteControlledNotification({ idx: currentFolderId });
        yield loadAllFileTypes({}, configFile, vss, molstar, pyodide, NAPGenericStore);
        setLoadingDCFile(false);
        flushFolderInVFS(pyodide, "/tmp");
    });
    return configFile ? (_jsx(Button, Object.assign({ disabled: disabled, variant: "contained", sx: { m: 1 }, onClick: handleClick }, { children: "Display all" }))) : (_jsx(_Fragment, {}));
}
function obtainConfigFile(dataset, folderName) {
    if (dataset !== ActiveDataset.CATH)
        return;
    const temp = ["320", "348", "379", "413", "450"];
    const numReplicates = 5;
    const colors = [11166900, 5149386, 6339421, 16750899, 15419210];
    return {
        systems: [
            {
                name: folderName,
                type: "group",
                children: temp.map((temp, tempI) => {
                    return {
                        name: temp,
                        type: "group",
                        children: [...Array(numReplicates).keys()].map((rep) => {
                            return {
                                name: `${folderName}_${temp}_${rep}`,
                                files: [
                                    `${folderName}.psf`,
                                    `${folderName}_${temp}_${rep}.xtc`,
                                ],
                                type: "trajectory",
                                representations: [
                                    {
                                        selection: "protein",
                                        alpha: 1,
                                        color: "uniform",
                                        type: "cartoon",
                                        color_value: colors[tempI],
                                    },
                                ],
                            };
                        }),
                    };
                }),
            },
        ],
    };
}
