import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * @author Timur Kuzhagaliyev <tim.kuzh@gmail.com>
 * @author Panagiotis Tourlas <p.tourlas@acellera.com>
 * @copyright 2022
 * @license MIT
 */
import { memo } from "react";
import { makeLocalChonkyStyles } from "../../util/styles";
import { useFileNameComponent, useModifierIconComponents, } from "./FileEntry-hooks";
export const FileEntryName = memo(({ file, className }) => {
    const modifierIconComponents = useModifierIconComponents(file);
    const fileNameComponent = useFileNameComponent(file);
    const classes = useStyles();
    return (_jsxs("span", Object.assign({ className: className, title: file ? file.name : undefined }, { children: [modifierIconComponents.length > 0 && (_jsx("span", Object.assign({ className: classes.modifierIcons }, { children: modifierIconComponents }))), fileNameComponent] })));
});
FileEntryName.displayName = "FileEntryName";
const useStyles = makeLocalChonkyStyles((theme) => ({
    modifierIcons: {
        // KEEP THIS AS REF
        // color: theme.palette.text.hint,
        position: "relative",
        fontSize: "0.775em",
        paddingRight: 5,
    },
}));
