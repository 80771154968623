import { dispatchNotificationEvent } from "../NotificationSystem/utils";
import { removeSystem } from "../3dViewer/Controls/utils";
import { findSystemByKey } from "../3dViewer/stateTree";
const showDeletionNotification = (numMolecules) => {
    const msg1 = `Molecule deleted as empty after filtering.`;
    const msg2 = `Removed ${numMolecules} empty molecules after filtering.`;
    if (numMolecules === 1) {
        dispatchNotificationEvent({
            type: "warning",
            message: msg1,
        });
    }
    if (numMolecules > 1) {
        dispatchNotificationEvent({
            type: "warning",
            message: msg2,
        });
    }
};
export function deleteEmptyMolecules(emptyMolecules, vss, molstar, pyodide, closeSystemPanels, removeVisibleSystemsFiles) {
    showDeletionNotification(emptyMolecules.length);
    const loaded_structures = vss.getState().loaded_structures;
    emptyMolecules.forEach((moleculeID) => {
        const system = findSystemByKey(loaded_structures, "moleculeID", moleculeID);
        const cellRefs = system.cellRef ? system.cellRef : [""];
        // make a wrapper that only receives the ID
        removeSystem(molstar, pyodide, vss, cellRefs, system.stateTreePosition);
        if (loaded_structures.length === 0)
            closeSystemPanels();
        if (removeVisibleSystemsFiles && system.files) {
            removeVisibleSystemsFiles(system.files);
        }
    });
}
