// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import * as yup from "yup";
export const defaultValues = {
    password: "",
};
export const DeleteAccountSchema = yup.object({
    password: yup.string().required("Password is required"),
});
