import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { IconButton } from "@mui/material";
import ButtonBox from "../ButtonBox";
const ActionIconButton = ({ onClick, tooltipMsg, tooltipPlacement, disabled, active, backgroundColor, iconColor, backgroundHoverColor, iconHoverColor, onMouseOver, onMouseOut, onTooltipOpen, onTooltipClose, tooltipIsOpen, children, tooltipMenu, }) => {
    return (_jsx(ButtonBox, Object.assign({ tooltipMsg: tooltipMsg, tooltipPlacement: tooltipPlacement, disabled: disabled, active: active, backgroundColor: backgroundColor, iconColor: iconColor, backgroundHoverColor: backgroundHoverColor, iconHoverColor: iconHoverColor, onMouseOver: onMouseOver, onMouseOut: onMouseOut, tooltipMenu: tooltipMenu, onTooltipOpen: onTooltipOpen, onTooltipClose: onTooltipClose, tooltipIsOpen: tooltipIsOpen }, { children: _jsx(IconButton, Object.assign({ size: "small", onClick: onClick, disabled: disabled, onMouseOver: onMouseOver, onMouseOut: onMouseOut }, { children: children })) })));
};
export default ActionIconButton;
