// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//

import { createRoot } from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { CookiesProvider } from "react-cookie";
import { ViewerStateStore } from "molkitstar-ui";
import { priorityWorkerHandler } from "./highPriorityWorker.handler";

import "./index.css";
import NAP from "./NAP.jsx";

import p from "../package.json";

export function initiate_app(theme) {
  window.NAP_version = p.version;

  window.highPriorityWorker = new Worker(
    new URL("./highPriorityWorker.ts", import.meta.url),
    { type: "module" }
  );

  window.highPriorityMsg = (x) => {
    window.highPriorityWorker.postMessage(x);
  };
  /* 
      The event listener is added regardless of login status and will only
      fire on messages from the worker. So, no harm (I think).
    */
  window.highPriorityWorker.addEventListener("message", (msg) => {
    priorityWorkerHandler(msg);
  });

  const container = document.getElementById("root");
  const root = createRoot(container);

  function ThemedApp() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <CookiesProvider>
          <NAP vss={ViewerStateStore} />
        </CookiesProvider>
      </ThemeProvider>
    );
  }

  root.render(<ThemedApp />);
}
