import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useContext, memo } from "react";
import { useLocalizedFileEntryStrings } from "../../util/i18n";
import { ChonkyIconContext } from "../../util/icon-helper";
import { makeLocalChonkyStyles } from "../../util/styles";
import { TextPlaceholder } from "../external/TextPlaceholder";
import { useFileEntryHtmlProps, useFileEntryState } from "./FileEntry-hooks";
import { FileEntryName } from "./FileEntryName";
export const CompactEntry = memo(
// @ts-ignore
({ file, selected, focused, dndState }) => {
    const entryState = useFileEntryState(file, selected, focused);
    const { fileModDateString, fileSizeString } = useLocalizedFileEntryStrings(file);
    const classes = useStyles(entryState);
    const ChonkyIcon = useContext(ChonkyIconContext);
    const fileEntryHtmlProps = useFileEntryHtmlProps(file);
    return (_jsxs("div", Object.assign({ className: classes.listFileEntry }, fileEntryHtmlProps, { children: [_jsx("div", Object.assign({ className: classes.listFileEntryIcon }, { children: _jsx(ChonkyIcon, { icon: entryState.icon, spin: entryState.iconSpin, fixedWidth: true }) })), _jsxs("div", Object.assign({ className: classes.listFileEntryDescription }, { children: [_jsx("div", Object.assign({ className: classes.listFileEntryName, title: file ? file.name : undefined }, { children: _jsx(FileEntryName, { file: file }) })), _jsxs("div", Object.assign({ className: classes.listFileEntryProperties }, { children: [_jsx("div", Object.assign({ className: classes.listFileEntryProperty }, { children: file ? (fileModDateString !== null && fileModDateString !== void 0 ? fileModDateString : _jsx("span", { children: "\u2014" })) : (_jsx(TextPlaceholder, { minLength: 5, maxLength: 15 })) })), _jsx("div", Object.assign({ className: classes.listFileEntryProperty }, { children: file ? (fileSizeString !== null && fileSizeString !== void 0 ? fileSizeString : _jsx("span", { children: "\u2014" })) : (_jsx(TextPlaceholder, { minLength: 10, maxLength: 20 })) }))] }))] })), _jsx("div", { className: "chonky-file-entry-outline" }), _jsx("div", { className: "chonky-file-entry-selection" })] })));
});
const useStyles = makeLocalChonkyStyles((theme) => ({
    listFileEntry: {
        fontSize: theme.listFileEntry.fontSize,
        alignItems: "center",
        position: "relative",
        display: "flex",
        height: "100%",
    },
    listFileEntryIcon: {
        backgroundColor: (state) => state.color,
        boxShadow: "inset rgba(255, 255, 255, 0.5) 0 0 0 999px",
        borderRadius: theme.listFileEntry.iconBorderRadius,
        fontSize: theme.listFileEntry.iconFontSize,
        color: "#fff",
        padding: 8,
    },
    listFileEntryDescription: {
        flexDirection: "column",
        display: "flex",
        flexGrow: 1,
    },
    listFileEntryName: {
        padding: [0, 8, 4, 8],
    },
    listFileEntryProperties: {
        fontSize: theme.listFileEntry.propertyFontSize,
        flexDirection: "row",
        display: "flex",
    },
    listFileEntryProperty: {
        padding: [0, 8],
        opacity: 0.4,
    },
}));
