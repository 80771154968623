import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Fade, Grid, IconButton, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Panels } from "../GenericViewerState/SharedState";
import ListIcon from "@mui/icons-material/List";
import { systemTree } from "../UIPanels/panelDimensions";
import { ActionIconButton } from "..";
import { MolSystemsPanel } from "../3dViewer/MolSystemsPanel";
import { shallow } from "zustand/shallow";
import BarChartIcon from "@mui/icons-material/BarChart";
import { NAPPlotStore, NAPTableStore } from "../StateStores";
import { DataPortalStore } from "./dataportal.store";
import CircularProgress from "@mui/material/CircularProgress";
function SystemOptions({ NAPGenericStore, vss, molstar, pyodide, systemsPanelActive, }) {
    return (_jsx(Fade, Object.assign({ in: systemsPanelActive }, { children: _jsx(Grid, Object.assign({ container: true, direction: "column", spacing: 1 }, { children: _jsx(MolSystemsPanel, { vss: vss, molstar: molstar, pyodide: pyodide, positionInTree: [], NAPGenericStore: NAPGenericStore, simplified: true }) })) })));
}
function TargetResultsButtons({ handleClickBack, disabled, systemsPanelActive, handleClickSystems, showSystemsButton, dataVizAvailable, handleClickDataviz, sessionIsLoading, }) {
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, Object.assign({ container: true, sx: { my: 1, position: "fixed", width: "auto" }, id: "buttons-left" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(Tooltip, Object.assign({ arrow: true, enterDelay: 1000, title: "Back" }, { children: _jsx(_Fragment, { children: _jsx(IconButton, Object.assign({ color: "secondary", disabled: disabled, onClick: handleClickBack }, { children: _jsx(ArrowBackIcon, {}) })) }) })) }), "result-btn-back"), showSystemsButton && !sessionIsLoading ? (_jsx(Grid, Object.assign({ item: true, xs: "auto", sx: { height: "2.1em" } }, { children: _jsx(ActionIconButton, Object.assign({ tooltipMsg: `${systemsPanelActive ? "Hide" : "Show"} molecule visualization`, disabled: disabled, onClick: handleClickSystems, active: systemsPanelActive, iconColor: "#ffab40", backgroundHoverColor: "#ffab40", 
                            //  backgroundColor?: string;
                            iconHoverColor: "#ffffff" }, { children: _jsx(ListIcon, {}) })) }), "result-btn-menu")) : null, sessionIsLoading && (_jsx(Grid, Object.assign({ item: true, xs: "auto", sx: { height: "2.1em" } }, { children: _jsx(Tooltip, Object.assign({ arrow: true, enterDelay: 1000, title: "Loading molecules..." }, { children: _jsx(Box, Object.assign({ sx: { pl: "8px" } }, { children: _jsx(CircularProgress, { color: "secondary", size: "1.5rem", sx: { m: "0.4em" } }) })) })) }), "result-btn-loading"))] })), dataVizAvailable ? (_jsx(Box, Object.assign({ sx: { my: 1, position: "fixed", right: 60 } }, { children: _jsx(ActionIconButton, Object.assign({ tooltipMsg: "Show results", disabled: disabled, onClick: handleClickDataviz, iconColor: "#ffab40", backgroundHoverColor: "#ffab40", iconHoverColor: "#ffffff" }, { children: _jsx(BarChartIcon, {}) })) }))) : null] }));
}
export function TargetResults({ handleClickBack, disabled, NAPGenericStore, show, vss, molstar, pyodide, }) {
    const dimensions = Object.assign(Object.assign({}, systemTree), { left: 10, maxHeight: `calc(100vh  - 1.75em )`, top: 10, width: "20em" });
    const [activePanels, setActivePanels] = NAPGenericStore((state) => [state._activePanels, state.setActivePanels], shallow);
    const systemsPanelActive = activePanels.statetree;
    const handleClickSystems = () => {
        setActivePanels(Object.assign(Object.assign({}, activePanels), { [Panels.statetree]: !activePanels.statetree }));
    };
    const [loaded_structures] = vss((state) => [state.loaded_structures]);
    const showSystemsButton = loaded_structures.length > 0;
    const handleClickDataviz = () => {
        setActivePanels(Object.assign(Object.assign({}, activePanels), { [Panels.plots]: true }));
    };
    const { tables } = NAPTableStore((state) => ({
        tables: state.tables,
    }));
    const { plots } = NAPPlotStore((state) => ({
        plots: state.plots,
    }));
    const dataVizAvailable = tables.some((t) => !t.archived) || plots.some((p) => !p.archived);
    const [completedSessions, activeSession] = DataPortalStore((state) => [state.completedSessions, state.activeSession], shallow);
    const resultsAreReady = activeSession
        ? completedSessions.includes(activeSession)
        : false;
    return (_jsx(Fade, Object.assign({ in: show }, { children: _jsxs(Box, Object.assign({ sx: dimensions }, { children: [_jsx(TargetResultsButtons, { handleClickBack: handleClickBack, systemsPanelActive: systemsPanelActive, handleClickSystems: handleClickSystems, disabled: disabled, showSystemsButton: showSystemsButton, dataVizAvailable: dataVizAvailable, handleClickDataviz: handleClickDataviz, sessionIsLoading: !resultsAreReady }), _jsx(Box, Object.assign({ sx: {
                        mt: "3.7em",
                        overflowY: "auto",
                        maxHeight: `calc(100vh  - 5em )`,
                        position: "fixed",
                        display: "block",
                        direction: "rtl",
                        overflowX: "hidden",
                    } }, { children: _jsx(Box, Object.assign({ sx: { direction: "ltr", m: 0, p: 0 } }, { children: _jsx(SystemOptions, { NAPGenericStore: NAPGenericStore, vss: vss, molstar: molstar, pyodide: pyodide, systemsPanelActive: systemsPanelActive }) })) }))] })) }), "dataportal-results"));
}
