import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { RoundActionIconButton } from "../../components/Buttons/ActionIconButton/RoundActionIconButton";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
export function ToggleNavigationMode({ handleClick, isActive, }) {
    return (_jsx(RoundActionIconButton, Object.assign({ size: "small", tooltipMsg: "Navigation mode", active: isActive, onClick: handleClick }, { children: _jsx(SwitchLeftIcon, { fontSize: "small" }) })));
}
