import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useCallback, useContext, useEffect, useMemo, useRef, useState, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChonkyActions } from "../../action-definitions/index";
import { selectThumbnailGenerator } from "../../redux/selectors";
import { thunkRequestFileAction } from "../../redux/thunks/dispatchers.thunks";
import { ChonkyIconName } from "../../types/icons.types";
import { FileHelper } from "../../util/file-helper";
import { ChonkyIconContext, ColorsDark, ColorsLight, useIconData, } from "../../util/icon-helper";
import { Logger } from "../../util/logger";
import { TextPlaceholder } from "../external/TextPlaceholder";
export const useFileEntryHtmlProps = (file) => {
    return useMemo(() => {
        const dataProps = {
            "data-test-id": "file-entry",
            "data-chonky-file-id": file ? file.id : undefined,
        };
        return Object.assign({ role: "listitem" }, dataProps);
    }, [file]);
};
export const useFileEntryState = (file, selected, focused) => {
    const iconData = useIconData(file);
    const { thumbnailUrl, thumbnailLoading } = useThumbnailUrl(file);
    return useMemo(() => {
        const fileColor = thumbnailUrl
            ? ColorsDark[iconData.colorCode]
            : ColorsLight[iconData.colorCode];
        const iconSpin = thumbnailLoading || !file;
        const icon = thumbnailLoading ? ChonkyIconName.loading : iconData.icon;
        return {
            childrenCount: FileHelper.getChildrenCount(file),
            icon: file && file.icon !== undefined ? file.icon : icon,
            iconSpin: iconSpin,
            thumbnailUrl: thumbnailUrl,
            color: file && file.color !== undefined ? file.color : fileColor,
            selected: selected,
            focused: !!focused,
        };
    }, [file, focused, iconData, selected, thumbnailLoading, thumbnailUrl]);
};
export const useDndIcon = (dndState) => {
    let dndIconName = null;
    if (dndState.dndIsOver) {
        const showDropIcon = dndState.dndCanDrop;
        dndIconName = showDropIcon
            ? ChonkyIconName.dndCanDrop
            : ChonkyIconName.dndCannotDrop;
    }
    else if (dndState.dndIsDragging) {
        dndIconName = ChonkyIconName.dndDragging;
    }
    return dndIconName;
};
export const useModifierIconComponents = (file) => {
    const modifierIcons = useMemo(() => {
        const modifierIcons = [];
        if (FileHelper.isHidden(file))
            modifierIcons.push(ChonkyIconName.hidden);
        if (FileHelper.isSymlink(file))
            modifierIcons.push(ChonkyIconName.symlink);
        if (FileHelper.isEncrypted(file))
            modifierIcons.push(ChonkyIconName.lock);
        return modifierIcons;
    }, [file]);
    const ChonkyIcon = useContext(ChonkyIconContext);
    const modifierIconComponents = useMemo(() => modifierIcons.map((icon, index) => (_jsx(ChonkyIcon, { icon: icon }, `file-modifier-${index}`))), 
    // For some reason ESLint marks `ChonkyIcon` as an unnecessary dependency,
    // but we expect it can change at runtime so we disable the check.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ChonkyIcon, modifierIcons]);
    return modifierIconComponents;
};
const _extname = (fileName) => {
    const parts = fileName.split(".");
    if (parts.length) {
        return `.${parts[parts.length - 1]}`;
    }
    return "";
};
export const useFileNameComponent = (file) => {
    return useMemo(() => {
        var _a;
        if (!file)
            return _jsx(TextPlaceholder, { minLength: 15, maxLength: 20 });
        let name;
        let extension = null;
        const isDir = FileHelper.isDirectory(file);
        if (isDir) {
            name = file.name;
        }
        else {
            const _file = file;
            extension = (_a = _file.ext) !== null && _a !== void 0 ? _a : _extname(_file.name);
            name = _file.name.substr(0, _file.name.length - extension.length);
        }
        return (_jsxs(_Fragment, { children: [name, extension && (_jsx("span", Object.assign({ className: "chonky-file-entry-description-title-extension" }, { children: extension })))] }));
    }, [file]);
};
export const useThumbnailUrl = (file) => {
    const thumbnailGenerator = useSelector(selectThumbnailGenerator);
    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    const [thumbnailLoading, setThumbnailLoading] = useState(false);
    const loadingAttempts = useRef(0);
    useEffect(() => {
        let loadingCancelled = false;
        if (file) {
            if (thumbnailGenerator) {
                if (loadingAttempts.current === 0) {
                    setThumbnailLoading(true);
                }
                loadingAttempts.current++;
                Promise.resolve()
                    .then(() => thumbnailGenerator(file))
                    .then((thumbnailUrl) => {
                    if (loadingCancelled)
                        return;
                    setThumbnailLoading(false);
                    if (thumbnailUrl && typeof thumbnailUrl === "string") {
                        setThumbnailUrl(thumbnailUrl);
                    }
                })
                    .catch((error) => {
                    if (!loadingCancelled)
                        setThumbnailLoading(false);
                    Logger.error(`User-defined "thumbnailGenerator" handler threw an error: ${error.message}`);
                });
            }
            else if (file.thumbnailUrl) {
                setThumbnailUrl(file.thumbnailUrl);
            }
        }
        return () => {
            loadingCancelled = true;
        };
    }, [file, setThumbnailUrl, setThumbnailLoading, thumbnailGenerator]);
    return { thumbnailUrl, thumbnailLoading };
};
export const useFileClickHandlers = (file, displayIndex) => {
    const dispatch = useDispatch();
    // Prepare base handlers
    const onMouseClick = useCallback((event, clickType) => {
        if (!file)
            return;
        dispatch(thunkRequestFileAction(ChonkyActions.MouseClickFile, {
            clickType,
            file,
            fileDisplayIndex: displayIndex,
            altKey: event.altKey,
            ctrlKey: event.ctrlKey,
            shiftKey: event.shiftKey,
        }));
    }, [dispatch, file, displayIndex]);
    const onKeyboardClick = useCallback((event) => {
        if (!file)
            return;
        dispatch(thunkRequestFileAction(ChonkyActions.KeyboardClickFile, {
            file,
            fileDisplayIndex: displayIndex,
            enterKey: event.enterKey,
            spaceKey: event.spaceKey,
            altKey: event.altKey,
            ctrlKey: event.ctrlKey,
            shiftKey: event.shiftKey,
        }));
    }, [dispatch, file, displayIndex]);
    // Prepare single/double click handlers
    const onSingleClick = useCallback((event) => onMouseClick(event, "single"), [onMouseClick]);
    const onDoubleClick = useCallback((event) => onMouseClick(event, "double"), [onMouseClick]);
    return {
        onSingleClick,
        onDoubleClick,
        onKeyboardClick,
    };
};
