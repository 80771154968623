import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Tooltip, IconButton } from "@mui/material";
import { useLocation } from "wouter";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
export function SelectionInfoBtn() {
    const [, setLocation] = useLocation();
    return (_jsx(Tooltip, Object.assign({ title: "Open selection guidelines", arrow: true }, { children: _jsx(IconButton, Object.assign({ onClick: () => setLocation("/docs/selection"), color: "primary", sx: {
                mb: "0.1rem",
            } }, { children: _jsx(InfoOutlinedIcon, { sx: { fontSize: 20 } }) })) })));
}
