import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * @author Timur Kuzhagaliyev <tim.kuzh@gmail.com>
 * @author Panagiotis Tourlas <p.tourlas@acellera.com>
 * @copyright 2022
 * @license MIT
 */
import { memo, useCallback, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { FixedSizeList } from "react-window";
import { selectFileViewConfig, selectors } from "../../redux/selectors";
import { FileViewMode } from "../../types/file-view.types";
import { useInstanceVariable } from "../../util/hooks-helpers";
import { makeLocalChonkyStyles } from "../../util/styles";
import { SmartFileEntry } from "./FileEntry";
export const ListContainer = memo((props) => {
    const { width, height } = props;
    const viewConfig = useSelector(selectFileViewConfig);
    const listRef = useRef();
    const displayFileIds = useSelector(selectors.getDisplayFileIds);
    const displayFileIdsRef = useInstanceVariable(displayFileIds);
    const getItemKey = useCallback((index) => { var _a; return (_a = displayFileIdsRef.current[index]) !== null && _a !== void 0 ? _a : `loading-file-${index}`; }, [displayFileIdsRef]);
    const classes = useStyles();
    const listComponent = useMemo(() => {
        // When entry size is null, we use List view
        const rowRenderer = (data) => {
            var _a;
            return (_jsx("div", Object.assign({ style: data.style }, { children: _jsx(SmartFileEntry, { fileId: (_a = displayFileIds[data.index]) !== null && _a !== void 0 ? _a : null, displayIndex: data.index, fileViewMode: FileViewMode.List }) })));
        };
        return (_jsx(FixedSizeList, Object.assign({ ref: listRef, className: classes.listContainer, itemSize: viewConfig.entryHeight, height: height, itemCount: displayFileIds.length, width: width, itemKey: getItemKey }, { children: rowRenderer })));
    }, [
        classes.listContainer,
        viewConfig.entryHeight,
        height,
        displayFileIds,
        width,
        getItemKey,
    ]);
    return listComponent;
});
const useStyles = makeLocalChonkyStyles((theme) => ({
    listContainer: {
        borderTop: `solid 1px ${theme.palette.divider}`,
    },
}));
