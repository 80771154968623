import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import React from "react";
// import styled from "styled-components";
import { useTable, useBlockLayout, useRowSelect } from "react-table";
import { FixedSizeList } from "react-window";
import scrollbarWidth from "./scrollbarWidth";
// const Styles = styled.div`
//   .table {
//     display: inline-block;
//     border-spacing: 0;
//     border: 1px solid lightgrey;
//     .th,
//     .td {
//       margin: 0;
//       padding: 0.5rem;
//       border-bottom: 1px solid lightgrey;
//       border-right: 1px solid lightgrey;
//       :last-child {
//         border-right: 1px solid lightgrey;
//       }
//     }
//   }
// `;
function Table({ columns, data }) {
    const scrollBarSize = React.useMemo(() => scrollbarWidth(), []);
    const { getTableProps, getTableBodyProps, headerGroups, rows, totalColumnsWidth, prepareRow, } = useTable({
        columns,
        data,
    }, useBlockLayout, useRowSelect);
    const RenderRow = React.useCallback((rows) => ({ index, style }) => {
        const row = rows[index];
        prepareRow(row);
        return (_jsx("div", Object.assign({}, row.getRowProps({ style }), { className: "tr" }, { children: row.cells.map((cell) => {
                return (_jsx("div", Object.assign({}, cell.getCellProps(), { className: "td" }, { children: cell.render("Cell") })));
            }) })));
    }, [prepareRow]);
    // Render the UI for your table
    return (_jsxs("div", Object.assign({}, getTableProps(), { className: "table" }, { children: [_jsx("div", { children: headerGroups.map((headerGroup) => (_jsx("div", Object.assign({}, headerGroup.getHeaderGroupProps(), { className: "tr" }, { children: headerGroup.headers.map((column) => (_jsx("div", Object.assign({}, column.getHeaderProps(), { className: "th" }, { children: column.render("Header") })))) })))) }), _jsx("div", Object.assign({}, getTableBodyProps(), { children: _jsx(FixedSizeList, Object.assign({ height: window.innerHeight - 300, itemCount: rows.length, itemSize: 50, width: totalColumnsWidth + scrollBarSize * 2 }, { children: RenderRow(rows) })) }))] })));
}
export function VirtualizedTable({ data, columns }) {
    return (
    // <Styles>
    _jsx(Table, { data: data, columns: columns })
    // </Styles>
    );
}
