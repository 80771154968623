import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Grid, Typography } from "@mui/material";
import { useFieldArray, useFormContext, } from "react-hook-form";
import { keyToLabel } from "../utils";
import { HelpTooltip } from "../../apps/HelpTooltip";
import { TextFieldPM } from "../../SharedFormParts/TextField";
import { CheckboxPM } from "../../SharedFormParts/CheckBox";
import { SliderPM } from "../../SharedFormParts/Slider";
import { SelectPM } from "../../SharedFormParts/Select";
import { FileSelector } from "../../SharedFormParts/FileSelector";
import { CsProbeSelector } from "../../SharedFormParts/CsProbeSelector";
import { DatacenterSelector } from "../../SharedFormParts/DatacenterSelector/DatacenterSelector";
import { CustomNumArgs } from "./CustomNumArgs";
import { DefinedNumArgs } from "./DefinedNumArgs";
import { LigandSelector } from "../../SharedFormParts/LigandSelector/LigandSelector";
export function FormFieldGenerator(appElementParams) {
    var _a, _b;
    return ((_a = appElementParams.gui_options) === null || _a === void 0 ? void 0 : _a.force_value) ||
        ((_b = appElementParams.gui_options) === null || _b === void 0 ? void 0 : _b.hide) ? (_jsx(_Fragment, {})) : appElementParams.nargs != null && !appElementParams.hideNargs ? (
    // why spread one and explicitly pass the other???
    // and also, some de-spaghettization would be nice
    _jsx(FormFieldGeneratorNumArgs, { appElementParams: appElementParams, pyodide: appElementParams.pyodide, disabled: appElementParams.disabled })) : (_jsx(FormFieldGeneratorElement, Object.assign({}, appElementParams)));
}
function FormFieldGeneratorElement({ pyodide, mandatory, type, name, value, description, nargs, choices, metavar, stepsize, customLabel, gui_options, disabled, }) {
    const { register, setValue, formState } = useFormContext();
    const { errors } = formState;
    const linked_group = gui_options === null || gui_options === void 0 ? void 0 : gui_options.linked_group;
    const errorMessage = obtainErrorMessage(name, errors, nargs != null, !!linked_group);
    let formElement;
    const _label = customLabel
        ? customLabel
        : (gui_options === null || gui_options === void 0 ? void 0 : gui_options.label)
            ? gui_options === null || gui_options === void 0 ? void 0 : gui_options.label
            : keyToLabel(name);
    const label_opt = mandatory ? `${_label} *` : _label;
    const _type = (gui_options === null || gui_options === void 0 ? void 0 : gui_options.component) ? gui_options === null || gui_options === void 0 ? void 0 : gui_options.component : type;
    switch (_type) {
        case "ligand_selector":
            formElement = (_jsx(LigandSelector, { pyodide: pyodide, formKey: name, label: label_opt, error: errorMessage, disabled: disabled, hookFormRegisterFunc: register, setValue: setValue }));
            break;
        case "str":
        case "str_to_num_array":
            if (choices) {
                const _choices = typeof choices === "object" ? choices : [choices];
                const options = _choices.map((choice) => {
                    return { label: choice, value: choice };
                });
                formElement = (_jsx(SelectPM, { formKey: name, label: label_opt, options: options, defaultValue: value, disabled: disabled }));
            }
            else if ((gui_options === null || gui_options === void 0 ? void 0 : gui_options.custom_selector) === "cs_probe_selector") {
                formElement = (_jsx(CsProbeSelector, { hookFormRegisterFunc: register, setValue: setValue, formKey: name, label: label_opt, error: errorMessage, disabled: disabled }));
            }
            else {
                formElement = (_jsx(TextFieldPM, { defaultValue: value, formKey: name, label: label_opt, type: "text", error: errorMessage, marginTop: 0, disabled: disabled }));
            }
            break;
        case "Path":
            if (gui_options === null || gui_options === void 0 ? void 0 : gui_options.data_center) {
                formElement = (_jsx(DatacenterSelector, { pyodide: pyodide, formKey: name, label: label_opt, error: errorMessage, datacenterArgs: gui_options.data_center, disabled: disabled, defaultValue: value, hookFormRegisterFunc: register, setValue: setValue }));
            }
            else {
                formElement = (_jsx(FileSelector, { pyodide: pyodide, formKey: name, label: label_opt, error: errorMessage, disabled: disabled, hookFormRegisterFunc: register, setValue: setValue }));
            }
            break;
        case "int":
        case "float": // if min max and stepsize, use slider
            const maxvalue = gui_options === null || gui_options === void 0 ? void 0 : gui_options.max_value;
            const minvalue = gui_options === null || gui_options === void 0 ? void 0 : gui_options.min_value;
            if ((minvalue || minvalue === 0) &&
                (maxvalue || maxvalue === 0) &&
                stepsize) {
                formElement = (_jsx(SliderPM, { hookFormRegisterFunc: register, formKey: name, label: label_opt, 
                    // error={errorMessage as string | undefined}
                    min: minvalue, max: maxvalue, step: stepsize, defaultValue: value, disabled: disabled }));
            }
            else {
                formElement = (_jsx(TextFieldPM, { defaultValue: value, formKey: name, label: label_opt, type: "number", error: errorMessage, marginTop: 0, min: minvalue ? minvalue : undefined, max: maxvalue ? maxvalue : undefined, stepsize: stepsize ? stepsize : type === "int" ? 1 : undefined, disabled: disabled }));
            }
            break;
        case "bool":
        case "str_to_bool":
            formElement = (_jsx(CheckboxPM, { hookFormRegisterFunc: register, formKey: name, label: label_opt, marginTop: 0, disabled: disabled }));
            break;
        default:
            throw Error(`unknown form type ${type} passed!`);
    }
    const _description = `[${name}] ${description}`;
    return (_jsxs(Grid, Object.assign({ container: true, alignItems: "center", spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(HelpTooltip, { title: _description }) })), _jsx(Grid, Object.assign({ item: true, xs: true, zeroMinWidth: true }, { children: formElement }))] })));
}
function FormFieldGeneratorNumArgs({ appElementParams, pyodide, disabled, }) {
    var _a, _b, _c;
    const { formState } = useFormContext();
    const { errors } = formState;
    const nargsVal = appElementParams.nargs;
    const nargsNum = typeof nargsVal === "number" ? nargsVal : undefined;
    const errorMessage = (_a = errors[appElementParams.name]) === null || _a === void 0 ? void 0 : _a.message;
    const { fields, append, remove } = useFieldArray({
        name: appElementParams.name,
    });
    const label = ((_b = appElementParams.gui_options) === null || _b === void 0 ? void 0 : _b.label)
        ? (_c = appElementParams.gui_options) === null || _c === void 0 ? void 0 : _c.label
        : keyToLabel(appElementParams.name);
    const generateFormField = (i) => {
        const nameNum = i > 0 ? `${label} #${i + 1}` : label;
        const _appElementParams = Object.assign(Object.assign({}, appElementParams), { customLabel: nameNum, name: `${appElementParams.name}.${i}.${appElementParams.name}` });
        return (_jsx(FormFieldGeneratorElement, Object.assign({}, _appElementParams, { pyodide: pyodide, disabled: disabled }), `field-narg-el-${appElementParams.name}`));
    };
    let nargsComp;
    if (nargsNum === undefined) {
        const handleAdd = () => {
            append({
                [appElementParams.name]: appElementParams.value ||
                    appElementParams.value === 0 ||
                    appElementParams.value === false
                    ? appElementParams.value
                    : undefined,
            });
        };
        const handleRemove = () => {
            remove(-1);
        };
        nargsComp = (_jsx(CustomNumArgs, { fields: fields, handleAdd: handleAdd, handleRemove: handleRemove, repeatElement: generateFormField }));
    }
    else {
        nargsComp = (_jsx(DefinedNumArgs, { repeatElement: generateFormField, numElements: nargsNum }));
    }
    return (_jsxs(Grid, Object.assign({ container: true, direction: "column", justifyContent: "center", alignItems: "flex-start" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true, sx: { width: "100%" } }, { children: nargsComp })), " ", errorMessage ? (_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(Typography, Object.assign({ variant: "caption", sx: { color: "#d32f2f", pl: 5 } }, { children: errorMessage })) }))) : null] })));
}
function obtainErrorMessage(name, errors, isNargsPlus, isLinkedGroup) {
    var _a, _b, _c;
    let errorMessage;
    if (isLinkedGroup) {
        const [groupName, elIdx, errName] = name.split(".");
        const groupError = errors[groupName];
        if (groupError) {
            // @ts-ignore
            const fieldError = groupError[errName];
            if (fieldError) {
                errorMessage = (_a = fieldError[Number(elIdx)]) === null || _a === void 0 ? void 0 : _a.message;
            }
        }
    }
    else if (isNargsPlus) {
        const [, elIdx, errName] = name.split(".");
        const groupError = errors[errName];
        // @ts-ignore
        errorMessage = groupError ? (_b = groupError[Number(elIdx)]) === null || _b === void 0 ? void 0 : _b.message : undefined;
    }
    else {
        errorMessage = (_c = errors[name]) === null || _c === void 0 ? void 0 : _c.message;
    }
    return errorMessage;
}
