import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * @author Timur Kuzhagaliyev <tim.kuzh@gmail.com>
 * @author Panagiotis Tourlas <p.tourlas@acellera.com>
 * @copyright 2022
 * @license MIT
 */
import c from "classnames";
import { memo } from "react";
import { makeGlobalChonkyStyles } from "../../util/styles";
export const FileThumbnail = memo((props) => {
    const { className, thumbnailUrl } = props;
    const thumbnailStyle = thumbnailUrl
        ? { backgroundImage: `url('${thumbnailUrl}')` }
        : {};
    const classes = useStyles();
    return (_jsx("div", { className: c([className, classes.fileThumbnail]), style: thumbnailStyle }));
});
FileThumbnail.displayName = "FileThumbnail";
const useStyles = makeGlobalChonkyStyles(() => ({
    fileThumbnail: {
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
    },
}));
