import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * @author Timur Kuzhagaliyev <tim.kuzh@gmail.com>
 * @author Panagiotis Tourlas <p.tourlas@acellera.com>
 * @copyright 2022
 * @license MIT
 */
import { memo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { selectHiddenFileCount, selectors, selectSelectionSize, } from "../../redux/selectors";
import { getI18nId, I18nNamespace } from "../../util/i18n";
import { important, makeGlobalChonkyStyles } from "../../util/styles";
export const ToolbarInfo = memo(() => {
    const classes = useStyles();
    const displayFileIds = useSelector(selectors.getDisplayFileIds);
    const selectionSize = useSelector(selectSelectionSize);
    const hiddenCount = useSelector(selectHiddenFileCount);
    const intl = useIntl();
    const fileCountString = intl.formatMessage({
        id: getI18nId(I18nNamespace.Toolbar, "visibleFileCount"),
        defaultMessage: `{fileCount, plural,
                =0 {# items}
                one {# item}
                other {# items}
            }`,
    }, { fileCount: displayFileIds.length });
    const selectedString = intl.formatMessage({
        id: getI18nId(I18nNamespace.Toolbar, "selectedFileCount"),
        defaultMessage: `{fileCount, plural,
                =0 {}
                other {# selected}
            }`,
    }, { fileCount: selectionSize });
    const hiddenString = intl.formatMessage({
        id: getI18nId(I18nNamespace.Toolbar, "hiddenFileCount"),
        defaultMessage: `{fileCount, plural,
                =0 {}
                other {# hidden}
            }`,
    }, { fileCount: hiddenCount });
    return (_jsx("div", Object.assign({ className: classes.infoContainer }, { children: _jsxs(Typography, Object.assign({ className: classes.infoText, variant: "body1" }, { children: [fileCountString, (selectedString || hiddenString) && (_jsxs("span", Object.assign({ className: classes.extraInfoSpan }, { children: ["(", _jsx("span", Object.assign({ className: classes.selectionSizeText }, { children: selectedString })), selectedString && hiddenString && ", ", _jsx("span", Object.assign({ className: classes.hiddenCountText }, { children: hiddenString })), ")"] })))] })) })));
});
const useStyles = makeGlobalChonkyStyles((theme) => ({
    infoContainer: {
        height: theme.toolbar.size,
        display: "flex",
    },
    infoText: {
        lineHeight: important(theme.toolbar.lineHeight),
        fontSize: important(theme.toolbar.fontSize),
        marginLeft: important(12),
        height: theme.toolbar.size,
    },
    extraInfoSpan: {
        marginRight: important(8),
        marginLeft: important(8),
        opacity: 0.8,
    },
    selectionSizeText: {
        color: theme.colors.textActive,
    },
    hiddenCountText: {},
}));
