import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Grid, Slider, Box, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { HelpTooltip } from "../apps/HelpTooltip";
export function SliderPM({ hookFormRegisterFunc, formKey, defaultValue, step, min, max, label, helpText, disabled = false, }) {
    return (_jsx(Box, Object.assign({ sx: { marginTop: 2 } }, { children: _jsx(Controller, { name: formKey, defaultValue: defaultValue, render: ({ field: props }) => (_jsxs(Grid, Object.assign({ container: true, spacing: 2, alignItems: "center" }, { children: [helpText ? (_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(HelpTooltip, { title: helpText }) }))) : null, _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(Typography, Object.assign({ gutterBottom: true, variant: "body2", sx: { pr: 1 } }, { children: label })) })), _jsx(Grid, Object.assign({ item: true, xs: true, sx: { minWidth: "5em" } }, { children: _jsx(Slider, Object.assign({}, props, { onChange: (_, value) => {
                                props.onChange(value);
                            }, valueLabelDisplay: "auto", disabled: disabled, step: step, min: min, max: max })) })), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Typography, Object.assign({ gutterBottom: true, variant: "body2", sx: { pr: 1 } }, { children: props.value })) }))] }))) }) })));
}
