var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { create } from "zustand";
import { checkRepeatedJobName, getExecId, sendStartMessage, uploadJobFiles, uploadJobParams, } from "./submitJob_utils";
import { prepareManifestParams } from "../../FormGenerator/FormFromManifest/prepareManifestParams";
export const AppListStore = create()((set, get) => ({
    appList: [],
    ignoredApps: [],
    preselectedValues: {},
    appLimitations: { apps: {}, python_functions: "" },
    appManifestFormFields: {},
    setPreselectedVal: (appName, values) => {
        set((state) => {
            const presel = state.preselectedValues;
            presel[appName] = values;
            return { preselectedValues: presel };
        });
    },
    reSetPreselectedVal: () => {
        set((state) => {
            return { preselectedValues: {} };
        });
    },
    setAppList: (list) => {
        list.forEach((app) => (app["app_group"] = "Tools"));
        list.sort((a, b) => a.name.localeCompare(b.name));
        set({ appList: list });
    },
    setAppLimits: (x) => {
        set({ appLimitations: x });
    },
    setAppManifestFormFields: (appParams, appName) => __awaiter(void 0, void 0, void 0, function* () {
        const _appParams = appParams.filter((p) => p.name !== "outdir" && p.name !== "scratchdir"); /* skip adding "outdir" and "scratchdir" fields to the form */
        const manifestGroups = yield prepareManifestParams(_appParams);
        set({
            appManifestFormFields: Object.assign(Object.assign({}, get().appManifestFormFields), { [appName]: manifestGroups }),
        });
    }),
    getAppNames: () => get().appList.map((app) => app.name.toLowerCase()),
    getAppGroups: () => [...new Set(get().appList.map((x) => x.app_group))],
    getAppLinks: () => {
        const groups = get().getAppGroups();
        const links = groups.map((groupName) => {
            const link = {
                groupName: groupName,
                groupEntries: get().appList.filter((app) => app.app_group === groupName),
            };
            link.groupEntries.forEach((x) => (x.name = x.name.toLowerCase()));
            return link;
        });
        return links;
    },
    getAppFormFields: (name) => {
        const i = get().appList.findIndex((app) => app.name.toLowerCase() === name.toLowerCase());
        if (i < 0)
            throw Error(`App ${name} does not exist!`);
        return get().appList[i].params;
    },
    submitJob: (pyodide, formData, appManifest, userId, project, onSuccess, onError) => __awaiter(void 0, void 0, void 0, function* () {
        const jobName = formData.name;
        const group = `${userId}/${project}`;
        try {
            let pmOptions = {};
            if (jobName) {
                const isRepeated = yield checkRepeatedJobName(`${userId}/${project}`, jobName);
                if (isRepeated) {
                    if (onError)
                        onError({
                            title: "This job name already exists.",
                            details: "This job name already exists. Please provide a new one.",
                        });
                    return;
                }
                pmOptions["remotepath"] = `${userId}/${project}/${jobName}`;
            }
            else {
                pmOptions["remotedir"] = group;
            }
            const appid = `${appManifest.name}-${appManifest.version}`;
            const execid = yield getExecId(group, appid, jobName);
            const resultParams = yield uploadJobParams(appManifest.params, formData, execid, group, appManifest.name, pyodide);
            const out_config = {
                appid: appid,
                execid: execid,
                pm_options: pmOptions,
                params: resultParams,
            };
            yield uploadJobFiles(execid, group, appManifest.name, out_config, pyodide);
            yield sendStartMessage(execid);
        }
        catch (e) {
            if (onError)
                onError({
                    title: "Error",
                    details: `${e}`,
                });
            return;
        }
        if (onSuccess) {
            onSuccess();
        }
    }),
}));
export function applyLimitations(appList, appLimitations) {
    appList.forEach((appManifest) => {
        const name = appManifest.name;
        const appLim = appLimitations.apps[name.toLowerCase()];
        if (!appLim)
            return;
        const appLimArgs = appLim.args;
        if (!appLimArgs)
            return;
        const appParams = appManifest.params;
        for (let appParamName in appLimArgs) {
            const appParamLimits = appLimArgs[appParamName];
            const manifestParam = appParams.find((param) => param.name === appParamName);
            if (!manifestParam) {
                continue;
            }
            if (!manifestParam.gui_options) {
                manifestParam["gui_options"] = {};
            }
            manifestParam["gui_options"] = Object.assign(Object.assign({}, manifestParam["gui_options"]), appParamLimits);
            if ("force_value" in appParamLimits) {
                manifestParam.value = appParamLimits["force_value"];
            }
        }
    });
}
