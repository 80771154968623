// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { downloadDatasetFiles, readDCfile, } from "../../apps/results/fetchDcData";
import { dsInfoFromId } from "../../apps/results/utils";
export function runPythonValidations(pyodide, onSubmitError, appLimitations, appManifest, args) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const appName = appManifest.name.toLowerCase();
        const appSpecificRestrictions = ((_a = appLimitations.apps[appName]) === null || _a === void 0 ? void 0 : _a.validation_functions) || {};
        /* If there are no validation instructions, just go ahead with submission. */
        if (Object.keys(appSpecificRestrictions).length === 0)
            return true;
        const restrictionArr = Object.entries(appSpecificRestrictions);
        const tmpdir = "/tmp/submit";
        let err;
        try {
            const updatedArgs = yield downloadDCfilesForValidation(args, restrictionArr, pyodide);
            err = yield pyodide.RunPythonAsync({
                context: { updatedArgs, restrictionArr, tmpdir },
                script: `
          from js import updatedArgs as _args
          from js import tmpdir as TMPDIR
          from js import restrictionArr as _restrictionArr
          from pathlib import Path
    
          Path(TMPDIR).mkdir(parents=True, exist_ok=True)
    
          args = _args.to_py()
          restrictionArr = _restrictionArr.to_py()
          validation_error_msg = ''
    
          for restriction in restrictionArr:
            try:
              (validation, fail_message) = restriction
              expected_args = extract_function_args(validation)
              # If an function argument is not in args, add it with value None
              add_missing_keys(args, expected_args)
              
              if (fail_message):
                validation_pass = eval(validation)
              else:
                validation_pass, fail_message = eval(validation)
              
              if(validation_pass):
                pass
              else:
                validation_error_msg = fail_message
                break
            except Exception as e:
              validation_error_msg = f"{type(e)}: {e}"
              break
           
          validation_error_msg
          `,
            });
        }
        catch (error) {
            err = `${error}`;
        }
        if (err) {
            // flushFolderInVFS(pyodide, tmpdir);
            pyodide.RunPythonAsync({
                context: {},
                script: `
        if 'args' in globals():
         del args
        if 'restrictionArr' in globals():
         del restrictionArr
        if 'validation_error_msg' in globals():
         del validation_error_msg
        `,
            });
            onSubmitError({ title: "Invalid Input!", details: err });
            return false;
        }
        return true;
    });
}
function extractFunctionArgs(codeString) {
    const pattern = /(?<=args\[['"])(\w+)(?=['"]\])/g;
    const matches = codeString.match(pattern) || [];
    const argsKeys = Array.from(new Set(matches)); // Removing duplicates, if any
    return argsKeys;
}
function extractValidationFields(restrictionArr) {
    const usedArgs = [];
    restrictionArr.forEach(([validationStr]) => {
        const args = extractFunctionArgs(validationStr);
        usedArgs.push(...args);
    });
    const usedArgsUnique = [...new Set(usedArgs)];
    return usedArgsUnique;
}
function createDirectory(path, pyodide) {
    return __awaiter(this, void 0, void 0, function* () {
        yield pyodide.RunPythonAsync({
            context: { path },
            script: `
          from js import path
          os.makedirs(path, exist_ok=True)
          `,
        });
    });
}
function downloadDCfilesForValidation(args, restrictionArr, pyodide) {
    var _a, e_1, _b, _c, _d, e_2, _e, _f;
    return __awaiter(this, void 0, void 0, function* () {
        // Dowload the files drom DC that we will use for validation
        const usedArgsUnique = extractValidationFields(restrictionArr);
        const _args = Object.assign({}, args);
        const tmppath = `/tmp/submit/validate`;
        try {
            for (var _g = true, usedArgsUnique_1 = __asyncValues(usedArgsUnique), usedArgsUnique_1_1; usedArgsUnique_1_1 = yield usedArgsUnique_1.next(), _a = usedArgsUnique_1_1.done, !_a;) {
                _c = usedArgsUnique_1_1.value;
                _g = false;
                try {
                    const argName = _c;
                    const argsVal = _args[argName];
                    if (!argsVal)
                        continue;
                    const isArray = Array.isArray(argsVal);
                    const valArray = isArray ? argsVal : [argsVal];
                    const newValArray = [];
                    try {
                        for (var _h = true, valArray_1 = (e_2 = void 0, __asyncValues(valArray)), valArray_1_1; valArray_1_1 = yield valArray_1.next(), _d = valArray_1_1.done, !_d;) {
                            _f = valArray_1_1.value;
                            _h = false;
                            try {
                                const val = _f;
                                let newVal = val;
                                if (typeof val === "string" && val.startsWith("dc://")) {
                                    const { ds_id, files } = dsInfoFromId(val);
                                    const tmpdir = `${tmppath}/${ds_id}`;
                                    if (ds_id && files) {
                                        if (files && files.length > 0) {
                                            const fileContent = yield readDCfile(ds_id, files);
                                            if (fileContent) {
                                                let filePath = `${tmpdir}/${files[0]}`.replace(/\/+$/, "");
                                                let encoding = "utf8";
                                                let _fileContent = fileContent;
                                                if (typeof fileContent !== "string") {
                                                    if (files && files.length === 1) {
                                                        const hasExtension = files[0].includes(".") && !files[0].startsWith(".");
                                                        if (!hasExtension) {
                                                            filePath = `${filePath}.zip`;
                                                        }
                                                    }
                                                    encoding = "binary";
                                                    _fileContent = new Uint8Array(fileContent);
                                                }
                                                const dirPath = filePath.substring(0, filePath.lastIndexOf("/"));
                                                yield createDirectory(dirPath, pyodide);
                                                pyodide.FS.writeFile(filePath, _fileContent, encoding);
                                                newVal = filePath;
                                            }
                                        }
                                        else {
                                            const content_tmpdir = `${tmpdir}/content`;
                                            yield downloadDatasetFiles(ds_id, pyodide, undefined, content_tmpdir);
                                            newVal = content_tmpdir;
                                        }
                                    }
                                }
                                newValArray.push(newVal);
                            }
                            finally {
                                _h = true;
                            }
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (!_h && !_d && (_e = valArray_1.return)) yield _e.call(valArray_1);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                    _args[argName] = isArray ? newValArray : newValArray[0];
                }
                finally {
                    _g = true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (!_g && !_a && (_b = usedArgsUnique_1.return)) yield _b.call(usedArgsUnique_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return _args;
    });
}
