import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { createTheme, ThemeProvider as MuiThemeProvider, } from "@mui/material/styles";
import merge from "deepmerge";
import { useMemo, forwardRef } from "react";
import { ThemeProvider } from "react-jss";
import { Provider as ReduxProvider } from "react-redux";
import { IntlProvider } from "react-intl";
import { useChonkyStore } from "../../redux/store";
import { defaultConfig } from "../../util/default-config";
import { getValueOrFallback } from "../../util/helpers";
import { useStaticValue } from "../../util/hooks-helpers";
import { ChonkyIconContext } from "../../util/icon-helper";
import { darkThemeOverride, lightTheme, mobileThemeOverride, useIsMobileBreakpoint, } from "../../util/styles";
import { ChonkyFormattersContext, defaultFormatters } from "../../util/i18n";
import { ChonkyBusinessLogic } from "../internal/ChonkyBusinessLogic";
import { ChonkyIconPlaceholder } from "../internal/ChonkyIconPlaceholder";
import { ChonkyPresentationLayer } from "../internal/ChonkyPresentationLayer";
export const FileBrowser = forwardRef((props, ref) => {
    var _a;
    const { instanceId, iconComponent, children } = props;
    const darkMode = getValueOrFallback(props.darkMode, defaultConfig.darkMode, "boolean");
    const chonkyInstanceId = useStaticValue(() => instanceId !== null && instanceId !== void 0 ? instanceId : Math.random().toString());
    const store = useChonkyStore(chonkyInstanceId);
    const isMobileBreakpoint = useIsMobileBreakpoint();
    const theme = useMemo(() => {
        const muiTheme = createTheme({
        // palette: { type: darkMode ? "dark" : "light" },
        });
        const combinedTheme = merge(muiTheme, merge(lightTheme, darkMode ? darkThemeOverride : {}));
        return isMobileBreakpoint
            ? merge(combinedTheme, mobileThemeOverride)
            : combinedTheme;
    }, [darkMode, isMobileBreakpoint]);
    const i18n = getValueOrFallback(props.i18n, defaultConfig.i18n);
    const formatters = useMemo(() => (Object.assign(Object.assign({}, defaultFormatters), i18n === null || i18n === void 0 ? void 0 : i18n.formatters)), [i18n]);
    const chonkyComps = (_jsxs(_Fragment, { children: [_jsx(ChonkyBusinessLogic, Object.assign({ ref: ref }, props)), _jsx(ChonkyPresentationLayer, { children: children })] }));
    return (_jsx(IntlProvider, Object.assign({ locale: "en", defaultLocale: "en" }, i18n, { children: _jsx(ChonkyFormattersContext.Provider, Object.assign({ value: formatters }, { children: _jsx(ReduxProvider, Object.assign({ store: store }, { children: _jsx(ThemeProvider, Object.assign({ theme: theme }, { children: _jsx(MuiThemeProvider, Object.assign({ theme: theme }, { children: _jsx(ChonkyIconContext.Provider, Object.assign({ value: (_a = iconComponent !== null && iconComponent !== void 0 ? iconComponent : defaultConfig.iconComponent) !== null && _a !== void 0 ? _a : ChonkyIconPlaceholder }, { children: chonkyComps })) })) })) })) })) })));
});
FileBrowser.displayName = "FileBrowser";
