// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
export function arraysHaveSameElements(array1, array2) {
    const sortedArray1 = array1.slice().sort();
    const sortedArray2 = array2.slice().sort();
    return (sortedArray1.length === sortedArray2.length &&
        sortedArray1.every((value, index) => value === sortedArray2[index]));
}
