// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//

import { createRoot } from "react-dom/client";
import { createTheme } from "@mui/material/styles";
import { CookiesProvider } from "react-cookie";
import { CookiesTest, CookiesTestLogin } from "molkitstar-ui";
import { initiate_app } from "./initiate_app";
import "./index.css";

const isCookiesTest = window.location.pathname === "/cookies-test";
const isCookiesTestLogin = window.location.pathname === "/cookies-test-login";

export const theme = createTheme({
  /*components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#e6e6e6",
        },
      },
    },
  }, JUST IN CASE IT IS NEEDED */
  palette: {
    primary: {
      main: "#0b5394",
      light: "#a0cff8",
    },
    secondary: {
      main: "#ffab40",
      light: "#e6e6e6",
    },
  },
});

if (isCookiesTest || isCookiesTestLogin) {
  const container = document.getElementById("root");
  const root = createRoot(container);

  function ThemedApp() {
    return (
      <CookiesProvider>
        {isCookiesTest ? <CookiesTest /> : <CookiesTestLogin />}
      </CookiesProvider>
    );
  }

  root.render(<ThemedApp />);
} else {
  initiate_app(theme);
}
