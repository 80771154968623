// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { capitalizeFirstLetter, keyToLabel } from "../utils";
import { getDatasets } from "../../apps/results/fetchDcData";
import { splitFilePath } from "./utils";
function organizeByGuiOptinsValue(value, objArray) {
    const nogroupName = "ungrouped";
    const groups = {};
    objArray.forEach((param) => {
        const group = param["gui_options"] && param["gui_options"][value]
            ? param["gui_options"][value]
            : nogroupName;
        if (!(group in groups)) {
            groups[group] = {
                isGroup: group !== nogroupName,
                elements: [],
                name: group,
            };
        }
        groups[group].elements.push(param);
    });
    return Object.values(groups);
}
function placeNotGroupToFront(paramGroupsPre) {
    const idx = paramGroupsPre.findIndex((e) => !e.isGroup);
    if (idx === -1)
        return;
    paramGroupsPre.unshift(paramGroupsPre.splice(idx, 1)[0]);
}
function getFirstSubstring(description) {
    // Get the first "." that is not followed by "smi" or "sdf"
    const regex = /\.(?!smi|sdf)/;
    const [firstPart] = description.split(regex);
    return firstPart;
}
function setLabels(manifestParams) {
    manifestParams.forEach((app) => {
        var _a;
        if (!((_a = app.gui_options) === null || _a === void 0 ? void 0 : _a.label)) {
            let label;
            const description = getFirstSubstring(app.description);
            if (description && description.split(" ").length <= 4) {
                label = capitalizeFirstLetter(description);
            }
            else {
                label = keyToLabel(app.name);
            }
            if (!app.gui_options) {
                app["gui_options"] = {};
            }
            app["gui_options"]["label"] = label;
        }
    });
}
function prepareDataCenterDefaults(manifestParams) {
    var _a, manifestParams_1, manifestParams_1_1;
    var _b, e_1, _c, _d;
    var _e, _f;
    return __awaiter(this, void 0, void 0, function* () {
        try {
            for (_a = true, manifestParams_1 = __asyncValues(manifestParams); manifestParams_1_1 = yield manifestParams_1.next(), _b = manifestParams_1_1.done, !_b;) {
                _d = manifestParams_1_1.value;
                _a = false;
                try {
                    const param = _d;
                    const dcDefault = (_f = (_e = param.gui_options) === null || _e === void 0 ? void 0 : _e.data_center) === null || _f === void 0 ? void 0 : _f.default;
                    if (!dcDefault)
                        continue;
                    if (dcDefault.startsWith("dc://"))
                        continue;
                    const { directoryPath, fileName } = splitFilePath(dcDefault.replace(/\/+$/, ""));
                    const fileNameSplit = fileName === null || fileName === void 0 ? void 0 : fileName.split(".");
                    const isDir = fileNameSplit && fileNameSplit.length <= 1;
                    let _directoryPath = isDir ? dcDefault : directoryPath;
                    const ds = yield getDatasets({
                        public: true,
                        remotepath: _directoryPath,
                    });
                    if (!ds || ds.length === 0)
                        continue;
                    const dsId = ds[0].id;
                    param.value = `dc://${dsId}${!isDir ? `?file=${fileName}` : ""}`;
                }
                finally {
                    _a = true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (!_a && !_b && (_c = manifestParams_1.return)) yield _c.call(manifestParams_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    });
}
export function prepareManifestParams(manifestParams) {
    return __awaiter(this, void 0, void 0, function* () {
        setLabels(manifestParams);
        yield prepareDataCenterDefaults(manifestParams);
        // Separate in groups
        const paramGroupsPre = organizeByGuiOptinsValue("group", manifestParams);
        placeNotGroupToFront(paramGroupsPre);
        // Separate each group in linked groups
        const paramGroups = [];
        paramGroupsPre.forEach((paramGroupPre) => {
            const paramGroupsElements = organizeByGuiOptinsValue("linked_group", paramGroupPre.elements);
            const finalGroup = {
                isGroup: paramGroupPre.isGroup,
                elements: paramGroupsElements,
                name: paramGroupPre.name,
            };
            paramGroups.push(finalGroup);
        });
        return paramGroups;
    });
}
