import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState } from "react";
import { Box, Dialog, DialogTitle, DialogContent, IconButton, } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export function DiscordInvitationPanel({ open, setOpen, }) {
    const defaultDimensions = {
        width: "25%",
        height: "25%",
    };
    const [_dialogDimensions, _setDialogDimensions] = useState(defaultDimensions);
    const resetWindow = () => {
        _setDialogDimensions(defaultDimensions);
        setOpen(false);
    };
    return (_jsxs(Dialog, Object.assign({ open: open, PaperProps: {
            sx: {
                minWidth: _dialogDimensions.width,
                height: _dialogDimensions.height + 150,
            },
        } }, { children: [_jsx(DialogTitle, { children: _jsx(Box, Object.assign({ display: "flex", justifyContent: "flex-end" }, { children: _jsx(IconButton, Object.assign({ color: "primary", onClick: resetWindow }, { children: _jsx(CloseIcon, {}) })) })) }), _jsx(DialogContent, { children: _jsx("iframe", { title: "discord-invitation", src: "https://discordapp.com/widget?id=968464699169587201&theme=dark", width: "100%", height: "400", allowTransparency: true, frameBorder: "0", sandbox: "allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts" }) })] })));
}
