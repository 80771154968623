import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogTitle, Box, DialogContent, DialogActions, Button, } from "@mui/material";
import { useEffect, useState } from "react";
import EmailIcon from "@mui/icons-material/Email";
export function ErrorBox({ open, setOpen, error, resetErrorMsg, }) {
    const [openTraceback, setOpenTraceback] = useState(false);
    const errorDetails = error.details;
    const contactError = errorDetails.includes("@acellera.com");
    useEffect(() => {
        if (contactError) {
            setOpenTraceback(true);
        }
    }, [errorDetails, contactError]);
    const closeAndReset = () => {
        resetErrorMsg();
        setOpen(false);
    };
    const CloseButton = (_jsx(IconButton, Object.assign({ "aria-label": "close", color: "inherit", size: "small", onClick: closeAndReset }, { children: _jsx(CloseIcon, { fontSize: "inherit" }) })));
    return (_jsxs(_Fragment, { children: [_jsx(Collapse, Object.assign({ in: open, unmountOnExit: true }, { children: _jsxs(Alert, Object.assign({ sx: { mb: 2 }, severity: "error", action: CloseButton }, { children: [error.title, "\n", _jsx(Link, Object.assign({ component: "button", variant: "body2", onClick: () => setOpenTraceback(true) }, { children: "More Info..." }))] })) })), _jsx(TracebackDetailsPanel, { open: openTraceback, setOpen: setOpenTraceback, error: error, showContact: contactError })] }));
}
function TracebackDetailsPanel({ open, setOpen, error, showContact, }) {
    const resetWindow = () => setOpen(false);
    let hasErrorInfo = false;
    Object.values(error).forEach((e) => {
        if (e)
            hasErrorInfo = true;
    });
    return (_jsxs(Dialog, Object.assign({ open: open && hasErrorInfo, PaperProps: {
            sx: {
                minWidth: "60%",
                height: "50%" + 150,
            },
        } }, { children: [_jsxs(DialogTitle, { children: [_jsx(Box, Object.assign({ display: "flex", justifyContent: "flex-end" }, { children: _jsx(IconButton, Object.assign({ color: "primary", onClick: resetWindow }, { children: _jsx(CloseIcon, {}) })) })), showContact ? "Usage Limits Exceeded" : " Error Details:"] }), _jsx(DialogContent, { children: showContact ? _jsx(_Fragment, { children: error.details }) : _jsx("pre", { children: error.details }) }), showContact && (_jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ variant: "outlined", onClick: resetWindow }, { children: "close" })), _jsx(Button, Object.assign({ variant: "contained", onClick: () => window.open("mailto:sales@acellera.com", "_blank"), endIcon: _jsx(EmailIcon, {}) }, { children: "contact us" }))] }))] })));
}
