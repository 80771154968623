// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import * as yup from "yup";
export const defaultValues = {
    email: "",
    password: "",
};
export const LoginSchema = yup
    .object({
    email: yup.string().email().required("Email is required"),
    password: yup.string().required("Password is required"),
})
    .required();
