import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * @author Timur Kuzhagaliyev <tim.kuzh@gmail.com>
 * @author Panagiotis Tourlas <p.tourlas@acellera.com>
 * @copyright 2022
 * @license MIT
 */
import { useEffect, useMemo, memo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import ListSubheader from "@mui/material/ListSubheader";
import Menu from "@mui/material/Menu";
import { reduxActions } from "../../redux/reducers";
import { selectContextMenuConfig, selectContextMenuItems, } from "../../redux/selectors";
import { getI18nId, I18nNamespace } from "../../util/i18n";
import { important, makeGlobalChonkyStyles } from "../../util/styles";
import { useContextMenuDismisser } from "./FileContextMenu-hooks";
import { SmartToolbarDropdownButton } from "./ToolbarDropdownButton";
export const FileContextMenu = memo(() => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(reduxActions.setContextMenuMounted(true));
        return () => {
            dispatch(reduxActions.setContextMenuMounted(false));
        };
    }, [dispatch]);
    const intl = useIntl();
    const browserMenuShortcutString = intl.formatMessage({
        id: getI18nId(I18nNamespace.FileContextMenu, "browserMenuShortcut"),
        defaultMessage: "Browser menu: {shortcut}",
    }, { shortcut: _jsx("strong", { children: "Alt + Right Click" }) });
    const contextMenuConfig = useSelector(selectContextMenuConfig);
    const contextMenuItems = useSelector(selectContextMenuItems);
    const hideContextMenu = useContextMenuDismisser();
    const contextMenuItemComponents = useMemo(() => {
        const components = [];
        for (let i = 0; i < contextMenuItems.length; ++i) {
            const item = contextMenuItems[i];
            if (typeof item === "string") {
                components.push(_jsx(SmartToolbarDropdownButton, { fileActionId: item, onClickFollowUp: hideContextMenu }, `context-menu-item-${item}`));
            }
            else {
                item.fileActionIds.map((id) => components.push(_jsx(SmartToolbarDropdownButton, { fileActionId: id, onClickFollowUp: hideContextMenu }, `context-menu-item-${item.name}-${id}`)));
            }
        }
        return components;
    }, [contextMenuItems, hideContextMenu]);
    const anchorPosition = useMemo(() => contextMenuConfig
        ? { top: contextMenuConfig.mouseY, left: contextMenuConfig.mouseX }
        : undefined, [contextMenuConfig]);
    const classes = useStyles();
    return (_jsxs(Menu, Object.assign({ elevation: 2, disablePortal: true, onClose: hideContextMenu, transitionDuration: 150, open: !!contextMenuConfig, anchorPosition: anchorPosition, anchorReference: "anchorPosition", classes: { list: classes.contextMenuList } }, { children: [contextMenuItemComponents, _jsx(ListSubheader, Object.assign({ component: "div", className: classes.browserMenuTooltip }, { children: browserMenuShortcutString }))] })));
});
const useStyles = makeGlobalChonkyStyles(() => ({
    contextMenuList: {
        paddingBottom: important(0),
        paddingTop: important(0),
    },
    browserMenuTooltip: {
        lineHeight: important("30px"),
        fontSize: important("0.7em"),
    },
}));
