import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Button } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
export function AppDescriptionButton({ appDocsIsOpen, toggleOpenAppDocs, }) {
    return (_jsx(Button, Object.assign({ sx: {
            backgroundColor: appDocsIsOpen ? "rgba(11, 83, 148, 0.3)" : undefined,
        }, size: "small", variant: "text", endIcon: _jsx(InfoIcon, {}), onClick: toggleOpenAppDocs }, { children: "Docs" })));
}
