var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect, createRef } from "react";
import { PluginConfig } from "molstar/lib/mol-plugin/config";
import { createPluginUI } from "molstar/lib/mol-plugin-ui";
import { DefaultPluginUISpec, } from "molstar/lib/mol-plugin-ui/spec";
import "molstar/lib/mol-plugin-ui/skin/blue.scss";
const PlayMoleculeSpec = Object.assign(Object.assign({}, DefaultPluginUISpec()), { config: [
        [PluginConfig.VolumeStreaming.Enabled, false],
        [PluginConfig.Viewport.ShowExpand, false],
        [PluginConfig.Viewport.ShowAnimation, false],
        [PluginConfig.Viewport.ShowControls, false],
        [PluginConfig.Viewport.ShowSettings, true],
        [PluginConfig.Viewport.ShowSelectionMode, false],
        [PluginConfig.Viewport.ShowTrajectoryControls, false],
        [PluginConfig.General.AllowMajorPerformanceCaveat, true],
    ], canvas3d: {
        camera: { mode: "orthographic" },
        renderer: {
            backgroundColor: 0x303030,
        },
        postprocessing: {
            occlusion: {
                name: "off",
                params: {},
            },
        },
    }, components: {
        disableDragOverlay: true,
    } });
export function MolStarWrapper() {
    const parent = createRef();
    useEffect(() => {
        function init() {
            return __awaiter(this, void 0, void 0, function* () {
                window.molstar = yield createPluginUI(parent.current, PlayMoleculeSpec);
                yield window.molstar.managers.interactivity.setProps({
                    granularity: "element",
                });
                hideUnwantedComp();
            });
        }
        init();
        return () => {
            var _a;
            (_a = window.molstar) === null || _a === void 0 ? void 0 : _a.dispose();
            window.molstar = undefined;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return _jsx("div", { className: "molstar-entrypoint", ref: parent });
}
const hideUnwantedComp = () => {
    const config = { attributes: true, childList: true, subtree: true };
    const callback = (mutationList, observer) => {
        const targ = Array.from(document.getElementsByClassName("msp-logo"));
        if (targ.length > 0 && targ[0].style.display !== "none")
            targ[0].style.display = "none";
        const selectionControls = Array.from(document.getElementsByClassName("msp-selection-viewport-controls"));
        if (selectionControls.length > 0 &&
            selectionControls[0].style.display !== "none")
            selectionControls[0].style.display = "none";
    };
    const observer = new MutationObserver(callback);
    const whatToObserve = Array.from(document.getElementsByClassName("molstar-entrypoint"));
    observer.observe(whatToObserve[0], config);
};
