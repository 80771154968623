import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { ControlledAccordion } from "../../Controls/ControlledAccordion";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
export function MeasurementAccordion({ children, name, }) {
    const [isOpen, setIsOpen] = useState(false);
    return (_jsx(ControlledAccordion, Object.assign({ open: isOpen, callback: () => setIsOpen((prev) => !prev), hoverBackground: true, noIcon: true, name: _jsxs(Box, Object.assign({ display: "flex", justifyContent: "start", alignItems: "center", sx: { ml: "-0.25rem" } }, { children: [isOpen ? (_jsx(ArrowDropDownIcon, { fontSize: "small" })) : (_jsx(ArrowRightIcon, { fontSize: "small" })), _jsxs(Typography, Object.assign({ sx: { fontSize: "13px" } }, { children: [name, " "] }))] })), backgroundColor: "transparent" }, { children: _jsx(Box, Object.assign({ sx: { overflowY: "auto", maxHeight: "4rem" } }, { children: children })) })));
}
