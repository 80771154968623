import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Link, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
export function MailContactButton() {
    return (_jsxs(MenuItem, Object.assign({ component: Link, href: "https://www.acellera.com/contact-us/", underline: "none", color: "inherit", target: "_blank", rel: "noopener", dense: true }, { children: [_jsx(ListItemIcon, { children: _jsx(EmailIcon, {}) }), _jsx(ListItemText, { children: "Contact us by mail" })] }), "help-mail"));
}
