var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { TextField, Box } from "@mui/material";
import { Controller } from "react-hook-form";
export function TextFieldPM({ error, formKey, label, disabled = false, max, min, type = "text", size = "small", marginTop = 1, stepsize, defaultValue, }) {
    return (_jsx(Box, Object.assign({ sx: { marginTop: marginTop } }, { children: _jsx(Controller, { name: formKey, defaultValue: defaultValue && defaultValue != 0 ? defaultValue : undefined, render: (_a) => {
                var _b = _a.field, { ref } = _b, field = __rest(_b, ["ref"]), { fieldState: { error: defaulterror } } = _a;
                const _error = error ? error : defaulterror === null || defaulterror === void 0 ? void 0 : defaulterror.message;
                return (_jsx(TextField, Object.assign({}, field, { value: field.value || field.value == 0 ? field.value : "", disabled: disabled, label: label, type: type, error: !!error, helperText: _error, sx: { width: "100%" }, size: size, InputProps: {
                        inputProps: {
                            max: max,
                            min: min,
                            step: stepsize,
                        },
                    }, InputLabelProps: { shrink: field.value !== "" }, onChange: (e) => {
                        field.onChange(e.target.value);
                    } })));
            } }) })));
}
