var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState, useEffect } from "react";
import { useLocation } from "wouter";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid } from "@mui/material";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import produce from "immer";
import { Apps } from "../../GenericViewerState/SharedState";
import { JobName } from "../JobName";
import { FormGroup } from "./FormGroup";
import { formSchema, defaultValues } from "./SchemaGenerator";
import { extractFieldsThatDisableOthers, updateDisabledFields } from "../utils";
import { AppListStore, } from "../../apps/ApplicationsMenu/appList.store";
import { DCBrowserStore } from "../../DatacenterBrowser/datacenterBrowser.store";
import { flushFolderInVFS } from "../../apps/utils";
import { shallow } from "zustand/shallow";
import { ErrorBox } from "./ErrorBox";
import { SubmitButton } from "./SubmitButton";
import { AppInfo } from "./AppInfo";
import { runPythonValidations } from "./appInputValidation";
import { userAuthStore } from "../../Authentication/userAuthState";
export function FormFromManifest({ pyodide, appManifest, }) {
    //============== CLEANUP PYODIDE'S VFS =================//
    useEffect(() => {
        flushFolderInVFS(pyodide, "/tmp");
    }, [pyodide]);
    //== REDIRECT TO JOB PANEL AFTER SUCCESSFUL SUBMISSION ==//
    const [, setLocation] = useLocation();
    const onSubmitSuccess = () => {
        setSubmissionStatus(undefined);
        setLocation(`${Apps.jobs}`);
    };
    const { submitJob, preselectedValues, reSetPreselectedVal, appLimitations, appManifestFormFields, } = AppListStore((state) => ({
        submitJob: state.submitJob,
        preselectedValues: state.preselectedValues,
        reSetPreselectedVal: state.reSetPreselectedVal,
        appLimitations: state.appLimitations,
        appManifestFormFields: state.appManifestFormFields,
    }), shallow);
    //========= preselected parameters s.a. kdeep model selected from kdeeptrainer output
    const appName = appManifest.name;
    const preselectedAppParams = preselectedValues[appName];
    /* Group form fields according to "groups" and "linked_groups" */
    const appParams = appManifest.params;
    const manifestGroups = appName in appManifestFormFields ? appManifestFormFields[appName] : [];
    const formDefaults = defaultValues(manifestGroups, preselectedAppParams);
    const formMethods = useForm({
        resolver: yupResolver(formSchema(manifestGroups)),
        defaultValues: formDefaults,
    });
    const { handleSubmit, control, reset } = formMethods;
    const [disabledFields, setDisabledFields] = useState([]);
    const setFormValues = (newValues) => {
        // Enable the fields we want to set if thew were disabled
        const newValKeys = Object.keys(newValues);
        setDisabledFields((fieldNames) => fieldNames.filter((fieldName) => !newValKeys.includes(fieldName)));
        // Set new values
        reset(Object.assign(Object.assign({}, formDefaults), newValues), { keepDefaultValues: true });
    };
    if (preselectedAppParams)
        reSetPreselectedVal();
    const [submissionStatus, setSubmissionStatus] = useState(undefined);
    //==================== Watched fields: when filling one field disables another
    // Watch fields that disable others. Set disabledFields accordingly
    const { fieldsDisableTrigger, disabledToTrigger } = extractFieldsThatDisableOthers(appParams);
    const watchedFields = useWatch({ control, name: fieldsDisableTrigger });
    useEffect(() => {
        // create a dictionary with the wathced fields and a boolean for weher or not they have a value
        const watchedFieldHasVal = {};
        watchedFields.forEach((fieldVal, idx) => {
            const fieldKey = fieldsDisableTrigger[idx];
            watchedFieldHasVal[fieldKey] = !!fieldVal || fieldVal === 0;
        });
        const newFields = produce(disabledFields, (draftDisabledFields) => {
            updateDisabledFields(draftDisabledFields, watchedFieldHasVal, disabledToTrigger);
        });
        setDisabledFields(newFields);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchedFields]);
    //========== Submit and errors
    const [submissionError, setSubmissionError] = useState({
        title: "",
        details: "",
    });
    const [errorBoxIsOpen, setOpenErrorBox] = useState(false);
    const onSubmitError = (error) => {
        setSubmissionError(error);
        setSubmissionStatus(undefined);
        setOpenErrorBox(true);
    };
    const resetErrorMsg = () => {
        setSubmissionError({
            title: "",
            details: "",
        });
    };
    const [project] = DCBrowserStore((state) => [state.project], shallow);
    //Submit
    const onSubmit = (args) => __awaiter(this, void 0, void 0, function* () {
        setSubmissionStatus("Validating...");
        /*
          Run validation functions defined in appLimitations, if all goes well
          it returns true and proceeds with submission.
        */
        const userInfo = userAuthStore.getState().userInfo;
        if (!userInfo)
            throw Error("user data not available!");
        runPythonValidations(pyodide, onSubmitError, appLimitations, appManifest, args).then((isOk) => {
            if (!isOk)
                return;
            setSubmissionStatus("Submitting...");
            setOpenErrorBox(false);
            resetErrorMsg();
            submitJob(pyodide, args, appManifest, userInfo.userId, project, onSubmitSuccess, onSubmitError);
        });
    });
    return (_jsxs(Box, Object.assign({ sx: { py: 1, px: 2 } }, { children: [_jsx(AppInfo, { appManifest: appManifest, setFormValues: setFormValues }), _jsxs("form", Object.assign({ onSubmit: handleSubmit(onSubmit), noValidate: true }, { children: [_jsx(FormProvider, Object.assign({}, formMethods, { children: _jsxs(Grid, Object.assign({ container: true, direction: "column", spacing: 2, sx: { pb: 2 } }, { children: [manifestGroups.map((manifestGroup) => (_jsx(FormGroup, { pyodide: pyodide, manifestGroup: manifestGroup, disabledFields: disabledFields }, manifestGroup.name))), _jsx(JobName, {})] })) })), _jsx(ErrorBox, { open: errorBoxIsOpen, setOpen: setOpenErrorBox, error: submissionError, resetErrorMsg: resetErrorMsg }), _jsx(Box, Object.assign({ sx: { mt: 2 } }, { children: _jsx(SubmitButton, { submissionStatus: submissionStatus }) }))] }))] })));
}
