var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/*
  SHOULD BE MIGRATED TO SHARED LIB AND FIX THE files.fileMap TYPE ERRORS
*/
import { shallow } from "zustand/shallow";
import { useEffect, useRef } from "react";
import { FileHelper, FileList, FileBrowser, ChonkyActions, FileContextMenu, FileToolbar, FileNavbar, setChonkyDefaults, supportedFiles, ChonkyIconFA, dispatchConfirmationDialogEvent, userAuthStore, } from "..";
import { actionsToDisable, customFileActions } from "./fileActions";
import { ActiveDataset, DCBrowserStore } from "./datacenterBrowser.store";
import { fileHandler } from "./SingleFileTools";
import { DownloadDatasetBtn } from "./ActionButtons/DownloadDatasetBtn";
import { DisplayJobResultsBtn } from "./ActionButtons/DisplayJobResultsBtn";
import { UseResultsInKdeep } from "./ActionButtons/UseResultsInKdeep";
import { getDatasets } from "../apps/results/fetchDcData";
import { Grid } from "@mui/material";
import { DisplayDatasetFolder } from "./ActionButtons/DisplayDatasetFolder";
export function DatacenterBrowser({ vss, molstar, pyodide, pyodideReady, filterExtension, NAPGenericStore, showFileActions = false, dialogMode = false, resolve = () => { }, dataset, }) {
    var _a;
    const _dataset = dataset ? dataset : ActiveDataset.user;
    const { getActiveDatasetEntries, setCurrentFolderId, currentFolderId, folderChain, currentFolderContent, setSelectedFiles, activeDataset, setActiveDataset, } = DCBrowserStore();
    // const [currLoc] = useLocation();
    const datacenterEntries = getActiveDatasetEntries();
    useEffect(() => {
        if (_dataset === ActiveDataset.user &&
            activeDataset !== ActiveDataset.user) {
            setActiveDataset(ActiveDataset.user);
            setCurrentFolderId(datacenterEntries.rootFolderId);
        }
        else if (_dataset === ActiveDataset.CATH &&
            activeDataset !== ActiveDataset.CATH) {
            setActiveDataset(ActiveDataset.CATH);
            setCurrentFolderId(datacenterEntries.rootFolderId);
        }
    }, [_dataset]);
    const [setOpenFileContentPanel, setContentPanelFileId] = NAPGenericStore((state) => [state.setOpenFileContentPanel, state.setContentPanelFileId], shallow);
    const _enableListView = ChonkyActions.EnableListView.id;
    const findFileInParent = (parentId) => {
        var _a;
        const searchDir = datacenterEntries.fileMap[parentId];
        if (!searchDir)
            return;
        const myFileID = (_a = searchDir.childrenIds) === null || _a === void 0 ? void 0 : _a.find((e) => {
            const ext = e.split(".").pop();
            return ext && supportedFiles.topology.includes(ext);
        });
        return myFileID;
    };
    const deleteJobsFromFBrowser = (files) => { var _a, files_1, files_1_1; return __awaiter(this, void 0, void 0, function* () {
        var _b, e_1, _c, _d;
        const ok = yield dispatchConfirmationDialogEvent({
            message: `Are you sure you want to delete the following job${files.length > 1 ? "s" : ""}? ${files.map((f) => f.name).join(", ")}`,
        });
        if (!ok)
            return;
        try {
            for (_a = true, files_1 = __asyncValues(files); files_1_1 = yield files_1.next(), _b = files_1_1.done, !_b;) {
                _d = files_1_1.value;
                _a = false;
                try {
                    const file = _d;
                    const datasetID = Number(file.id.replace("dc://", ""));
                    if (isNaN(datasetID))
                        continue;
                    const datasets = yield getDatasets({
                        datasetid: datasetID,
                    });
                    if (!datasets || datasets.length === 0)
                        continue;
                    const execId = datasets[0]["execid"];
                    const userInfo = userAuthStore.getState().userInfo;
                    if (!userInfo)
                        return;
                    DCBrowserStore.getState().removeJobResults([execId], userInfo.userId, true);
                }
                finally {
                    _a = true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (!_a && !_b && (_c = files_1.return)) yield _c.call(files_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }); };
    const defaultFileActionHandler = (data) => {
        if (data.id === customFileActions.deleteJob.id) {
            deleteJobsFromFBrowser(data.state.selectedFiles);
        }
        if (data.id === ChonkyActions.OpenFiles.id) {
            const { targetFile, files } = data.payload;
            const fileToOpen = targetFile !== null && targetFile !== void 0 ? targetFile : files[0];
            if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
                setCurrentFolderId(fileToOpen.id);
                return;
            }
            fileHandler(vss, molstar, pyodide, fileToOpen, NAPGenericStore, setOpenFileContentPanel, setContentPanelFileId, findFileInParent, _dataset);
        }
    };
    const dialogFileActionHandler = (data) => {
        if (data.id === ChonkyActions.OpenFiles.id) {
            const { targetFile, files } = data.payload;
            const fileToOpen = targetFile !== null && targetFile !== void 0 ? targetFile : files[0];
            if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
                /* double-click folder, go inside */
                setCurrentFolderId(fileToOpen.id);
                return;
            }
            else {
                resolve(fileToOpen);
                return;
            }
        }
        else if (data.id === ChonkyActions.ChangeSelection.id) {
            setSelectedFiles(data.payload.selection);
        }
    };
    const handleFileAction = dialogMode
        ? dialogFileActionHandler
        : defaultFileActionHandler;
    const fileBrowserRef = useRef(null);
    const [loadingDCFile] = NAPGenericStore((state) => [state.loadingDCFile], shallow);
    return (_jsx(_Fragment, { children: _jsxs(Grid, Object.assign({ sx: { height: "100%" }, container: true, direction: "column", justifyContent: "center", alignItems: "flex-start" }, { children: [!dialogMode ? (_jsxs(Grid, Object.assign({ item: true, xs: "auto" }, { children: [_jsx(DisplayJobResultsBtn, { currentFolderContent: currentFolderContent, currentFolderId: currentFolderId, pyodide: pyodide, vss: vss, molstar: molstar, NAPGenericStore: NAPGenericStore, disabled: loadingDCFile }), _jsx(DisplayDatasetFolder, { currentFolderContent: currentFolderContent, currentFolderId: currentFolderId, pyodide: pyodide, vss: vss, molstar: molstar, NAPGenericStore: NAPGenericStore, folderName: (_a = datacenterEntries.fileMap[currentFolderId]) === null || _a === void 0 ? void 0 : _a.name, dataset: _dataset, disabled: loadingDCFile }), _jsx(UseResultsInKdeep, { currentFolderContent: currentFolderContent, disabled: loadingDCFile }), _jsx(DownloadDatasetBtn, { currentFolderContent: currentFolderContent, currentFolderId: currentFolderId, disabled: loadingDCFile, dataset: _dataset })] }))) : null, _jsx(Grid, Object.assign({ item: true, xs: true, sx: { width: "100%" } }, { children: _jsxs(FileBrowser, Object.assign({ ref: fileBrowserRef, 
                        //@ts-ignore
                        folderChain: folderChain, 
                        //@ts-ignore
                        files: currentFolderContent, 
                        //@ts-ignore
                        onFileAction: handleFileAction, defaultFileViewActionId: _enableListView, disableDefaultFileActions: actionsToDisable, fileActions: showFileActions ? Object.values(customFileActions) : [] }, { children: [_jsx(FileNavbar, {}), _jsx(FileToolbar, {}), _jsx(FileList, {}), _jsx(FileContextMenu, {})] })) }))] })) }));
}
setChonkyDefaults({
    iconComponent: ChonkyIconFA,
    disableDragAndDropProvider: true,
});
