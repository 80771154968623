import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel, Select } from "@mui/material";
import { Controller } from "react-hook-form";
export function SelectPM({ formKey, disabled = false, label, options, defaultValue, }) {
    return (_jsx(Box, Object.assign({ sx: { minWidth: 100, marginTop: 1 } }, { children: _jsx(FormControl, Object.assign({ disabled: disabled, fullWidth: true, size: "small" }, { children: _jsx(Controller, { name: formKey, defaultValue: defaultValue, render: ({ field, fieldState: { error } }) => (_jsxs(_Fragment, { children: [_jsx(InputLabel, Object.assign({ size: "small", shrink: !!field.value }, { children: label })), _jsx(Select, Object.assign({}, field, { label: label, notched: !!field.value, error: !!error }, { children: options.map((option) => (_jsx(MenuItem, Object.assign({ value: option.value }, { children: option.label }), option.value))) }))] })) }) })) })));
}
