import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * @author Timur Kuzhagaliyev <tim.kuzh@gmail.com>
 * @author Panagiotis Tourlas <p.tourlas@acellera.com>
 * @copyright 2022
 * @license MIT
 */
import { memo, useContext } from "react";
import { ChonkyIconContext } from "../../util/icon-helper";
import { c, makeLocalChonkyStyles } from "../../util/styles";
import { useDndIcon } from "./FileEntry-hooks";
export const GridEntryDndIndicator = memo((props) => {
    const { className: externalClassName, dndState } = props;
    const dndIconName = useDndIcon(dndState);
    const classes = useStyles(dndState);
    const ChonkyIcon = useContext(ChonkyIconContext);
    if (!dndIconName)
        return null;
    const className = c({
        [classes.dndIndicator]: true,
        [externalClassName]: true,
    });
    return (_jsx("div", Object.assign({ className: className }, { children: _jsx(ChonkyIcon, { icon: dndIconName }) })));
});
const useStyles = makeLocalChonkyStyles((theme) => ({
    dndIndicator: {
        color: (dndState) => dndState.dndIsOver
            ? dndState.dndCanDrop
                ? theme.dnd.canDropColor
                : theme.dnd.cannotDropColor
            : "#000",
        boxSizing: "border-box",
        position: "absolute",
        fontSize: "1.2em",
        opacity: 0.6,
        padding: 6,
        "&:before": {
            borderBottom: "50px solid transparent",
            borderLeft: "50px solid #fff",
            position: "absolute",
            content: '""',
            zIndex: -1,
            left: 0,
            top: 0,
        },
    },
}));
