import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * @author Timur Kuzhagaliyev <tim.kuzh@gmail.com>
 * @author Panagiotis Tourlas <p.tourlas@acellera.com>
 * @copyright 2022
 * @license MIT
 */
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useMemo, memo } from "react";
import { ChonkyActions } from "../../action-definitions/index";
import { important, makeGlobalChonkyStyles } from "../../util/styles";
import { useFolderChainItems } from "./FileNavbar-hooks";
import { FolderChainButton } from "./FolderChainButton";
import { SmartToolbarButton } from "./ToolbarButton";
export const FileNavbar = memo(() => {
    const classes = useStyles();
    const folderChainItems = useFolderChainItems();
    const folderChainComponents = useMemo(() => {
        const components = [];
        for (let i = 0; i < folderChainItems.length; ++i) {
            const key = `folder-chain-${i}`;
            const component = (_jsx(FolderChainButton, { first: i === 0, current: i === folderChainItems.length - 1, item: folderChainItems[i] }, key));
            components.push(component);
        }
        return components;
    }, [folderChainItems]);
    return (_jsx(Box, Object.assign({ className: classes.navbarWrapper }, { children: _jsxs(Box, Object.assign({ className: classes.navbarContainer }, { children: [_jsx(SmartToolbarButton, { fileActionId: ChonkyActions.OpenParentFolder.id }), _jsx(Breadcrumbs, Object.assign({ className: classes.navbarBreadcrumbs, classes: { separator: classes.separator } }, { children: folderChainComponents }))] })) })));
});
const useStyles = makeGlobalChonkyStyles((theme) => ({
    navbarWrapper: {
        paddingBottom: theme.margins.rootLayoutMargin,
    },
    navbarContainer: {
        display: "flex",
    },
    upDirectoryButton: {
        fontSize: important(theme.toolbar.fontSize),
        height: theme.toolbar.size,
        width: theme.toolbar.size,
        padding: "0px !important",
    },
    navbarBreadcrumbs: {
        fontSize: important(theme.toolbar.fontSize),
        flexGrow: 100,
    },
    separator: {
        marginRight: important(4),
        marginLeft: important(4),
    },
}));
