import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * @author Timur Kuzhagaliyev <tim.kuzh@gmail.com>
 * @author Panagiotis Tourlas <p.tourlas@acellera.com>
 * @copyright 2022
 * @license MIT
 */
import { memo } from "react";
import { makeLocalChonkyStyles } from "../../util/styles";
const getRandomInt = (min, max) => {
    return min + Math.floor(Math.random() * Math.floor(max - min));
};
export const TextPlaceholder = memo((props) => {
    const { minLength, maxLength } = props;
    const placeholderLength = getRandomInt(minLength, maxLength);
    const whitespace = "&nbsp;".repeat(placeholderLength);
    const classes = useStyles();
    return (_jsx("span", { className: classes.textPlaceholder, dangerouslySetInnerHTML: { __html: whitespace } }));
});
const useStyles = makeLocalChonkyStyles(() => ({
    "@keyframes loading-placeholder": {
        "0%": { opacity: 0.2 },
        "50%": { opacity: 0.4 },
        "100%": { opacity: 0.2 },
    },
    textPlaceholder: {
        animationName: "$loading-placeholder",
        animationIterationCount: "infinite",
        animationTimingFunction: "linear",
        animationDuration: "1.5s",
        backgroundColor: "#ccc",
        whiteSpace: "nowrap",
        overflow: "hidden",
        borderRadius: 4,
        maxWidth: "40%",
        minWidth: 20,
    },
}));
