var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getDatasets } from "../apps/results/fetchDcData";
export function downloadFSFile(filePath, pyodide) {
    return __awaiter(this, void 0, void 0, function* () {
        const isZip = filePath.endsWith(".zip");
        const encoding = isZip ? "binary" : "utf8";
        const fileContent = yield pyodide.FS.readFile(filePath, encoding);
        const mimeType = isZip ? "application/zip" : "text/plain";
        const blob = new Blob([fileContent], {
            type: mimeType,
        });
        const url = URL.createObjectURL(blob);
        const fileName = filePath.split("/").pop();
        const anchorElement = document.createElement("a");
        anchorElement.href = url;
        anchorElement.download = fileName !== undefined ? fileName : "examplefile";
        anchorElement.click();
        window.URL.revokeObjectURL(url);
    });
}
function getDatasetInfo(appId) {
    return __awaiter(this, void 0, void 0, function* () {
        const datasets = yield getDatasets({
            remotepath: `apps/${appId}/tests/file`,
            filelist: true,
        });
        let dsid;
        let basepath;
        if (datasets && datasets.length > 0) {
            dsid = datasets[0]["id"];
            basepath = datasets[0]["files"].includes("tests/") ? "tests/" : "";
        }
        return { dsid, basepath };
    });
}
function convertToDCpath(dsid, basepath, isFile, val) {
    if (typeof val !== "string" || !isFile || !dsid)
        return val;
    let _val = !basepath ? val.replace(/^tests\//, "") : val;
    return `dc://${dsid}?files=${_val}`;
}
function formatLinkedGroupValues(exampleArgs, dsid, basepath) {
    const grouped = {};
    Object.keys(exampleArgs).forEach((key) => {
        const item = exampleArgs[key];
        const group = item.group;
        if (!group)
            return;
        const keyValue = item.key;
        const values = Array.isArray(item.value) ? item.value : [item.value];
        if (!grouped[group]) {
            grouped[group] = [];
        }
        values.forEach((value, index) => {
            if (!grouped[group][index]) {
                grouped[group][index] = {};
            }
            grouped[group][index][keyValue] = convertToDCpath(dsid, basepath, item.isFile, value);
        });
    });
    return grouped;
}
export function formatExampleValues(appExample, appId) {
    return __awaiter(this, void 0, void 0, function* () {
        const autoFillArgs = {};
        const hasFile = Object.values(appExample.arguments).some((e) => e.isFile);
        let dsid;
        let basepath;
        if (hasFile) {
            ({ dsid, basepath } = yield getDatasetInfo(appId));
        }
        for (let label in appExample.arguments) {
            const vals = appExample.arguments[label];
            if (vals.nargs) {
                const arrayVals = Array.isArray(vals.value) ? vals.value : [vals.value];
                arrayVals.forEach((val, i) => {
                    if (!(vals.key in autoFillArgs)) {
                        autoFillArgs[vals.key] = [];
                    }
                    const _val = convertToDCpath(dsid, basepath, vals.isFile, val);
                    autoFillArgs[vals.key].push({ [vals.key]: _val });
                });
            }
            else if (!vals.group) {
                autoFillArgs[vals.key] = convertToDCpath(dsid, basepath, vals.isFile, vals.value);
            }
        }
        const groups = formatLinkedGroupValues(appExample.arguments, dsid, basepath);
        return Object.assign(Object.assign({}, autoFillArgs), groups);
    });
}
