// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { create } from "zustand";
import { diff } from "deep-object-diff";
import { DCBrowserStore } from "../DatacenterBrowser";
import { arraysHaveSameElements } from "../utils/compareArrays";
import { userAuthStore } from "../Authentication/userAuthState";
export const statusLabels = {
    0: "Waiting data",
    1: "Validating",
    2: "Waiting approval",
    3: "Running",
    4: "Completed",
    5: "Error",
    6: "Queued",
    7: "Sleeping",
};
export const statusToColor = {
    "Waiting data": "#fb8500",
    Validating: "#fb8500",
    "Waiting approval": "#fb8500",
    Running: "#118ab2",
    Completed: "#06d6a0",
    Error: "#ef476f",
    Queued: "#ffd166",
    Sleeping: "#fb8500",
};
const jobFilterDefaults = {
    in_status: Object.keys(statusLabels).map((e) => Number(e)),
    app_id: "",
    newer_than: -1,
    older_than: -1,
    limit: 10,
    count: false,
};
const computeQStatus = (queue_jobs) => {
    const hasQueuedJobs = queue_jobs.some(({ status }) => status === "Queued");
    if (queue_jobs.length >= 10 && !hasQueuedJobs) {
        return "busy";
    }
    else if (hasQueuedJobs) {
        return "congested";
    }
    else
        return "running";
};
export const jobListStore = create((set, get) => ({
    jobList: [],
    jobFilters: jobFilterDefaults,
    modifiedJobFilters: [],
    queueJobs: [],
    queueStatus: undefined,
    setJobList: (newJobList) => {
        const userInfo = userAuthStore.getState().userInfo;
        const currJobList = get().jobList;
        const _diff = diff(currJobList, newJobList);
        if (Object.keys(_diff).length === 0) {
            return;
        }
        const prevRunningJobs = currJobList.filter((j) => j.status === "Running");
        const newRunningJobs = newJobList.filter((j) => j.status === "Running");
        if (prevRunningJobs.length > newRunningJobs.length && userInfo) {
            window.highPriorityMsg({
                type: "fetch-datasets",
                userId: userInfo.userId,
                url: window.location.origin,
                project: DCBrowserStore.getState().project,
                /*
                  do not disturb the user,
                  we set the current dir only on first fetch
                */
                setCurrentDir: false,
            });
        }
        set({ jobList: newJobList });
    },
    setModifiedJobFilters: (filters) => {
        const modFilters = [];
        for (const filterKey in filters) {
            if (!(filterKey in jobFilterDefaults)) {
                modFilters.push(filterKey);
                continue;
            }
            const defVal = jobFilterDefaults[filterKey];
            const newVal = filters[filterKey];
            let isEqual = false;
            if (Array.isArray(defVal) && Array.isArray(newVal)) {
                isEqual = arraysHaveSameElements(defVal, newVal);
            }
            else {
                isEqual = defVal === newVal;
            }
            if (!isEqual)
                modFilters.push(filterKey);
        }
        set({ modifiedJobFilters: modFilters });
    },
    setJobFilters: (filters) => {
        window.highPriorityMsg({ type: "set-job-filters", filters: filters });
        get().setModifiedJobFilters(filters);
        set({ jobFilters: filters });
    },
    updateQueueStatus: (queueJobs) => {
        set({ queueJobs: queueJobs, queueStatus: computeQStatus(queueJobs) });
    },
}));
export function parseJobInfo(jobData) {
    return {
        container: jobData.container,
        appid: jobData.appid,
        date: "",
        parentId: jobData.parentid !== "" ? jobData.parentid : "-",
        status: statusLabels[Number(jobData.status)],
        traceId: jobData.id,
        shortTraceId: jobData.id.substring(0, 8).toUpperCase(),
        userName: "-",
        name: jobData.name,
        output_id: "-",
        error_info: jobData.error_info,
        progressper: jobData["progressper"],
        progressinfo: jobData["progressinfo"],
        children: jobData.children
            ? jobData.children.map((j) => parseJobInfo(j))
            : undefined,
    };
}
