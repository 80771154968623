// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChonkyActions } from "../../action-definitions/index";
import { selectFolderChain } from "../../redux/selectors";
import { thunkRequestFileAction } from "../../redux/thunks/dispatchers.thunks";
import { FileHelper } from "../../util/file-helper";
export const useFolderChainItems = () => {
    const folderChain = useSelector(selectFolderChain);
    const dispatch = useDispatch();
    const folderChainItems = useMemo(() => {
        const items = [];
        if (!folderChain)
            return items;
        for (let i = 0; i < folderChain.length; ++i) {
            const file = folderChain[i];
            items.push({
                file,
                disabled: !file,
                onClick: !FileHelper.isOpenable(file) || i === folderChain.length - 1
                    ? undefined
                    : () => dispatch(thunkRequestFileAction(ChonkyActions.OpenFiles, {
                        targetFile: file,
                        files: [file],
                    })),
            });
        }
        return items;
    }, [dispatch, folderChain]);
    return folderChainItems;
};
