import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import { ActionIconButton } from "../../..";
export const CameraFocusButton = ({ focusCb, mouseOverCb, mouseOutCb, disabled, tooltipMsg, }) => {
    const _ = () => { }; /* Empty function because I can. */
    const onMouseOver = mouseOverCb ? mouseOverCb : _;
    const onMouseOut = mouseOutCb ? mouseOutCb : _;
    return (_jsx(ActionIconButton, Object.assign({ onClick: focusCb, tooltipMsg: tooltipMsg, disabled: disabled, onMouseOver: onMouseOver, onMouseOut: onMouseOut, backgroundColor: "white", 
        // iconColor={"white"}
        backgroundHoverColor: "#0b5394", iconHoverColor: "#ffab40" }, { children: _jsx(CenterFocusStrongIcon, { className: "camfocusIcon" }) })));
};
