// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
export const executeWhenPyodideReady = (cb) => {
    if (window.highPriorityWorker && window.pyodideWorkerReady) {
        cb();
    }
    else {
        setTimeout(() => executeWhenPyodideReady(cb));
    }
};
