// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import * as yup from "yup";
import countryList from "../static/assets/countryList.json";
export const defaultValues = {
    username: "",
    mail: "",
    password: "",
    passwordConfirm: "",
    affiliation: "",
    country: "",
    city: "",
};
export const RegistrationSchema = yup
    .object({
    username: yup.string().required("Name is required"),
    mail: yup
        .string()
        .email("Must be a valid email")
        .required("Email is required"),
    password: yup
        .string()
        .required("Password is required")
        .test("password-strength", "Password MUST have at least 8 characters and contain at least: a lower-case letter, an upper-case letter, a number and a symbol/special character.", (value) => {
        if (!value)
            return true;
        const isLongEnough = value && value.length >= 8;
        const hasLowerCase = /[a-z]/.test(value);
        const hasUpperCase = /[A-Z]/.test(value);
        const hasDigit = /\d/.test(value);
        const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(value);
        const result = isLongEnough &&
            hasLowerCase &&
            hasUpperCase &&
            hasDigit &&
            hasSymbol;
        return !!result;
    }),
    passwordConfirm: yup
        .string()
        .required("Password confirmation is required")
        .oneOf([yup.ref("password"), null], "Passwords must match"),
    affiliation: yup.string().required("Affiliation is required"),
    country: yup
        .string()
        .required("Country is required")
        .oneOf(countryList.map((c) => c.text), "Please select a country"),
    city: yup.string().required("City is required"),
})
    .required();
export const RegistrationCompletionSchema = yup
    .object({
    affiliation: yup.string().required("Affiliation is required"),
    country: yup
        .string()
        .required("Country is required")
        .oneOf(countryList.map((c) => c.text), "Please select a country"),
    city: yup.string().required("City is required"),
})
    .required();
