import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Typography, CircularProgress, Box } from "@mui/material";
import { useState } from "react";
import { useInterval } from "../utils";
export function LoaderStatus() {
    const [interval, setInterval] = useState(1000);
    const [progress, setProgress] = useState(0);
    useInterval(() => {
        if (window.pyodideLoaderStatus)
            setProgress(window.pyodideLoaderStatus - 5);
        if (window.pyodideWorkerReady) {
            setInterval(null);
            setProgress(100);
        }
    }, interval);
    return (_jsx(Box, Object.assign({ sx: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pt: 2,
        } }, { children: _jsxs(Box, Object.assign({ sx: {
                position: "relative",
                display: "inline-flex",
            } }, { children: [_jsx(CircularProgress, {}), _jsx(Box, Object.assign({ sx: {
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    } }, { children: _jsx(Typography, Object.assign({ variant: "caption", component: "div", color: "#999999", sx: { pt: "2px" } }, { children: `${progress}%` })) }))] })) })));
}
