// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * We have option IDs in a separate file to avoid circular deps...
 */
export const OptionIds = {
    ShowHiddenFiles: 'show_hidden_files',
    ShowFoldersFirst: 'show_folders_first',
    DarkMode: 'dark_mode',
};
