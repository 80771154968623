var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { RepresentationOptionLabel } from "./RepresentationOptionLabel";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
export function IgnoreLightSwitch({ ignoreLight, onChange, disabled, }) {
    const [reprIgnoreLight, setReprIgnoreLight] = useState(ignoreLight);
    useEffect(() => {
        if (ignoreLight !== reprIgnoreLight) {
            setReprIgnoreLight(ignoreLight);
        }
    }, [ignoreLight]);
    const callback = (e) => __awaiter(this, void 0, void 0, function* () {
        const newVal = e.target.checked;
        onChange(newVal);
    });
    return (_jsxs(Box, Object.assign({ sx: { pl: "11px", display: "flex" }, justifyContent: "flex-start", alignItems: "center" }, { children: [_jsx(RepresentationOptionLabel, { icon: _jsx(EmojiObjectsIcon, {}), text: "Ignore Light", textVariant: "body2" }), _jsx(Switch, { disabled: disabled, checked: reprIgnoreLight, onChange: callback, inputProps: { "aria-label": "controlled" } })] })));
}
