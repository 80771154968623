// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { createTheme } from "@mui/material/styles";
const defaultNAPtheme = createTheme({
    palette: {
        primary: {
            main: "#1d4f91",
        },
        secondary: {
            main: "#ffab40",
            light: "#e6e6e6",
        },
    },
});
export default defaultNAPtheme;
