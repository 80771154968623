var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Button, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { getDatasets, readDCfile } from "../apps/results/fetchDcData";
import { userAuthStore } from "../Authentication/userAuthState";
import { shallow } from "zustand/shallow";
export function DownloadFilesBtn({ tags }) {
    const userInfo = userAuthStore((state) => [state.userInfo], shallow);
    const userIsAuth = !!userInfo;
    const downloadExamples = () => __awaiter(this, void 0, void 0, function* () {
        const datasets = yield getDatasets({ tags: tags });
        if (!datasets || datasets.length == 0)
            return;
        const dsID = datasets[0]["id"];
        const response = yield readDCfile(dsID);
        if (!response || typeof response === "string")
            return;
        const u8a = new Uint8Array(response);
        const blob = new Blob([u8a], {
            type: "application/x-gzip",
        });
        const url = URL.createObjectURL(blob);
        const anchorElement = document.createElement("a");
        anchorElement.href = url;
        anchorElement.download = `${dsID}.tar.gz`;
        anchorElement.click();
        window.URL.revokeObjectURL(url);
    });
    return (_jsx(Box, Object.assign({ sx: { mt: 4, mx: 4 } }, { children: userIsAuth ? (_jsxs(_Fragment, { children: [_jsx(Typography, { children: "You can download the files for this tutorial here:" }), _jsx(Button, Object.assign({ variant: "outlined", size: "small", endIcon: _jsx(DownloadIcon, {}), sx: { textTransform: "none" }, onClick: downloadExamples }, { children: "Example files" }))] })) : (_jsxs(_Fragment, { children: [_jsx(Typography, { children: "Please log in to access the example files." }), _jsx(Button, Object.assign({ variant: "outlined", size: "small", endIcon: _jsx(DownloadIcon, {}), sx: { textTransform: "none" }, disabled: true }, { children: "Example files" }))] })) })));
}
