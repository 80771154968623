import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useTheme } from "@mui/material/styles";
import { IconButton, lighten, Tooltip } from "@mui/material";
function RoundActionIconButtonWrapper({ tooltipMsg, children, }) {
    return tooltipMsg ? (_jsx(Tooltip, Object.assign({ title: tooltipMsg, arrow: true, enterDelay: 1000 }, { children: children }))) : (_jsx(_Fragment, { children: children }));
}
export function RoundActionIconButton({ onClick, tooltipMsg, size, active, children, paletteCategory, colorOnHover, disabled, }) {
    const theme = useTheme();
    const highlightColor = theme.palette[paletteCategory ? paletteCategory : "primary"]["main"];
    const highlightBackgroundColor = highlightColor
        ? lighten(highlightColor, 0.8)
        : undefined;
    const color = active ? highlightColor : undefined;
    const backgroundColor = active ? highlightBackgroundColor : undefined;
    return (_jsx(RoundActionIconButtonWrapper, Object.assign({ tooltipMsg: tooltipMsg }, { children: _jsxs(IconButton, Object.assign({ disabled: disabled, onClick: onClick, size: size, sx: {
                color: color,
                backgroundColor: backgroundColor,
                transition: "background 0.5s, color 0.5s",
                "&:hover": colorOnHover && !disabled
                    ? {
                        color: highlightColor,
                        // backgroundColor: highlightBackgroundColor,
                    }
                    : undefined,
            } }, { children: [" ", children] })) })));
}
