import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Grid } from "@mui/material";
export function LocalVideo({ mp4, webm, poster, text }) {
    return (_jsxs(Grid, Object.assign({ container: true, direction: "column", spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true }, { children: text })), mp4 || webm ? (_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx("div", Object.assign({ style: {
                        borderRadius: "10px",
                        overflow: "hidden",
                        width: "600px",
                        height: "335px",
                    } }, { children: _jsxs("video", Object.assign({ controls: true, width: "600px", preload: "none", poster: poster }, { children: [_jsx("source", { src: mp4, type: "video/mp4" }), _jsx("source", { src: webm, type: "video/webm" })] })) })) }))) : null] })));
}
