// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
export function suppressSelection(chart, params) {
    chart.setOption({ animation: false });
    // Re-select what the user unselected
    chart.dispatchAction({
        type: "legendSelect",
        name: params.name,
    });
    chart.setOption({ animation: true });
}
function getPreviousSelections(chartHandler) {
    const plotSeries = chartHandler.getOption().series;
    if (!plotSeries)
        return;
    const nodeIndexes = [];
    plotSeries.forEach((serie) => {
        //@ts-ignore
        const nodeIsSelected = serie.selectedMap;
        if (!nodeIsSelected)
            return;
        const data = serie.data;
        if (!data)
            return;
        const selectedNodeNames = [];
        Object.entries(nodeIsSelected).forEach(([name, isSelected]) => {
            if (isSelected)
                selectedNodeNames.push(name);
        });
        let nodeIndexesSerie = selectedNodeNames
            .map((name) => {
            return data.findIndex((dataPoint) => dataPoint.name === name);
        })
            .filter((e) => e !== -1);
        nodeIndexes.push(...nodeIndexesSerie);
    });
    return nodeIndexes;
}
export function restoreChartPosition(chartHandler) {
    //Reset chart position keeping the selections
    const selNodeIdxs = getPreviousSelections(chartHandler);
    chartHandler.dispatchAction({
        type: "restore",
    });
    if (selNodeIdxs && selNodeIdxs.length > 0)
        chartHandler.dispatchAction({
            type: "select",
            dataIndex: selNodeIdxs,
        });
}
