// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import * as yup from "yup";
export const mailVerificationDefaults = {
    code: "",
};
export const MailVerificationSchema = yup.object({
    code: yup.string().required("Code is required"),
});
