// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * @author Timur Kuzhagaliyev <tim.kuzh@gmail.com>
 * @author Panagiotis Tourlas <p.tourlas@acellera.com>
 * @copyright 2022
 * @license MIT
 */
import { memo, forwardRef } from "react";
import { reduxActions } from "../../redux/reducers";
import { initialRootState } from "../../redux/state";
import { useDTE, usePropReduxUpdate } from "../../redux/store";
import { thunkActivateSortAction, thunkUpdateDefaultFileViewActionId, thunkUpdateRawFileActions, } from "../../redux/thunks/file-actions.thunks";
import { defaultConfig } from "../../util/default-config";
import { useFileBrowserHandle } from "../../util/file-browser-handle";
import { getValueOrFallback } from "../../util/helpers";
export const ChonkyBusinessLogicInner = memo(forwardRef((props, ref) => {
    var _a;
    // ==== Update Redux state
    usePropReduxUpdate(reduxActions.setRawFiles, (_a = props.files) !== null && _a !== void 0 ? _a : initialRootState.rawFiles);
    usePropReduxUpdate(reduxActions.setRawFolderChain, props.folderChain);
    useDTE(thunkUpdateRawFileActions, getValueOrFallback(props.fileActions, defaultConfig.fileActions), getValueOrFallback(props.disableDefaultFileActions, defaultConfig.disableDefaultFileActions));
    useDTE(reduxActions.setExternalFileActionHandler, getValueOrFallback(props.onFileAction, defaultConfig.onFileAction));
    useDTE(reduxActions.setSelectionDisabled, getValueOrFallback(props.disableSelection, defaultConfig.disableSelection, "boolean"));
    useDTE(thunkActivateSortAction, getValueOrFallback(props.defaultSortActionId, defaultConfig.defaultSortActionId));
    useDTE(thunkUpdateDefaultFileViewActionId, getValueOrFallback(props.defaultFileViewActionId, defaultConfig.defaultFileViewActionId, "string"));
    useDTE(reduxActions.setThumbnailGenerator, getValueOrFallback(props.thumbnailGenerator, defaultConfig.thumbnailGenerator));
    useDTE(reduxActions.setDoubleClickDelay, getValueOrFallback(props.doubleClickDelay, defaultConfig.doubleClickDelay, "number"));
    useDTE(reduxActions.setDisableDragAndDrop, getValueOrFallback(props.disableDragAndDrop, defaultConfig.disableDragAndDrop, "boolean"));
    useDTE(reduxActions.setClearSelectionOnOutsideClick, getValueOrFallback(props.clearSelectionOnOutsideClick, defaultConfig.clearSelectionOnOutsideClick, "boolean"));
    // ==== Setup the imperative handle for external use
    useFileBrowserHandle(ref);
    return null;
}));
ChonkyBusinessLogicInner.displayName = "ChonkyBusinessLogicInner";
export const ChonkyBusinessLogic = memo(ChonkyBusinessLogicInner);
ChonkyBusinessLogic.displayName = "ChonkyBusinessLogic";
