// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
export var DatavizControlOptions;
(function (DatavizControlOptions) {
    DatavizControlOptions["delete"] = "delete";
})(DatavizControlOptions || (DatavizControlOptions = {}));
export var DatavizElements;
(function (DatavizElements) {
    DatavizElements["Plots"] = "Plots";
    DatavizElements["Tables"] = "Tables";
})(DatavizElements || (DatavizElements = {}));
