// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * @author Timur Kuzhagaliyev <tim.kuzh@gmail.com>
 * @author Panagiotis Tourlas <p.tourlas@acellera.com>
 * @copyright 2022
 * @license MIT
 */
import hotkeys from "hotkeys-js";
import { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { selectFileActionData } from "../../redux/selectors";
import { useParamSelector } from "../../redux/store";
import { thunkRequestFileAction } from "../../redux/thunks/dispatchers.thunks";
export const HotkeyListener = memo((props) => {
    const { fileActionId } = props;
    const dispatch = useDispatch();
    const fileAction = useParamSelector(selectFileActionData, fileActionId);
    useEffect(() => {
        if (!fileAction || !fileAction.hotkeys || fileAction.hotkeys.length === 0) {
            return;
        }
        const hotkeysStr = fileAction.hotkeys.join(",");
        const hotkeyCallback = (event) => {
            event.preventDefault();
            dispatch(thunkRequestFileAction(fileAction, undefined));
        };
        hotkeys(hotkeysStr, hotkeyCallback);
        return () => hotkeys.unbind(hotkeysStr, hotkeyCallback);
    }, [dispatch, fileAction]);
    return null;
});
