// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { cameraFocus } from "../../3dViewer/Controls/CameraControls/cameraFocus";
import { EchartsActions } from "./interfaces";
import { applyActionOnPlotNodes } from "./utils";
function findImpliedPlotNodeIds(plotOptions, systemFiles, dataNames) {
    // Find data element(s) corresponding to the clicked system
    const series = plotOptions.series;
    if (!series)
        return;
    const impliedDataPoinsIndexes = [];
    plotOptions.series.forEach((serie) => {
        const data = serie.data;
        if (!data)
            return;
        const impliedDataPoinsIndexesSerie = [];
        data.forEach((dataPoint, nodeIdx) => {
            const dataPointValues = dataNames
                .map((name) => dataPoint[name])
                .filter((val) => typeof val === "string");
            const isSystemDataPoint = dataPointValues.some((element) => systemFiles.includes(element));
            if (isSystemDataPoint)
                impliedDataPoinsIndexesSerie.push(nodeIdx);
        });
        impliedDataPoinsIndexes.push(...impliedDataPoinsIndexesSerie);
    });
    return impliedDataPoinsIndexes;
}
export function handleStstemVizChange(plotOptions, system, dataNames, visibility, chartHandler, molstar) {
    const systemFiles = system.files;
    if (!systemFiles)
        return;
    const impliedPlotNodeIds = findImpliedPlotNodeIds(plotOptions, systemFiles, dataNames);
    if (!impliedPlotNodeIds)
        return;
    applyActionOnPlotNodes(visibility ? EchartsActions.select : EchartsActions.unselect, chartHandler, impliedPlotNodeIds);
    const cellRefs = system.cellRef;
    if (visibility && cellRefs)
        cameraFocus(molstar, cellRefs);
}
