var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { supportedFiles } from "../..";
import { Button } from "@mui/material";
import { dispatchControlledNotification, dispatchDeleteControlledNotification, } from "../../NotificationSystem/utils";
import { addPathToNap, dsInfoFromId, getFilesToDownload, } from "../../apps/results/utils";
import { sleep } from "../..//utils/sleep";
import { loadAllFileTypes } from "../../FileLoaders";
import { flushFolderInVFS } from "../../apps/utils";
import { getRawFileContent, downloadDatasetFiles, } from "../../apps/results/fetchDcData";
export function DisplayJobResultsBtn({ currentFolderContent, currentFolderId, pyodide, vss, molstar, NAPGenericStore, disabled, }) {
    const NAPFile = currentFolderContent.find((e) => {
        const ext = e["name"].split(".").pop();
        return ext && supportedFiles.instructions.includes(ext);
    });
    return NAPFile ? (_jsx(Button, Object.assign({ disabled: disabled, variant: "contained", sx: { m: 1 }, onClick: () => displayJobResults(vss, molstar, pyodide, NAPFile, currentFolderId, NAPGenericStore) }, { children: "Show job result" }))) : (_jsx(_Fragment, {}));
}
export function displayJobResults(vss, molstar, pyodide, napFile, folderId, NAPGenericStore) {
    return __awaiter(this, void 0, void 0, function* () {
        const loadingDCFile = NAPGenericStore.getState().loadingDCFile;
        if (loadingDCFile)
            return;
        const napfile_id = napFile.id;
        const { ds_id } = dsInfoFromId(napfile_id);
        if (!ds_id)
            return;
        const setLoadingDCFile = NAPGenericStore.getState().setLoadingDCFile;
        setLoadingDCFile(true);
        dispatchControlledNotification({
            idx: napFile.name,
            type: "info",
            message: `Reading job output...`,
        });
        const napDataStr = yield getRawFileContent(napfile_id, pyodide);
        if (!napDataStr) {
            dispatchDeleteControlledNotification({ idx: napFile.name });
            setLoadingDCFile(false);
            return;
        }
        const napData = JSON.parse(napDataStr);
        const dcFiles = getFilesToDownload(napData);
        if (dcFiles.length === 0) {
            console.error("No files detected");
            dispatchDeleteControlledNotification({ idx: napFile.name });
            setLoadingDCFile(false);
            return;
        }
        addPathToNap(napData, folderId);
        yield sleep(2000);
        yield downloadDatasetFiles(ds_id, pyodide, dcFiles);
        dispatchDeleteControlledNotification({ idx: napFile.name });
        yield loadAllFileTypes({}, napData, vss, molstar, pyodide, NAPGenericStore);
        setLoadingDCFile(false);
        flushFolderInVFS(pyodide, "/tmp");
    });
}
