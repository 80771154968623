import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import "../static/css/LandingPage.css";
//@ts-ignore
import acelleraLogo from "../static/images/LandingPage/Logo_Acellera_white_hd.png";
// //@ts-ignore
// import acelleraLogoWebp from "../static/images/LandingPage/playmolecule_highres_white2.webp";
export function LandingPageFooter() {
    return (_jsxs("div", Object.assign({ style: {
            position: "relative",
            paddingTop: "20px",
            zIndex: 10,
            color: "rgba(250,250,250,0.8)",
            textAlign: "center",
            paddingBottom: "20px",
            backgroundColor: "#101010",
        } }, { children: [_jsx("a", Object.assign({ href: "https://www.acellera.com", target: "_blank" }, { children: _jsx("img", { className: "acellera-logo-footer", src: acelleraLogo }) })), _jsx("div", Object.assign({ style: { marginTop: "15px" } }, { children: "PlayMolecule - 2023 \u00A9" }))] })));
}
