import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Typography } from "@mui/material";
export function RepresentationOptionLabel({ icon, text, textVariant, isLabel, }) {
    return (_jsxs(Box, Object.assign({ display: "flex", alignItems: "center", gap: 1, sx: {
            "& .MuiSvgIcon-root": {
                fontSize: isLabel ? "1.2rem" : "1rem",
                color: "rgba(0, 0, 0, 0.6)",
            },
        } }, { children: [_jsx(_Fragment, { children: icon }), _jsxs(Typography, Object.assign({ variant: textVariant }, { children: [" ", text] }))] })));
}
