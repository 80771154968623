// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * Creates a `Proxy` object with a modified getter.
 * @param arr The array to be enhanced.
 * @returns Same array but with negative indexing capability.
 */
const addReverseIndexing = (arr) => new Proxy(arr, {
    get(target, idx) {
        let _idx;
        if (idx === "length")
            return target.length;
        if (!Number.isNaN(idx)) {
            _idx = parseInt(idx, 10);
            if (_idx < 0 && _idx < -target.length - 1)
                throw new RangeError("Reverse-indexing went out of bounds!");
            if (_idx < 0)
                _idx += target.length + 1;
        }
        return _idx !== undefined ? target[_idx] : undefined;
    },
});
export default addReverseIndexing;
