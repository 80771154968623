// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect, useRef } from "react";
function useCustomEvent(eventName, callback) {
    const eventRef = useRef(() => { });
    useEffect(() => {
        eventRef.current = callback;
        function handleEvent(event) {
            if (!eventRef.current)
                throw Error("Callback not found!");
            eventRef.current(event.detail);
        }
        window.addEventListener(eventName, handleEvent);
        return () => {
            window.removeEventListener(eventName, handleEvent);
        };
    }, [callback, eventName]);
}
export default useCustomEvent;
