var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Button, Divider, Grid, Table, TableBody, TableCell, TableRow, Typography, } from "@mui/material";
// import { downloadFSFile } from "./utils";
import { AppListStore } from "../apps";
import { shallow } from "zustand/shallow";
import { obtainExamples } from "./obtainExamples";
import DownloadIcon from "@mui/icons-material/Download";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { formatExampleValues } from "./utils";
import { userAuthStore } from "../Authentication/userAuthState";
function AppExampleValue({ value, isFile, onExampleFileClick, }) {
    if (!isFile)
        return _jsxs(Box, Object.assign({ sx: { px: "0.3rem" } }, { children: [" ", `${value}`, " "] }));
    let valArray = typeof value === "object" ? value : [value];
    return (_jsxs(Grid, Object.assign({ container: true, direction: "row", spacing: 1 }, { children: [valArray.map((val) => (_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(Button, Object.assign({ size: "small", endIcon: _jsx(DownloadIcon, {}), sx: { textTransform: "none", py: 0, px: "0.3rem" }, onClick: () => onExampleFileClick(val) }, { children: val.replace(/\/+$/, "").split("/").pop() }), val) }), `${val}`))), " "] })));
}
function AppExampleInfo({ appId, appExample, idx, onExampleFileClick, setFormValues, }) {
    const autoFillForm = () => __awaiter(this, void 0, void 0, function* () {
        const autoFillArgs = yield formatExampleValues(appExample, appId);
        setFormValues(autoFillArgs);
    });
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { children: [_jsxs(Box, Object.assign({ sx: { display: "flex", alignItems: "center", gap: 3 } }, { children: [_jsxs(Typography, Object.assign({ variant: "h5", sx: { pb: 1 }, color: "primary" }, { children: ["Example ", idx] })), _jsx(Button, Object.assign({ variant: "outlined", endIcon: _jsx(PlaylistAddIcon, {}), sx: { mb: 2 }, onClick: autoFillForm }, { children: "Auto-fill" }))] })), appExample.description && (_jsx(Typography, Object.assign({ sx: { pb: 1, opacity: 0.9 }, variant: "subtitle1", gutterBottom: true }, { children: appExample.description })))] }), _jsx(Table, Object.assign({ size: "small" }, { children: _jsx(TableBody, { children: Object.entries(appExample.arguments).map(([argName, argData]) => {
                        return (_jsxs(TableRow, { children: [_jsx(TableCell, Object.assign({ sx: {
                                        border: 0,
                                        fontWeight: 600,
                                        pl: 0,
                                        width: "15rem",
                                        verticalAlign: "top",
                                        opacity: 0.7,
                                    } }, { children: argName })), _jsx(TableCell, Object.assign({ sx: {
                                        border: 0,
                                        pl: 0,
                                        height: "43px",
                                        verticalAlign: "top",
                                    } }, { children: _jsx(AppExampleValue, Object.assign({}, argData, { onExampleFileClick: onExampleFileClick })) }))] }, `app-ex-arg-${argName}`));
                    }) }) })), _jsx(Divider, { sx: { my: 4 } })] }));
}
export function AppExamples({ appName, appVersion, setFormValues, }) {
    const [userInfo] = userAuthStore((state) => [state.userInfo], shallow);
    const userIsAuth = !!userInfo;
    const [appList] = AppListStore((s) => [s.appList], shallow);
    const appExamples = obtainExamples(appList, appName);
    const onExampleFileClick = (filePath) => {
        window.highPriorityMsg({
            type: "fetch-examples",
            url: window.location.origin,
            appId: `${appName.toLowerCase()}_v1`,
            filePath: filePath,
        });
    };
    return (_jsx(_Fragment, { children: userIsAuth ? (appExamples && appExamples.length > 0 ? (_jsx(_Fragment, { children: _jsx(Grid, Object.assign({ container: true, direction: "column", alignItems: "flex-start", spacing: 1 }, { children: appExamples.map((appExample, i) => (_jsx(Grid, Object.assign({ item: true, sx: { width: "100%" } }, { children: _jsx(AppExampleInfo, { appExample: appExample, idx: i + 1, onExampleFileClick: onExampleFileClick, setFormValues: setFormValues, appId: `${appName}_${appVersion}` }) }), `ex-section-${i}`))) })) })) : (_jsx(Typography, { children: "The examples for this app are not available yet." }))) : (_jsx(Typography, { children: "Please log in to access the example files." })) }));
}
