// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { NAPPlotStore, NAPTableStore } from "../StateStores";
import { NAPGenericStore, Panels } from "../GenericViewerState";
import { dispatchConfirmationDialogEvent } from "../utils";
import { DatavizElements } from "./interfaces";
//
export function lightenColor(hex) {
    const percent = 0.5;
    // Removing '#' if present
    hex = hex.replace(/^#/, "");
    // Convert hex to RGB
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    // Calculate the lighter color
    r = Math.floor(r + (255 - r) * percent);
    g = Math.floor(g + (255 - g) * percent);
    b = Math.floor(b + (255 - b) * percent);
    // Ensure values stay within 0-255
    r = r < 0 ? 0 : r > 255 ? 255 : r;
    g = g < 0 ? 0 : g > 255 ? 255 : g;
    b = b < 0 ? 0 : b > 255 ? 255 : b;
    // Convert back to hex
    const lighterHex = `#${(r * 65536 + g * 256 + b)
        .toString(16)
        .padStart(6, "0")}`;
    return lighterHex;
}
const closeDataVizPanel = () => NAPGenericStore.getState().setActivePanels(Object.assign(Object.assign({}, NAPGenericStore.getState()._activePanels), { [Panels.plots]: false }));
export const deleteAllDatavizElementIntances = (tabName, numTables, numPlots) => __awaiter(void 0, void 0, void 0, function* () {
    const ok = yield dispatchConfirmationDialogEvent({
        message: `Are you sure you want to close all ${tabName.toLowerCase()}?`,
    });
    if (!ok)
        return;
    if (tabName === DatavizElements.Plots) {
        NAPPlotStore.getState().del_all_plots();
        if (numTables === 0)
            closeDataVizPanel();
    }
    else {
        NAPTableStore.getState().del_all_tables();
        if (numPlots === 0)
            closeDataVizPanel();
    }
});
