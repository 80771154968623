var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { userAuthStore } from "../../Authentication/userAuthState";
import { shallow } from "zustand/shallow";
export function CookiesTest() {
    const clickUserInfo = () => __awaiter(this, void 0, void 0, function* () {
        const result = yield fetch(`${window.location.origin}/v2/auth/user-info`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (result.ok) {
            const resp = yield result.json();
            console.log(resp.data);
        }
        else {
            const resultJson = yield result.json();
            console.log(`Error: ${resultJson.message}`);
        }
    });
    const clickJobList = () => __awaiter(this, void 0, void 0, function* () {
        const result = yield fetch(`${window.location.origin}/v2/jobs`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                newer_than: -1,
                older_than: -1,
                limit: -1,
                not_in_status: [4, 5],
                count: false,
                children: false,
                all_users: true,
            }),
        });
        if (result.ok) {
            const resp = yield result.json();
            console.log(resp);
        }
        else {
            const resultJson = yield result.json();
            console.log(`Error: ${resultJson.message}`);
        }
    });
    const clickApps = () => __awaiter(this, void 0, void 0, function* () {
        const result = yield fetch(`${window.location.origin}/v2/apps`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (result.ok) {
            const resp = yield result.json();
            console.log(resp);
        }
        else {
            const resultJson = yield result.json();
            console.log(`Error: ${resultJson.message}`);
        }
    });
    const clickDatasets = () => __awaiter(this, void 0, void 0, function* () {
        const headers = {
            userOnly: "True",
            taggedOnly: "False",
            completedOnly: "False",
            datasetId: "",
            filePath: "",
            public: "",
            // startsWith: `${msg.userId}/${msg.project}/`,
            tags: "",
            group: "",
            filelist: "True",
            execID: "",
        };
        const result = yield fetch(`${window.location.origin}/v2/datacenter`, {
            method: "GET",
            headers: headers,
        });
        if (result.ok) {
            const resp = yield result.json();
            console.log(resp);
        }
        else {
            const resultJson = yield result.json();
            console.log(`Error: ${resultJson.message}`);
        }
    });
    return (_jsxs("div", { children: [_jsx("div", { children: _jsx("button", Object.assign({ onClick: clickUserInfo }, { children: "Get User Info" })) }), _jsx("div", { children: _jsx("button", Object.assign({ onClick: clickJobList }, { children: "Get Job list" })) }), _jsx("div", { children: _jsx("button", Object.assign({ onClick: clickDatasets }, { children: "Get Datasets" })) }), _jsx("div", { children: _jsx("button", Object.assign({ onClick: clickApps }, { children: "Get Apps" })) })] }));
}
export function CookiesTestLogin() {
    const [userInfo, waitUserInfo, addMissingDataAndSetUserInfo, logout, login, setUserInfo,] = userAuthStore((state) => [
        state.userInfo,
        state.waitUserInfo,
        state.addMissingDataAndSetUserInfo,
        state.logout,
        state.login,
        state.setUserInfo,
    ], shallow);
    const handleSubmit = (e) => __awaiter(this, void 0, void 0, function* () {
        const formData = new FormData(e.currentTarget);
        const email = formData.get("email");
        const password = formData.get("password");
        const userInfo = yield login(email, password);
        if (userInfo) {
            yield setUserInfo();
            console.log("Logged in");
            console.log(userInfo);
        }
        else {
            console.log(`Could not log in`);
        }
    });
    const handleLogout = () => __awaiter(this, void 0, void 0, function* () {
        logout();
    });
    const isAuthenticated = !!userInfo;
    useEffect(() => {
        addMissingDataAndSetUserInfo().then((userInfo) => {
            if (userInfo) {
                console.log("User info found!");
                console.log(userInfo);
            }
            else {
                console.log("User info not found!!");
            }
        });
    }, []);
    return (_jsxs("div", Object.assign({ style: { padding: "10px" } }, { children: [!isAuthenticated ? (waitUserInfo ? (_jsx("p", { children: "Wait..." })) : (_jsx(_Fragment, { children: "Not logged in" }))) : (_jsxs("p", { children: ["Hello ", userInfo === null || userInfo === void 0 ? void 0 : userInfo.username] })), _jsx("br", {}), userInfo ? (_jsx("button", Object.assign({ disabled: waitUserInfo, onClick: handleLogout }, { children: "Log out" }))) : (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsxs("form", Object.assign({ onSubmit: (e) => {
                            e.preventDefault();
                            handleSubmit(e);
                        } }, { children: [_jsxs("div", { children: [_jsx("label", Object.assign({ htmlFor: "email" }, { children: "Email:" })), _jsx("input", { type: "email", id: "email", name: "email", required: true })] }), _jsxs("div", { children: [_jsx("label", Object.assign({ htmlFor: "password" }, { children: "Password:" })), _jsx("input", { type: "password", id: "password", name: "password", required: true })] }), _jsx("button", Object.assign({ disabled: waitUserInfo, type: "submit" }, { children: "Submit" }))] }))] }))] })));
}
