import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Menu } from "@mui/material";
export function SidebarPopupMenu({ handleClose, anchorEl, menuIsOpen, children, arrowPosition, vertical, }) {
    const _arrowPosition = arrowPosition ? arrowPosition : "middle";
    return (_jsx(Menu, Object.assign({ id: "basic-menu", 
        // MenuListProps={{ onMouseLeave: handleClose }}
        anchorEl: anchorEl, open: menuIsOpen, onClose: handleClose, anchorOrigin: vertical
            ? {
                vertical: "bottom",
                horizontal: "center",
            }
            : {
                vertical: "center",
                horizontal: "right",
            }, transformOrigin: vertical
            ? {
                vertical: "top",
                horizontal: "center",
            }
            : {
                vertical: "center",
                horizontal: "left",
            }, PaperProps: {
            sx: {
                overflow: "visible",
                ml: 1,
                "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    bottom: vertical
                        ? undefined
                        : _arrowPosition === "bottom"
                            ? 5
                            : "calc(50% - 10px)",
                    left: vertical ? "calc(100% - 45px)" : -5,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                },
            },
        } }, { children: children })));
}
