import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Dialog, Fade } from "@mui/material";
import { LandingPage } from "../../LandingPage/LandingPage";
import { useLocation } from "wouter";
// import { LandingPageLoading } from "../../LandingPage/LandingPageLoading";
// import { useState } from "react";
export function LandingPagePanel() {
    const [parentLocation] = useLocation();
    const queryParams = new URLSearchParams(window.location.search);
    const from = queryParams.get("from");
    const isActive = parentLocation === "/landing" ||
        (parentLocation === "/login" && from == "/landing");
    // const [showLoading, setShowLoading] = useState(true);
    // setTimeout(() => setShowLoading(false), 3000);
    // Do I need to show the wait screen while pyodid is getting ready?
    return (_jsx(Dialog, Object.assign({ fullScreen: true, open: isActive, TransitionComponent: Fade }, { children: _jsx(LandingPage, {}) })));
}
