import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useRouter, useLocation, Router } from "wouter";
export const NestedRoutes = (props) => {
    const router = useRouter();
    const [parentLocation] = useLocation();
    const nestedBase = `${router.base}${props.base}`;
    // don't render anything outside of the scope
    if (!parentLocation.startsWith(nestedBase))
        return null;
    // we need key to make sure the router will remount when base changed
    return (_jsx(Router, Object.assign({ base: nestedBase }, { children: props.children }), nestedBase));
};
