var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
//@ts-nocheck
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useState, forwardRef, useEffect, useRef } from "react";
import { Box, Menu, FormGroup, FormControlLabel, Checkbox, } from "@mui/material";
import { RoundActionIconButton } from "../../components/Buttons/ActionIconButton/RoundActionIconButton";
function HideColumnsCheckbox({ table, ignoredColumns, }) {
    const allColumns = table.getAllLeafColumns();
    const IndeterminateCheckbox = forwardRef((_a, ref) => {
        var { indeterminate } = _a, rest = __rest(_a, ["indeterminate"]);
        const defaultRef = useRef();
        const resolvedRef = ref || defaultRef;
        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);
        return _jsx(Checkbox, Object.assign({ ref: resolvedRef }, rest, { size: "small" }));
    });
    // If some row is ignored, make sure it's not shown on toggle
    const toggleHideAllColumnsProps = {
        checked: table.getIsAllColumnsVisible(),
        onChange: table.getToggleAllColumnsVisibilityHandler(),
        type: "checkbox",
    };
    if (ignoredColumns && ignoredColumns.length > 0) {
        const notIgnoredColumns = allColumns.filter((col) => !ignoredColumns.includes(col["header"]));
        toggleHideAllColumnsProps["checked"] = notIgnoredColumns.every((col) => col.isVisible);
        toggleHideAllColumnsProps["onChange"] = () => {
            const setToVisible = notIgnoredColumns.some((col) => !col.isVisible);
            notIgnoredColumns.forEach((c) => {
                if (c.isVisible !== setToVisible)
                    c.toggleVisibility();
            });
        };
    }
    return (_jsx(Box, Object.assign({ sx: { px: 2 } }, { children: _jsxs(FormGroup, { children: [_jsx(FormControlLabel, { control: _jsx(IndeterminateCheckbox, Object.assign({}, toggleHideAllColumnsProps)), label: "Toggle All" }, "ToggleAll"), allColumns.map((column) => {
                    const isIgnored = ignoredColumns.includes(column["id"]);
                    if (isIgnored) {
                        column.toggleVisibility = () => null;
                    }
                    return isIgnored ? null : (_jsx(FormControlLabel, { control: _jsx(Checkbox, { size: "small", checked: column.getIsVisible(), onChange: column.getToggleVisibilityHandler() }), label: column.id }, column.id));
                }), _jsx("br", {})] }) })));
}
export function HideColumnsMenu({ table, ignoredColumns, }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (_jsxs("div", { children: [_jsx(RoundActionIconButton, Object.assign({ onClick: handleClick, size: "small", active: open, tooltipMsg: "Filter columns" }, { children: _jsx(FilterAltIcon, { fontSize: "small" }) })), _jsx(Menu, Object.assign({ id: "basic-menu", anchorEl: anchorEl, open: open, onClose: handleClose, MenuListProps: {
                    "aria-labelledby": "basic-button",
                } }, { children: _jsx(HideColumnsCheckbox, { table: table, ignoredColumns: ignoredColumns }) }))] }));
}
