import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Button } from "@mui/material";
import ListAltIcon from "@mui/icons-material/ListAlt";
export function AppExamplesButton({ showExamples, toggleShowExamples, }) {
    return (_jsx(Button, Object.assign({ sx: {
            backgroundColor: showExamples ? "rgba(11, 83, 148, 0.3)" : undefined,
        }, onClick: () => toggleShowExamples(), size: "small", variant: "text", endIcon: _jsx(ListAltIcon, {}) }, { children: "Examples" })));
}
