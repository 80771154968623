import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useLocation } from "wouter";
import { Button } from "@mui/material";
import { AppListStore } from "../../apps/ApplicationsMenu/appList.store";
export function UseResultsInKdeep({ currentFolderContent, disabled, }) {
    const modelFile = currentFolderContent.find((e) => e["name"].endsWith(".ckpt"));
    const [, setLocation] = useLocation();
    const openInKdeep = (modelID) => {
        if (modelID) {
            setLocation(`kdeep`);
            AppListStore.getState().setPreselectedVal("kdeep", {
                modelfile: modelID,
            });
        }
    };
    return modelFile ? (_jsx(Button, Object.assign({ disabled: disabled, variant: "contained", sx: { m: 1 }, onClick: () => {
            openInKdeep(modelFile.id);
        } }, { children: "Use in Kdeep" }))) : (_jsx(_Fragment, {}));
}
