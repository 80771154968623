import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * @author Timur Kuzhagaliyev <tim.kuzh@gmail.com>
 * @author Panagiotis Tourlas <p.tourlas@acellera.com>
 * @copyright 2022
 * @license MIT
 */
import { useCallback, useEffect, useMemo, useRef, useState, memo, } from "react";
import { useSelector } from "react-redux";
import { VariableSizeGrid } from "react-window";
// import { ChonkyActions } from "../../action-definitions";
import { selectFileViewConfig, selectors } from "../../redux/selectors";
// import { RootState } from "../../types/redux.types";
import { useInstanceVariable } from "../../util/hooks-helpers";
import { makeGlobalChonkyStyles, useIsMobileBreakpoint, } from "../../util/styles";
import { SmartFileEntry } from "./FileEntry";
export const isMobileDevice = () => {
    // noinspection JSDeprecatedSymbols
    return (typeof window.orientation !== "undefined" ||
        navigator.userAgent.indexOf("IEMobile") !== -1);
};
export const getGridConfig = (width, fileCount, viewConfig, isMobileBreakpoint) => {
    const gutter = isMobileBreakpoint ? 5 : 8;
    const scrollbar = isMobileDevice() ? 0 : 18;
    let columnCount;
    let columnWidth;
    if (isMobileBreakpoint) {
        columnCount = 2;
        columnWidth = (width - gutter - scrollbar) / columnCount;
    }
    else {
        columnWidth = viewConfig.entryWidth;
        columnCount = Math.max(1, Math.floor((width - scrollbar) / (columnWidth + gutter)));
    }
    const rowCount = Math.ceil(fileCount / columnCount);
    return {
        rowCount,
        columnCount,
        gutter,
        rowHeight: viewConfig.entryHeight,
        columnWidth,
    };
};
export const GridContainer = memo((props) => {
    const { width, height } = props;
    const viewConfig = useSelector(selectFileViewConfig);
    const displayFileIds = useSelector(selectors.getDisplayFileIds);
    const fileCount = useMemo(() => displayFileIds.length, [displayFileIds]);
    const gridRef = useRef();
    const isMobileBreakpoint = useIsMobileBreakpoint();
    // Whenever the grid config changes at runtime, we call a method on the
    // `VariableSizeGrid` handle to reset column width/row height cache.
    // !!! Note that we deliberately update the `gridRef` firsts and update the React
    //     state AFTER that. This is needed to avoid file entries jumping up/down.
    const [gridConfig, setGridConfig] = useState(getGridConfig(width, fileCount, viewConfig, isMobileBreakpoint));
    const gridConfigRef = useRef(gridConfig);
    useEffect(() => {
        const oldConf = gridConfigRef.current;
        const newConf = getGridConfig(width, fileCount, viewConfig, isMobileBreakpoint);
        gridConfigRef.current = newConf;
        if (gridRef.current) {
            if (oldConf.rowCount !== newConf.rowCount) {
                gridRef.current.resetAfterRowIndex(Math.min(oldConf.rowCount, newConf.rowCount) - 1);
            }
            if (oldConf.columnCount !== newConf.columnCount) {
                gridRef.current.resetAfterColumnIndex(Math.min(oldConf.columnCount, newConf.rowCount) - 1);
            }
            if (oldConf.columnWidth !== newConf.columnWidth) {
                gridRef.current.resetAfterIndices({ columnIndex: 0, rowIndex: 0 });
            }
        }
        setGridConfig(newConf);
    }, [
        setGridConfig,
        gridConfigRef,
        isMobileBreakpoint,
        width,
        viewConfig,
        fileCount,
    ]);
    const sizers = useMemo(() => {
        const gc = gridConfigRef;
        return {
            getColumnWidth: (index) => gc.current.columnWidth +
                (index === gc.current.columnCount - 1 ? 0 : gc.current.gutter),
            getRowHeight: (index) => gc.current.rowHeight +
                (index === gc.current.rowCount - 1 ? 0 : gc.current.gutter),
        };
    }, [gridConfigRef]);
    const displayFileIdsRef = useInstanceVariable(useSelector(selectors.getDisplayFileIds));
    const getItemKey = useCallback((data) => {
        var _a;
        const index = data.rowIndex * gridConfigRef.current.columnCount + data.columnIndex;
        return (_a = displayFileIdsRef.current[index]) !== null && _a !== void 0 ? _a : `loading-file-${index}`;
    }, [gridConfigRef, displayFileIdsRef]);
    const cellRenderer = useCallback((data) => {
        const gc = gridConfigRef;
        const index = data.rowIndex * gc.current.columnCount + data.columnIndex;
        const fileId = displayFileIds[index];
        if (displayFileIds[index] === undefined)
            return null;
        const styleWithGutter = Object.assign(Object.assign({}, data.style), { paddingRight: data.columnIndex === gc.current.columnCount - 1
                ? 0
                : gc.current.gutter, paddingBottom: data.rowIndex === gc.current.rowCount - 1 ? 0 : gc.current.gutter, boxSizing: "border-box" });
        return (_jsx("div", Object.assign({ style: styleWithGutter }, { children: _jsx(SmartFileEntry, { fileId: fileId !== null && fileId !== void 0 ? fileId : null, displayIndex: index, fileViewMode: viewConfig.mode }) })));
    }, [displayFileIds, viewConfig.mode]);
    const classes = useStyles();
    const gridComponent = useMemo(() => {
        return (_jsx(VariableSizeGrid, Object.assign({ ref: gridRef, className: classes.gridContainer, estimatedRowHeight: gridConfig.rowHeight + gridConfig.gutter, rowHeight: sizers.getRowHeight, estimatedColumnWidth: gridConfig.columnWidth + gridConfig.gutter, columnWidth: sizers.getColumnWidth, columnCount: gridConfig.columnCount, height: height, rowCount: gridConfig.rowCount, width: width, itemKey: getItemKey }, { children: cellRenderer })));
    }, [
        classes.gridContainer,
        gridConfig.rowHeight,
        gridConfig.gutter,
        gridConfig.columnWidth,
        gridConfig.columnCount,
        gridConfig.rowCount,
        sizers.getRowHeight,
        sizers.getColumnWidth,
        height,
        width,
        getItemKey,
        cellRenderer,
    ]);
    return gridComponent;
});
const useStyles = makeGlobalChonkyStyles(() => ({
    gridContainer: {},
}));
