var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, Grid, } from "@mui/material";
import { useEffect, useState } from "react";
import { UploadLigandModeSelector } from "./UploadLigadModeSelector";
import { KetcherInput } from "./UploadLigandInputs/KetcherInput";
import { SmilesInput } from "./UploadLigandInputs/SmilesInput";
import { FileInput } from "./UploadLigandInputs/FileInput";
import { sdfFileFromSMILES } from "./utils";
export function UploadLigandDialog({ pyodide }) {
    const [upl_method, set_upl_method] = useState("file");
    const [open, set_open] = useState(false);
    const [resolve, setResolve] = useState(() => { });
    const [data_for_upload, set_data_for_upload] = useState("");
    const [error, setError] = useState("");
    useEffect(() => {
        if (error)
            setError("");
    }, [data_for_upload]);
    const [wait, setWait] = useState(false);
    const handleSelectUploadMethod = (method) => {
        set_upl_method(method);
        set_data_for_upload("");
    };
    const closeAndReturnUndefined = () => {
        resolve(undefined);
        set_open(false);
        set_data_for_upload("");
    };
    const closeAndReturnData = () => __awaiter(this, void 0, void 0, function* () {
        let data;
        if (upl_method !== "file") {
            setWait(true);
            const sdfPath = yield sdfFileFromSMILES(data_for_upload, pyodide, setError);
            if (sdfPath) {
                data = sdfPath;
            }
            setWait(false);
        }
        else {
            data = data_for_upload;
        }
        if (data) {
            resolve(data);
            set_open(false);
            set_data_for_upload("");
        }
    });
    useEffect(() => {
        const _cb = (e) => {
            const { resolve } = e.detail;
            setResolve(() => resolve);
            set_open(true);
        };
        window.addEventListener("PM-UploadLigand", _cb);
        return () => {
            window.removeEventListener("PM-UploadLigand", _cb);
        };
    }, []);
    let uploadComponent;
    switch (upl_method) {
        case "file":
            uploadComponent = (_jsx(FileInput, { file: data_for_upload, pyodide: pyodide, setFile: set_data_for_upload, label: "File" }));
            break;
        case "SMILES":
            uploadComponent = (_jsx(SmilesInput, { smiles: data_for_upload, setSmiles: set_data_for_upload }));
            break;
        case "ketcher":
            uploadComponent = (_jsx(KetcherInput, { molecule: data_for_upload, setMolecule: set_data_for_upload }));
            break;
        default:
            console.error("Wrong ligand upload method");
            uploadComponent = _jsx(_Fragment, {});
            break;
    }
    return (_jsxs(Dialog, Object.assign({ open: open, onClose: (event, reason) => {
            if (reason === "backdropClick")
                set_open(false);
        }, fullWidth: true }, { children: [_jsx(DialogTitle, { children: "Molecule uploader" }), _jsx(DialogContent, { children: _jsxs(Grid, Object.assign({ container: true, direction: "column", spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(UploadLigandModeSelector, { uplMethod: upl_method, handleSelect: handleSelectUploadMethod }) })), _jsx(Grid, Object.assign({ item: true, xs: true }, { children: uploadComponent })), " ", !!error && (_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(DialogContentText, Object.assign({ component: FormHelperText, error: true }, { children: error })) })))] })) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ variant: "outlined", onClick: closeAndReturnUndefined }, { children: "cancel" })), _jsx(Button, Object.assign({ variant: "contained", disabled: !!error || wait || !data_for_upload, onClick: closeAndReturnData }, { children: wait ? _jsx(CircularProgress, { size: 21 }) : "confirm" }))] })] })));
}
export function dispatchUploadLigandEvent() {
    return new Promise((resolve, reject) => {
        window.dispatchEvent(new CustomEvent("PM-UploadLigand", {
            detail: { resolve },
        }));
    });
}
