// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
export function dispatchNotificationEvent({ message, type }) {
    window.dispatchEvent(new CustomEvent("PM-Notification", { detail: { message, type } }));
}
export function dispatchControlledNotification({ idx, message, type, fileName, }) {
    window.dispatchEvent(new CustomEvent("PM-ControlledNotification", {
        detail: { idx, message, type, fileName },
    }));
}
export function dispatchDeleteControlledNotification({ idx }) {
    window.dispatchEvent(new CustomEvent("PM-DeleteControlledNotification", {
        detail: { idx },
    }));
}
