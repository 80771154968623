// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import filesize from "filesize";
import { createContext, useContext, useMemo } from "react";
import { useIntl } from "react-intl";
import { FileHelper } from "./file-helper";
export var I18nNamespace;
(function (I18nNamespace) {
    I18nNamespace["Toolbar"] = "toolbar";
    I18nNamespace["FileList"] = "fileList";
    I18nNamespace["FileEntry"] = "fileEntry";
    I18nNamespace["FileContextMenu"] = "contextMenu";
    I18nNamespace["FileActions"] = "actions";
    I18nNamespace["FileActionGroups"] = "actionGroups";
})(I18nNamespace || (I18nNamespace = {}));
export const getI18nId = (namespace, stringId) => `chonky.${namespace}.${stringId}`;
export const getActionI18nId = (actionId, stringId) => `chonky.${I18nNamespace.FileActions}.${actionId}.${stringId}`;
export const useLocalizedFileActionGroup = (groupName) => {
    const intl = useIntl();
    return useMemo(() => {
        return intl.formatMessage({
            id: getI18nId(I18nNamespace.FileActionGroups, groupName),
            defaultMessage: groupName,
        });
    }, [groupName, intl]);
};
export const useLocalizedFileActionStrings = (action) => {
    const intl = useIntl();
    return useMemo(() => {
        var _a, _b, _c;
        if (!action) {
            return {
                buttonName: "",
                buttonTooltip: undefined,
            };
        }
        const buttonName = intl.formatMessage({
            id: getActionI18nId(action.id, "button.name"),
            defaultMessage: (_a = action.button) === null || _a === void 0 ? void 0 : _a.name,
        });
        let buttonTooltip = undefined;
        if ((_b = action.button) === null || _b === void 0 ? void 0 : _b.tooltip) {
            // We only translate the tooltip if the original action has a tooltip.
            buttonTooltip = intl.formatMessage({
                id: getActionI18nId(action.id, "button.tooltip"),
                defaultMessage: (_c = action.button) === null || _c === void 0 ? void 0 : _c.tooltip,
            });
        }
        return {
            buttonName,
            buttonTooltip,
        };
    }, [action, intl]);
};
export const useLocalizedFileEntryStrings = (file) => {
    const intl = useIntl();
    const formatters = useContext(ChonkyFormattersContext);
    return useMemo(() => {
        return {
            fileModDateString: formatters.formatFileModDate(intl, file),
            fileSizeString: formatters.formatFileSize(intl, file),
        };
    }, [file, formatters, intl]);
};
export const defaultFormatters = {
    formatFileModDate: (intl, file) => {
        const safeModDate = FileHelper.getModDate(file);
        if (safeModDate) {
            return intl.formatDate(safeModDate, {
                dateStyle: "medium",
                timeStyle: "short",
            });
        }
        else {
            return null;
        }
    },
    formatFileSize: (_intl, file) => {
        if (!file || typeof file.size !== "number")
            return null;
        const size = file.size;
        const sizeData = filesize(size, { bits: false, output: "object" });
        if (sizeData.symbol === "B") {
            return `${Math.round(sizeData.value / 10) / 100.0} KB`;
        }
        else if (sizeData.symbol === "KB") {
            return `${Math.round(sizeData.value)} ${sizeData.symbol}`;
        }
        return `${sizeData.value} ${sizeData.symbol}`;
    },
};
export const ChonkyFormattersContext = createContext(defaultFormatters);
