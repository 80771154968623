import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import SendIcon from "@mui/icons-material/Send";
export function SubmitButton({ submissionStatus, }) {
    const isSubmitting = !!submissionStatus;
    return (_jsx(Button, Object.assign({ sx: { width: "100%" }, disabled: isSubmitting, variant: "contained", type: "submit", endIcon: isSubmitting ? _jsx(CircularProgress, { size: 20 }) : _jsx(SendIcon, {}) }, { children: isSubmitting ? submissionStatus : "submit" })));
}
