var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Grid, Tooltip, IconButton } from "@mui/material";
import { DCBrowserStore, dispatchConfirmationDialogEvent, userAuthStore, } from "../..";
import DeleteIcon from "@mui/icons-material/Delete";
import { jobListStore } from "../../Sidebar";
export function DeleteJob({ job }) {
    const { jobList, setJobList } = jobListStore();
    const handleClick = () => __awaiter(this, void 0, void 0, function* () {
        const ok = yield dispatchConfirmationDialogEvent({
            message: "Are you sure you want to delete this job?",
        });
        if (!ok)
            return;
        const jobID = job.traceId;
        const userInfo = userAuthStore.getState().userInfo;
        if (!userInfo)
            return;
        yield DCBrowserStore.getState().removeJobResults([jobID], userInfo.userId, true);
        setJobList(jobList.filter((prevJob) => prevJob.traceId !== job.traceId));
    });
    return (_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", title: "Delete job", sx: {} }, { children: _jsx(IconButton, Object.assign({ onClick: handleClick }, { children: _jsx(DeleteIcon, { fontSize: "small", color: "action" }) })) })) })));
}
