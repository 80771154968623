import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Grid } from "@mui/material";
const BottomSection = ({ children }) => (_jsx(Grid, Object.assign({ item: true, xs: 12, style: bottomGridSetup }, { children: children })));
const bottomGridSetup = {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "flex-end",
};
export default BottomSection;
