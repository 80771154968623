// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useCallback, useContext } from "react";
import { DndContext, useDrag, useDrop } from "react-dnd";
export const useDndContextAvailable = () => {
    const dndContext = useContext(DndContext);
    const { dragDropManager } = dndContext;
    return !!dragDropManager;
};
export const useDragIfAvailable = (...args) => {
    // This is an ugly hack to make `useDrag` not throw if a `DndContext` is not available.
    // See: https://github.com/react-dnd/react-dnd/issues/2212
    const dndContextAvailable = useDndContextAvailable();
    const mockHook = useCallback(() => [{}, () => null, () => null], []);
    // @ts-ignore
    const useHook = dndContextAvailable ? useDrag : mockHook;
    return useHook(...args);
};
export const useDropIfAvailable = (...args) => {
    const dndContextAvailable = useDndContextAvailable();
    const mockHook = useCallback(() => [{}, () => null], []);
    // @ts-ignore
    const useHook = dndContextAvailable ? useDrop : mockHook;
    return useHook(...args);
};
