import { create } from "zustand";
import { getButton, getButtons, getModifiers, } from "molstar/lib/mol-util/input/input-observer";
import { Representation } from "molstar/lib/mol-repr/representation";
import produce from "immer";
export var MeasurementType;
(function (MeasurementType) {
    // label="label"
    MeasurementType["distance"] = "distance";
    MeasurementType["angle"] = "angle";
    MeasurementType["dihedral"] = "dihedral";
    // orientation = "orientation",
    // plane = "plane",
})(MeasurementType || (MeasurementType = {}));
export const SelectionStore = create()((set, get) => ({
    selectedResidues: {},
    viewerSelectionMode: false,
    measureMode: false,
    selectionUnit: "element",
    clickedSequence: undefined,
    activeMeasurement: undefined,
    measurementLoci: {
        distance: [],
        angle: [],
        dihedral: [],
    },
    setSelectedResidues: (x) => set({ selectedResidues: x }),
    toggleViewerSelectionMode: (molstar) => {
        const setTo = !get().viewerSelectionMode;
        if (molstar) {
            molstar.selectionMode = setTo;
        }
        set({ viewerSelectionMode: setTo });
    },
    toggleMeasureMode: () => set({ measureMode: !get().measureMode }),
    setMeasureMode: (x) => set({ measureMode: x }),
    setSelectionUnit: (selectionUnit, molstar) => {
        if (molstar) {
            molstar.managers.interactivity.setProps({
                //@ts-ignore
                granularity: selectionUnit,
            });
        }
        set({ selectionUnit: selectionUnit });
    },
    resetSelectionState: (molstar) => {
        const selectionUnit = "element";
        if (molstar) {
            molstar.managers.interactivity.setProps({
                //@ts-ignore
                granularity: selectionUnit,
            });
        }
        set({ selectedResidues: {}, selectionUnit: selectionUnit });
    },
    clearSelection: (e, molstar) => {
        if (!molstar)
            return;
        const buttons = getButtons(e.nativeEvent);
        const button = getButton(e.nativeEvent);
        const modifiers = getModifiers(e.nativeEvent);
        molstar.behaviors.interaction.click.next({
            current: Representation.Loci.Empty,
            buttons,
            button,
            modifiers,
        });
    },
    setClickedSequence: (x) => set({ clickedSequence: x }),
    setActiveMeasurement: (x) => set({ activeMeasurement: x }),
    setMeasurementLoci: (measurementType, loci) => {
        set(produce((state) => {
            state.measurementLoci[measurementType] = loci;
        }));
    },
    deleteMeasurementLoci: (measurementType, index) => {
        set(produce((state) => {
            state.measurementLoci[measurementType] = state.measurementLoci[measurementType].filter((e, i) => i !== index);
        }));
    },
}));
