// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
export function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
export function keyToLabel(key) {
    return capitalizeFirstLetter(key).replaceAll("_", " ");
}
export function extractFieldsThatDisableOthers(manifestParams) {
    const fieldsTriggerDisable = manifestParams
        .filter((param) => { var _a, _b; return ((_a = param.gui_options) === null || _a === void 0 ? void 0 : _a.disables) && ((_b = param.gui_options) === null || _b === void 0 ? void 0 : _b.disables.length) > 0; })
        .map((param) => {
        var _a;
        return { key: param.name, disables: (_a = param.gui_options) === null || _a === void 0 ? void 0 : _a.disables };
    });
    const disabledToTrigger = {};
    fieldsTriggerDisable.forEach((fieldTrigger) => {
        var _a;
        (_a = fieldTrigger.disables) === null || _a === void 0 ? void 0 : _a.forEach((fieldDisabled) => {
            if (!(fieldDisabled in disabledToTrigger)) {
                disabledToTrigger[fieldDisabled] = [];
            }
            disabledToTrigger[fieldDisabled].push(fieldTrigger.key);
        });
    });
    return {
        fieldsDisableTrigger: fieldsTriggerDisable.map((f) => f.key),
        disabledToTrigger: disabledToTrigger,
    };
}
export function updateDisabledFields(disabledFields, watchedFieldHasVal, disabledToTrigger) {
    // For each potentially disabled field, check if they should be disabled (some of their trigger fiels have a value) or not
    Object.entries(disabledToTrigger).forEach(([disabledField, triggerFields]) => {
        let fieldShouldDisable = false;
        triggerFields.forEach((triggerField) => {
            // If any of the fields that trigger this to disable has a value, disable it
            const fieldhasVal = watchedFieldHasVal[triggerField];
            if (fieldhasVal)
                fieldShouldDisable = true;
        });
        const fieldDisableIdx = disabledFields.indexOf(disabledField);
        const fieldInDisabledList = fieldDisableIdx > -1;
        if (fieldShouldDisable && !fieldInDisabledList) {
            disabledFields.push(disabledField);
        }
        else if (!fieldShouldDisable && fieldInDisabledList) {
            disabledFields.splice(fieldDisableIdx, 1);
        }
    });
}
