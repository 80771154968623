import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import produce from "immer";
import { useMemo, useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Grid, Checkbox, FormControl, InputLabel, Select, OutlinedInput, MenuItem, ListItemText, Typography, FormLabel, RadioGroup, FormControlLabel, Radio, TextField, Box, Chip, } from "@mui/material";
import { ControlledAccordion } from "../../3dViewer/Controls/ControlledAccordion";
import { statusToColor, statusLabels, jobListStore, } from "../../Sidebar/JobStatusSharedState";
import { AppListStore } from "../ApplicationsMenu/appList.store";
// import { JobLimit } from "PyodideTerminal";
function SearchBar({ setFilterStr, setFilterBy, }) {
    return (_jsxs(_Fragment, { children: [_jsx(FilterInputNumber, { defaultVal: "name", label: "Search jobs by:", values: { name: "Name", traceId: "Job ID" }, callback: (x) => {
                    setFilterBy(x);
                } }), _jsx(TextField, { sx: { px: 1, pb: 1 }, size: "small", 
                // autoFocus
                placeholder: "Type to search...", fullWidth: true, onChange: (e) => setFilterStr(e.target.value), onKeyDown: (e) => {
                    e.stopPropagation();
                } })] }));
}
function FilterMultipleSelect({ value, selectOptions, label, callback, }) {
    const _value = value ? value.map((e) => e.toString()) : [];
    const [selectedItems, setSelectedItems] = useState(_value);
    const handleChange = (event) => {
        const { target: { value }, } = event;
        const newVal = typeof value === "string" ? value.split(",") : value;
        setSelectedItems(newVal);
        callback(newVal);
    };
    return (_jsxs(FormControl, Object.assign({ sx: { width: "100%" } }, { children: [_jsx(InputLabel, Object.assign({ size: "small" }, { children: label })), _jsx(Select, Object.assign({ multiple: true, size: "small", value: selectedItems, onChange: handleChange, input: _jsx(OutlinedInput, { label: label }), renderValue: (selectedK) => selectedK.map((selKey) => selectOptions[selKey]).join(", "), MenuProps: {
                    PaperProps: {
                        style: {
                            maxHeight: 200,
                        },
                    },
                } }, { children: Object.entries(selectOptions).map(([optID, optVal]) => {
                    const color = statusToColor[optVal];
                    return (_jsxs(MenuItem, Object.assign({ value: optID }, { children: [_jsx(Checkbox, { size: "small", checked: selectedItems.includes(optID) }), _jsx(Box, Object.assign({ sx: { pr: 1, color: color ? color : "#fb8500" } }, { children: _jsx(CircleIcon, { sx: { fontSize: "1em" } }) })), _jsx(ListItemText, { primary: optVal })] }), `jobs-filt-${optID}`));
                }) }))] })));
}
function FilterSelect({ selectOptions, label, callback, value, }) {
    const [selectedItem, setSelectedItem] = useState(value);
    const handleChange = (event) => {
        const newVal = event.target.value;
        setSelectedItem(newVal);
        callback(newVal === "All" ? "" : newVal);
    };
    return (_jsxs(FormControl, Object.assign({ sx: { width: "100%" } }, { children: [_jsx(InputLabel, Object.assign({ size: "small" }, { children: label })), _jsx(Select, Object.assign({ size: "small", value: selectedItem, label: label, onChange: handleChange }, { children: Object.entries(selectOptions).map(([optID, optVal]) => (_jsx(MenuItem, Object.assign({ value: optID }, { children: optVal }), `jobs-filt-${optID}`))) }))] })));
}
function FilterInputNumber({ defaultVal, label, values, callback, value, }) {
    const handleChange = (event) => {
        const newvalue = event.target.value;
        callback(newvalue);
    };
    return (_jsxs(FormControl, { children: [_jsx(FormLabel, { children: label }), _jsx(RadioGroup, Object.assign({ row: true, onChange: handleChange, defaultValue: defaultVal, value: value }, { children: Object.entries(values).map(([valID, val]) => (_jsx(FormControlLabel, { value: valID, control: _jsx(Radio, { size: "small" }), label: val.toString() }, `Filter-input-Number-${valID}`))) }))] }));
}
export function FilterJobs({ filterStr, setFilterStr, setFilterBy, }) {
    const [filtersOpen, setFiltersOpen] = useState(false);
    const { appList } = AppListStore((s) => ({ appList: s.appList }));
    const [filters, modifiedJobFilters, setFilters] = jobListStore((s) => [
        s.jobFilters,
        s.modifiedJobFilters,
        s.setJobFilters,
    ]);
    const toolOptions = useMemo(() => {
        return appList.reduce((o, app) => (Object.assign(Object.assign({}, o), { [`${app.name.toLowerCase()}-${app.version}`]: app.name })), {});
    }, [appList]);
    const onStatusChange = (statusIDs) => {
        const statusIDsNum = statusIDs.map((e) => Number(e));
        const newFilters = produce(filters, (draft) => {
            draft.in_status = statusIDsNum;
        });
        setFilters(newFilters);
    };
    const onNumOfJobsChange = (limit) => {
        const l = Number(limit);
        const newFilters = produce(filters, (draft) => {
            draft.limit = l;
        });
        setFilters(newFilters);
    };
    const onAppIdChange = (newApp) => {
        const _newApp = newApp === "All" ? undefined : newApp;
        const newFilters = produce(filters, (draft) => {
            draft.app_id = _newApp;
        });
        setFilters(newFilters);
    };
    const filtersAppliedCount = ((modifiedJobFilters.includes("in_status") + modifiedJobFilters.includes("app_id")) +
        !!filterStr);
    return (_jsx(ControlledAccordion, Object.assign({ open: filtersOpen, callback: () => setFiltersOpen((prev) => !prev), hoverBackground: true, name: _jsxs(Grid, Object.assign({ container: true, direction: "row", justifyContent: "center", spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(FilterAltIcon, { color: "primary" }) })), _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(Typography, Object.assign({ gutterBottom: true }, { children: "Filter Jobs" })) })), filtersAppliedCount > 0 && (_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(Chip, { size: "small", label: filtersAppliedCount, sx: { mr: 1 }, color: "secondary" }) })))] })) }, { children: _jsxs(Grid, Object.assign({ container: true, direction: "column", wrap: "nowrap", spacing: 2, sx: { p: 2 } }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true, zeroMinWidth: true }, { children: _jsx(FilterMultipleSelect, { label: "Status", value: filters.in_status, selectOptions: statusLabels, callback: onStatusChange }) })), _jsx(Grid, Object.assign({ item: true, xs: true, zeroMinWidth: true }, { children: _jsx(FilterSelect, { label: "Tool", selectOptions: Object.assign({ All: "All" }, toolOptions), callback: onAppIdChange, value: filters.app_id ? filters.app_id : "All" }) })), _jsx(Grid, Object.assign({ item: true, xs: true, zeroMinWidth: true }, { children: _jsx(FilterInputNumber, { defaultVal: 10, label: "No. of jobs shown:", values: { 10: "10", 50: "50", 100: "100" }, callback: onNumOfJobsChange, value: filters.limit }) })), _jsx(Grid, Object.assign({ item: true, xs: true, zeroMinWidth: true }, { children: _jsx(SearchBar, { setFilterStr: setFilterStr, setFilterBy: setFilterBy }) }))] })) })));
}
