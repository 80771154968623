import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Fade, IconButton } from "@mui/material";
import { leftPanel } from "../UIPanels/panelDimensions";
import CloseIcon from "@mui/icons-material/Close";
import { AppExamples } from "../Documentation/AppExamples";
export function AppExamplesPanel({ appName, appVersion, showExamples, closeExamples, setFormValues, }) {
    const boxStyle = {
        top: 0,
        position: "fixed",
        display: "block",
        left: (theme) => `calc(${theme.spacing(7)} + ${leftPanel.width})`,
        width: (theme) => `calc(100vw - ${theme.spacing(7)} - ${leftPanel.width})`,
        height: `calc(${leftPanel.height} - 1.75em)`,
        zIndex: 1,
        backgroundColor: "white",
        overflowY: "auto",
        overflowX: "auto",
        borderLeft: "1px solid #DCDCDC",
    };
    return (_jsx(Fade, Object.assign({ in: showExamples }, { children: _jsx(Box, Object.assign({ sx: boxStyle, id: "appDocs" }, { children: _jsxs(Box, Object.assign({ sx: { p: 4 } }, { children: [_jsx(Box, Object.assign({ display: "flex", justifyContent: "flex-end" }, { children: _jsx(IconButton, Object.assign({ color: "primary", onClick: closeExamples }, { children: _jsx(CloseIcon, {}) })) })), _jsx(AppExamples, { appName: appName, setFormValues: setFormValues, appVersion: appVersion })] })) })) })));
}
