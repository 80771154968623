import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { TabIcons } from "./DataVizTabIcons";
export function DataVizTabs({ tabs, activeTab, callback, }) {
    const [tabValue, setTabValue] = useState(activeTab);
    useEffect(() => {
        setTabValue(activeTab);
    }, [activeTab]);
    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
        callback(newValue);
    };
    return (_jsx(Tabs, Object.assign({ value: tabValue, onChange: handleChangeTab, variant: "scrollable", scrollButtons: "auto" }, { children: tabs.map((tab) => {
            return (_jsx(Tab, { label: tab.name, value: tab.name, component: "div", sx: { minHeight: 0 }, icon: _jsx(TabIcons, { isActive: tab.name === activeTab, color: tab.color, onDeleteAll: tab.onDeleteAll, tabName: tab.name }), iconPosition: "end" }, `dataviz-tab-${tab.name}`));
        }) })));
}
