// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useImperativeHandle } from "react";
import { useDispatch, useStore } from "react-redux";
import { reduxActions } from "../redux/reducers";
import { selectSelectionMap } from "../redux/selectors";
import { thunkRequestFileAction } from "../redux/thunks/dispatchers.thunks";
export const useFileBrowserHandle = (ref) => {
    const store = useStore();
    const dispatch = useDispatch();
    useImperativeHandle(ref, () => ({
        getFileSelection() {
            const selectionMap = selectSelectionMap(store.getState());
            const selectionSet = new Set(Object.keys(selectionMap));
            return selectionSet;
        },
        setFileSelection(selection, reset = true) {
            const fileIds = Array.from(selection);
            dispatch(reduxActions.selectFiles({ fileIds, reset }));
        },
        requestFileAction(action, payload) {
            return Promise.resolve(dispatch(thunkRequestFileAction(action, payload))).then();
        },
    }), [store, dispatch]);
};
