// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { keyToLabel } from "../FormGenerator/utils";
function getAvailableParams(testArguments, parameters) {
    var _a, _b, _c, _d;
    const filteredParams = {};
    for (let argName in testArguments) {
        const paramConfig = parameters[argName];
        if (!parameters)
            continue;
        if (((_a = paramConfig.gui_options) === null || _a === void 0 ? void 0 : _a.hide) || ((_b = paramConfig.gui_options) === null || _b === void 0 ? void 0 : _b.force_value))
            continue;
        const label = ((_c = paramConfig.gui_options) === null || _c === void 0 ? void 0 : _c.label)
            ? paramConfig.gui_options.label
            : keyToLabel(argName);
        filteredParams[label] = {
            value: testArguments[argName],
            key: argName,
            isFile: paramConfig.type.toLowerCase() === "path",
            nargs: paramConfig.nargs,
            group: (_d = paramConfig.gui_options) === null || _d === void 0 ? void 0 : _d.linked_group,
        };
    }
    return filteredParams;
}
export const obtainExamples = (appList, appName) => {
    const appManifest = appList.find((appMan) => appMan.name.toLowerCase() === appName.toLowerCase());
    if (!appManifest)
        return;
    const tests = appManifest.tests;
    if (!tests)
        return;
    const parameters = appManifest.params
        .filter((p) => p.name !== "outdir" && p.name !== "scratchdir")
        .reduce((acc, param) => {
        acc[param.name] = param;
        return acc;
    }, {});
    const appTests = [];
    for (let testName in tests) {
        const test = tests[testName];
        const filteredParams = getAvailableParams(test.arguments, parameters);
        if (Object.keys(filteredParams).length === 0)
            continue;
        appTests.push({ description: test.description, arguments: filteredParams });
    }
    return appTests;
};
