// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
export function throttle(func, limit) {
    //Throttling ensures that a function is only executed at most once within a specified period (limit, in ms), regardless of how many times it’s triggered.
    let inThrottle;
    let lastArgs;
    let lastThis;
    return function (...args) {
        if (!inThrottle) {
            // If not throttled, execute immediately
            func.apply(this, args);
            inThrottle = true;
            // Set up a timeout to reset the throttle and execute the last queued call
            setTimeout(() => {
                inThrottle = false;
                // If there were additional calls during the throttle period, execute the last one
                if (lastArgs) {
                    func.apply(lastThis, lastArgs);
                    lastArgs = null;
                    lastThis = null;
                }
            }, limit);
        }
        else {
            // If throttled, store the latest arguments and context
            lastArgs = args;
            lastThis = this;
        }
    };
}
