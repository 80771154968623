import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * @author Timur Kuzhagaliyev <tim.kuzh@gmail.com>
 * @author Panagiotis Tourlas <p.tourlas@acellera.com>
 * @copyright 2022
 * @license MIT
 */
import Menu from "@mui/material/Menu";
import { useCallback, useMemo, memo, useState } from "react";
import { useLocalizedFileActionGroup } from "../../util/i18n";
import { important, makeGlobalChonkyStyles } from "../../util/styles";
import { ToolbarButton } from "./ToolbarButton";
import { SmartToolbarDropdownButton } from "./ToolbarDropdownButton";
export const ToolbarDropdown = memo((props) => {
    const { name, fileActionIds } = props;
    const [anchor, setAnchor] = useState(null);
    const handleClick = useCallback((event) => setAnchor(event.currentTarget), [setAnchor]);
    const handleClose = useCallback(() => setAnchor(null), [setAnchor]);
    const menuItemComponents = useMemo(() => fileActionIds.map((id) => (_jsx(SmartToolbarDropdownButton, { fileActionId: id, onClickFollowUp: handleClose }, `menu-item-${id}`))), [fileActionIds, handleClose]);
    const localizedName = useLocalizedFileActionGroup(name);
    const classes = useStyles();
    return (_jsxs(_Fragment, { children: [_jsx(ToolbarButton, { text: localizedName, onClick: handleClick, dropdown: true }), _jsx(Menu, Object.assign({ autoFocus: true, keepMounted: true, elevation: 2, anchorEl: anchor, onClose: handleClose, open: Boolean(anchor), transitionDuration: 150, classes: { list: classes.dropdownList } }, { children: menuItemComponents }))] }));
});
const useStyles = makeGlobalChonkyStyles(() => ({
    dropdownList: {
        paddingBottom: important(0),
        paddingTop: important(0),
    },
}));
