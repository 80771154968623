// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
export function splitFilePath(filePath) {
    const lastSlashIndex = filePath.lastIndexOf("/");
    if (lastSlashIndex === -1) {
        return {
            directoryPath: "",
            fileName: filePath,
        };
    }
    const directoryPath = filePath.substring(0, lastSlashIndex);
    const fileName = filePath.substring(lastSlashIndex + 1);
    return {
        directoryPath,
        fileName,
    };
}
export function getFirstSentence(text) {
    // Returns the 1st sentence of a block of text.
    const match = text.match(/[^.!?]*[.!?]/);
    return match ? match[0].trim() : text.trim();
}
