import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * @author Timur Kuzhagaliyev <tim.kuzh@gmail.com>
 * @author Panagiotis Tourlas <p.tourlas@acellera.com>
 * @copyright 2022
 * @license MIT
 */
import { useContext, useMemo, memo } from "react";
import { ChonkyIconName } from "../../types/icons.types";
import { useDndHoverOpen, useFileDrop } from "../../util/dnd";
import { ChonkyIconContext } from "../../util/icon-helper";
import { c, important, makeLocalChonkyStyles } from "../../util/styles";
import { useDndIcon } from "../file-list/FileEntry-hooks";
import { ToolbarButton } from "./ToolbarButton";
export const FolderChainButton = memo(({ first, current, item }) => {
    const { file, disabled, onClick } = item;
    const { dndIsOver, dndCanDrop, drop } = useFileDrop({
        file,
        forceDisableDrop: !file || current,
    });
    const dndState = useMemo(() => ({
        dndIsOver,
        dndCanDrop,
        dndIsDragging: false,
    }), [dndCanDrop, dndIsOver]);
    useDndHoverOpen(file, dndState);
    const dndIconName = useDndIcon(dndState);
    const ChonkyIcon = useContext(ChonkyIconContext);
    const classes = useStyles(dndState);
    const className = c({
        [classes.baseBreadcrumb]: true,
        [classes.disabledBreadcrumb]: disabled,
        [classes.currentBreadcrumb]: current,
    });
    const text = file ? file.name : "Loading...";
    const icon = first && (file === null || file === void 0 ? void 0 : file.folderChainIcon) === undefined
        ? ChonkyIconName.folder
        : file === null || file === void 0 ? void 0 : file.folderChainIcon;
    return (_jsxs("div", Object.assign({ className: classes.buttonContainer, ref: file ? drop : null }, { children: [file && dndIconName && (_jsx("div", Object.assign({ className: classes.dndIndicator }, { children: _jsx(ChonkyIcon, { icon: dndIconName, fixedWidth: true }) }))), _jsx(ToolbarButton, { icon: icon, className: className, text: text, disabled: disabled, onClick: onClick })] })));
});
const useStyles = makeLocalChonkyStyles((theme) => ({
    buttonContainer: {
        position: "relative",
    },
    baseBreadcrumb: {
        color: (dndState) => {
            let color = theme.palette.text.primary;
            if (dndState.dndIsOver) {
                color = dndState.dndCanDrop
                    ? theme.dnd.canDropColor
                    : theme.dnd.cannotDropColor;
            }
            return important(color);
        },
    },
    disabledBreadcrumb: {
        // Constant function here is on purpose. Without the function, the color here
        // does not override the `baseBreadcrumb` color from above.
        color: () => important(theme.palette.text.disabled),
    },
    currentBreadcrumb: {
        textDecoration: important("underline"),
    },
    dndIndicator: {
        color: (dndState) => dndState.dndCanDrop ? theme.dnd.canDropColor : theme.dnd.cannotDropColor,
        backgroundColor: (dndState) => dndState.dndCanDrop ? theme.dnd.canDropMask : theme.dnd.cannotDropMask,
        lineHeight: `calc(${theme.toolbar.lineHeight} - 6px)`,
        transform: "translateX(-50%) translateY(-50%)",
        borderRadius: theme.toolbar.buttonRadius,
        height: theme.toolbar.size - 6,
        width: theme.toolbar.size - 6,
        boxSizing: "border-box",
        position: "absolute",
        textAlign: "center",
        left: "50%",
        top: "50%",
        zIndex: 5,
    },
}));
