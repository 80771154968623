import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState, useMemo } from "react";
import produce from "immer";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, InputAdornment, Grid, CircularProgress, } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
/*
    USAGE:
    <ProbeSelectionTable
        open={...}
        setOpen={...}
        onSelectRow={(r) => console.log(r.name)}
    />
*/
export function ProbeSelectionTable({ open, setOpen, onSelectRow, probeRows, notReady, }) {
    const [page, setPage] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const rows = useMemo(() => {
        /* Go back to 1st page when starting a search, because if user is on a
        page far away, nothing will be shown */
        if (searchText.length === 1)
            setPage(0);
        const matchingRows = produce(probeRows, (draft) => draft.filter((entry) => entry.name.toLowerCase().includes(searchText.toLowerCase())));
        return matchingRows;
    }, [searchText, probeRows]);
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (_jsx(_Fragment, { children: _jsxs(Dialog, Object.assign({ open: open, PaperProps: { sx: { minWidth: "30%", height: "80%" } } }, { children: [_jsxs(DialogTitle, { children: [_jsx(Box, Object.assign({ display: "flex", justifyContent: "flex-end" }, { children: _jsx(IconButton, Object.assign({ color: "primary", onClick: () => setOpen(false) }, { children: _jsx(CloseIcon, {}) })) })), _jsxs(Grid, Object.assign({ container: true, direction: "row", justifyContent: "space-between", alignItems: "center", spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: "Select a probe:" })), _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(SearchBar, { searchText: searchText, setSearchText: setSearchText }) }))] }))] }), _jsx(DialogContent, { children: notReady ? (_jsx(CircularProgress, {})) : (_jsx(Table, { children: _jsxs(TableBody, { children: [(rowsPerPage > 0
                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : rows).map((row) => (_jsxs(TableRow, Object.assign({ hover: true, style: { cursor: "pointer" }, onClick: () => onSelectRow(row) }, { children: [_jsx(TableCell, Object.assign({ component: "th", scope: "row" }, { children: row.name })), _jsx(TableCell, Object.assign({ style: { width: 160 }, align: "right" }, { children: _jsx("div", { dangerouslySetInnerHTML: { __html: row.image } }) }))] }), row.name))), emptyRows > 0 && (_jsx(TableRow, Object.assign({ style: { height: 53 * emptyRows } }, { children: _jsx(TableCell, { colSpan: 2 }) })))] }) })) }), _jsx(DialogActions, { children: _jsx(TablePagination, { rowsPerPageOptions: [5, 10, { label: "All", value: -1 }], colSpan: 1, count: rows.length, rowsPerPage: rowsPerPage, page: page, SelectProps: {
                            inputProps: {
                                "aria-label": "rows per page",
                            },
                            native: true,
                        }, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage, ActionsComponent: TablePaginationActions }) })] })) }));
}
function SearchBar({ searchText, setSearchText, }) {
    return (_jsx(TextField, { sx: { px: 1, pb: 1 }, size: "small", placeholder: "Type to search...", InputProps: {
            startAdornment: (_jsx(InputAdornment, Object.assign({ position: "start" }, { children: _jsx(SearchIcon, {}) }))),
        }, onChange: (e) => setSearchText(e.target.value), onKeyDown: (e) => {
            e.stopPropagation();
        } }));
}
function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };
    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };
    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };
    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (_jsxs(Box, Object.assign({ sx: { flexShrink: 0, ml: 2.5 } }, { children: [_jsx(IconButton, Object.assign({ onClick: handleFirstPageButtonClick, disabled: page === 0, "aria-label": "first page" }, { children: theme.direction === "rtl" ? _jsx(LastPageIcon, {}) : _jsx(FirstPageIcon, {}) })), _jsx(IconButton, Object.assign({ onClick: handleBackButtonClick, disabled: page === 0, "aria-label": "previous page" }, { children: theme.direction === "rtl" ? (_jsx(KeyboardArrowRight, {})) : (_jsx(KeyboardArrowLeft, {})) })), _jsx(IconButton, Object.assign({ onClick: handleNextButtonClick, disabled: page >= Math.ceil(count / rowsPerPage) - 1, "aria-label": "next page" }, { children: theme.direction === "rtl" ? (_jsx(KeyboardArrowLeft, {})) : (_jsx(KeyboardArrowRight, {})) })), _jsx(IconButton, Object.assign({ onClick: handleLastPageButtonClick, disabled: page >= Math.ceil(count / rowsPerPage) - 1, "aria-label": "last page" }, { children: theme.direction === "rtl" ? _jsx(FirstPageIcon, {}) : _jsx(LastPageIcon, {}) }))] })));
}
