import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect, useState } from "react";
import { Input } from "@mui/material";
import { extractValuesFromStr, inputStyle, numberIsValid } from "./utils";
export function ControlledInputNumberFocusOut({ currVal, minVal, maxVal, disabled, onUpdate, onlyIntegers, step, }) {
    const [internalValue, setInternalValue] = useState(currVal.toString());
    const [finalValue, setFinalValue] = useState(currVal.toString());
    useEffect(() => {
        if (internalValue !== currVal.toString())
            setInternalValue(currVal.toString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currVal]);
    const [inputErr, setInputErr] = useState(false);
    useEffect(() => {
        const re = onlyIntegers ? /^[-+]?[0-9]*$/ : /^[-+]?[0-9]+(\.)*[0-9]*$/;
        const valid = internalValue.match(re);
        const thingsWentWrong = !valid ||
            Number(internalValue) > maxVal ||
            Number(internalValue) < minVal;
        if (thingsWentWrong)
            setInputErr(true);
        else {
            setInputErr(false);
        }
    }, [internalValue, minVal, maxVal]);
    useEffect(() => {
        onUpdate(Number(finalValue));
    }, [finalValue]);
    const handleUpdate = () => {
        if (inputErr)
            return;
        setFinalValue(internalValue);
    };
    return (_jsx(Input
    // sx={{ mr: 1, ml: 1 }}
    , { 
        // sx={{ mr: 1, ml: 1 }}
        error: inputErr, value: internalValue, size: "small", onClick: handleUpdate, onChange: (e) => setInternalValue(e.target.value), disabled: disabled, onBlur: handleUpdate, onKeyDown: (e) => {
            if (e.key === "Enter") {
                handleUpdate();
            }
        }, inputProps: {
            step: step ? step : 1,
            min: minVal,
            max: maxVal,
            type: "number",
            "aria-labelledby": "input-slider",
            style: inputStyle(disabled),
        } }));
}
export function ControlledInputRangeFocusOut({ currValFrom, currValTo, currValStep, minVal, maxVal, disabled, onUpdate, }) {
    const generateRangeString = (valFrom, valTo, valStep) => {
        const valStepStr = valStep === undefined ? "" : `:${valStep}`;
        return `${valFrom}${valStepStr}:${valTo}`;
    };
    const [internalValue, setInternalValue] = useState(generateRangeString(currValFrom, currValTo, currValStep));
    const [finalValue, setFinalValue] = useState(generateRangeString(currValFrom, currValTo, currValStep));
    useEffect(() => {
        const rangeString = generateRangeString(currValFrom, currValTo, currValStep);
        if (internalValue !== rangeString)
            setInternalValue(rangeString);
    }, [currValFrom, currValTo, currValStep]);
    const [inputErr, setInputErr] = useState(false);
    useEffect(() => {
        const numbers = extractValuesFromStr(internalValue);
        let hasError = true;
        if (numbers) {
            const { from: currentFrom, to: currentTo, step: currentStep } = numbers;
            if (numberIsValid(currentFrom, minVal, maxVal) &&
                numberIsValid(currentTo, minVal, maxVal) &&
                (currentStep === undefined ||
                    numberIsValid(currentStep, minVal, maxVal))) {
                if (currentFrom <= currValTo) {
                    hasError = false;
                }
            }
        }
        setInputErr(hasError);
    }, [internalValue, minVal, maxVal]);
    useEffect(() => {
        const numbers = extractValuesFromStr(internalValue);
        if (numbers) {
            const { from: finalFrom, to: finalTo, step: finalStep } = numbers;
            onUpdate(finalFrom, finalTo, finalStep);
        }
    }, [finalValue]);
    const handleUpdate = () => {
        if (inputErr)
            return;
        setFinalValue(internalValue);
    };
    return (_jsx(Input
    // sx={{ mr: 1, ml: 1 }}
    , { 
        // sx={{ mr: 1, ml: 1 }}
        error: inputErr, value: internalValue, size: "small", onClick: handleUpdate, onChange: (e) => setInternalValue(e.target.value), disabled: disabled, onBlur: handleUpdate, onKeyDown: (e) => {
            if (e.key === "Enter") {
                handleUpdate();
            }
        }, inputProps: {
            // step: step ? step : 1,
            // min: 1,
            // max: maxVal,
            // type: "number",
            "aria-labelledby": "input-slider",
            style: inputStyle(disabled),
        } }));
}
