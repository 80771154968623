import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { Controller } from "react-hook-form";
import { HelpTooltip } from "../apps/HelpTooltip";
export function CheckboxPM({ hookFormRegisterFunc, formKey, disabled = false, label, helpText, marginTop = 2, }) {
    return (_jsxs(Grid, Object.assign({ container: true, spacing: 1, alignItems: "center" }, { children: [helpText ? (_jsxs(Grid, Object.assign({ item: true, xs: "auto" }, { children: [_jsx(HelpTooltip, { title: helpText }), " "] }))) : null, _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(FormControlLabel, { disabled: disabled, label: label, control: _jsx(Controller, { name: formKey, render: ({ field: props }) => (_jsx(Checkbox, Object.assign({}, props, { checked: props.value, onChange: (e) => props.onChange(e.target.checked), size: "small" }))) }) }) }))] })));
}
