import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState } from "react";
import { Tooltip, ListItem, ListItemIcon, Box, ListItemText, MenuItem, } from "@mui/material";
import { DiscordInvitationPanel } from "./DiscordInvitationPanel";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
//@ts-ignore
import discord_logo from "../static/images/DiscordLogoGrey.png";
export const DiscordLogo = () => {
    const [discordPanel, setDiscordPanel] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, Object.assign({ enterDelay: 200, title: "Join our community on Discord!", arrow: true, placement: "right" }, { children: _jsx(ListItem, Object.assign({ button: true, onClick: () => setDiscordPanel(true) }, { children: _jsx(ListItemIcon, { children: _jsx(Box, Object.assign({ sx: { color: "secondary.light" } }, { children: _jsx(LiveHelpIcon, {}) })) }) }), "pm-discord-logo") }), "pm-sidebar-discord"), _jsx(DiscordInvitationPanel, { open: discordPanel, setOpen: setDiscordPanel })] }));
};
export const DiscordListButton = ({ setDiscordOpen, }) => {
    return (_jsxs(MenuItem, Object.assign({ onClick: () => setDiscordOpen(true), dense: true }, { children: [_jsx(ListItemIcon, { children: _jsx("img", { src: discord_logo, style: {
                        width: "20px",
                        height: "20px",
                    }, alt: "discord" }) }), _jsx(ListItemText, { children: "Join our community on Discord" })] }), "help-discord"));
};
