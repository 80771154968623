var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { defaultValues, ResetPasswordSchemaSchema, SendResetMailSchema, sendResetMailDefaults, } from "./ResetPasswordSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DialogActions, DialogContentText, FormHelperText, CircularProgress, Grid, Box, Link, Fade, } from "@mui/material";
import { TextFieldPM } from "../../SharedFormParts/TextField";
import { executeWithTokenRefresh } from "../executeWithTokenRefresh";
import { DCBrowserStore } from "../../DatacenterBrowser";
import { shallow } from "zustand/shallow";
import { userAuthStore } from "../userAuthState";
import { dispatchConfirmationDialogEvent } from "../../utils";
import { MailCodeVerificationForm } from "../MailCodeVerification/MailCodeVerificationForm";
export function PasswordReset({ onLoginClick, onSuccess, authenticatedEmail, }) {
    useEffect(() => {
        if (authenticatedEmail) {
            setEmail(authenticatedEmail);
            setShowingVerificationCodeForm(true);
        }
        else {
            setShowingEmailForm(true);
        }
    }, [authenticatedEmail]);
    const [showingEmailForm, setShowingEmailForm] = useState(false);
    const [showingVerificationCodeForm, setShowingVerificationCodeForm] = useState(false);
    const [showNewPasswordForm, setShowNewPasswordForm] = useState(false);
    const [email, setEmail] = useState(undefined);
    const [verificationCode, setVerificationCode] = useState(undefined);
    const handleEmailFormSuccess = (email) => {
        setEmail(email);
        setTimeout(() => setShowingEmailForm(false), 500);
        setTimeout(() => setShowingVerificationCodeForm(true), 700);
    };
    const handleVerificationCodeSuccess = (verificationCode) => {
        setVerificationCode(verificationCode);
        setTimeout(() => setShowingVerificationCodeForm(false), 500);
        setTimeout(() => setShowNewPasswordForm(true), 700);
    };
    const handleSetNewPasswordSuccess = () => __awaiter(this, void 0, void 0, function* () {
        setShowNewPasswordForm(false);
        yield dispatchConfirmationDialogEvent({
            message: "Your password was successfully reset!",
            noCancelButton: true,
        });
        onSuccess();
    });
    return (_jsxs(_Fragment, { children: [_jsx(Fade, Object.assign({ in: showingEmailForm, unmountOnExit: true }, { children: _jsx("div", { children: _jsx(SendPasswordResetCode, { onSuccess: handleEmailFormSuccess, onLoginClick: onLoginClick }) }) })), _jsx(Fade, Object.assign({ in: showingVerificationCodeForm, unmountOnExit: true }, { children: _jsx("div", { children: _jsx(MailCodeVerificationForm, { onSuccess: handleVerificationCodeSuccess, email: email }) }) })), _jsx(Fade, Object.assign({ in: showNewPasswordForm, unmountOnExit: true }, { children: _jsx("div", { children: _jsx(SetNewPassword, { onSuccess: handleSetNewPasswordSuccess, email: email, code: verificationCode }) }) }))] }));
}
function SetNewPassword({ onSuccess, email, code, }) {
    var _a, _b;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const formMethods = useForm({
        resolver: yupResolver(ResetPasswordSchemaSchema),
        defaultValues: defaultValues,
    });
    const { handleSubmit, formState: { errors }, } = formMethods;
    const [project] = DCBrowserStore((state) => [state.project], shallow);
    const { login, onSuccessfulLogin } = userAuthStore((state) => ({
        login: state.login,
        onSuccessfulLogin: state.onSuccessfulLogin,
    }));
    const onSubmit = (data) => __awaiter(this, void 0, void 0, function* () {
        setIsSubmitting(true);
        setErrorMessage("");
        const apiCallF = () => fetch(`${window.location.origin}/v2/auth/reset-password`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                mail: email,
                password: data.newPassword,
                password_re: data.newPasswordConfirm,
                code: code,
            }),
        });
        const response = yield executeWithTokenRefresh(apiCallF, true);
        if (response.success) {
            if (email) {
                const userInfo = yield login(email, data.newPassword);
                if (userInfo) {
                    onSuccessfulLogin(project);
                }
            }
            setTimeout(() => setIsSubmitting(false), 200);
            onSuccess();
        }
        else {
            setIsSubmitting(false);
            const errorMsg = response.error
                ? response.error
                : "Something went wrong with this registration. Please try again or contact support if the issue continues.";
            setErrorMessage(errorMsg);
        }
    });
    return (_jsx(Box, Object.assign({ sx: { py: 1 } }, { children: _jsx("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: _jsx(FormProvider, Object.assign({}, formMethods, { children: _jsxs(Grid, Object.assign({ container: true, direction: "column", spacing: 2 }, { children: [_jsx(Grid, Object.assign({ xs: true, item: true }, { children: _jsx(TextFieldPM, { type: "password", formKey: "newPassword", label: "New Password", error: (_a = errors["newPassword"]) === null || _a === void 0 ? void 0 : _a.message }) })), _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(TextFieldPM, { type: "password", formKey: "newPasswordConfirm", label: "Confirm New Password", error: (_b = errors["newPasswordConfirm"]) === null || _b === void 0 ? void 0 : _b.message }) })), !!errorMessage && (_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(DialogContentText, Object.assign({ component: FormHelperText, textAlign: "center", error: true }, { children: errorMessage })) }))), _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(DialogActions, Object.assign({ sx: { justifyContent: "center" } }, { children: _jsx(Button, Object.assign({ type: "submit", variant: "contained", disabled: isSubmitting, endIcon: isSubmitting ? _jsx(CircularProgress, { size: 20 }) : undefined }, { children: "Reset password" })) })) }))] })) })) })) })));
}
function SendPasswordResetCode({ onLoginClick, onSuccess, }) {
    var _a;
    const formMethods = useForm({
        resolver: yupResolver(SendResetMailSchema),
        defaultValues: sendResetMailDefaults,
    });
    const { handleSubmit, formState: { errors }, } = formMethods;
    const [errorMessage, setErrorMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const onSubmit = (data) => __awaiter(this, void 0, void 0, function* () {
        const mail = data.mail;
        setIsSubmitting(true);
        setErrorMessage("");
        const response = yield executeWithTokenRefresh(() => fetch(`${window.location.origin}/v2/auth/get-password-reset-code`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                mail: mail,
            },
        }), true);
        if (response.success) {
            setTimeout(() => setIsSubmitting(false), 200);
            onSuccess(mail);
        }
        else {
            setIsSubmitting(false);
            const errorMsg = response.error
                ? response.error
                : "Something went wrong. Please try again or contact support if the issue continues.";
            setErrorMessage(errorMsg);
        }
    });
    return (_jsxs(Grid, Object.assign({ container: true, direction: "column", spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: _jsx(FormProvider, Object.assign({}, formMethods, { children: _jsxs(Grid, Object.assign({ container: true, direction: "column", spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(DialogContentText, Object.assign({ textAlign: "left" }, { children: "Enter your account's email address and we will send you a password reset link." })) })), _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(TextFieldPM, { size: "small", formKey: "mail", label: "Email", error: (_a = errors["mail"]) === null || _a === void 0 ? void 0 : _a.message }) })), !!errorMessage && (_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(DialogContentText, Object.assign({ component: FormHelperText, textAlign: "center", error: true }, { children: errorMessage })) }))), _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(DialogActions, Object.assign({ sx: { justifyContent: "center" } }, { children: _jsx(Button, Object.assign({ type: "submit", variant: "contained", disabled: isSubmitting, endIcon: isSubmitting ? _jsx(CircularProgress, { size: 20 }) : undefined }, { children: "Send password reset email" })) })) }))] })) })) })) })), onLoginClick ? (_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(DialogContentText, Object.assign({ variant: "body1", textAlign: "center" }, { children: _jsx(Link, Object.assign({ component: "button", underline: "hover", variant: "body1", onClick: onLoginClick }, { children: "Log in" })) }), "login-link-passwordreset") }))) : null] })));
}
