// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * What is this contraption?
 *
 * This tiny utility addresses the rather complex issue of having to abort a
 * fetch request from a useEffect React hook (easy).
 * But without memory leaks (not so easy).
 * Its usefulness should hopefully be far more generic though.
 *
 * How to use?
 *
 * There are 2 requirements: the `abort_fetch_event` and the `abort_signal`.
 * The event has to be dispatched manually through dispatchEvent() while
 * the signal has to be passed to any requests that should be aborted through
 * their configuration
 * (see https://developer.mozilla.org/en-US/docs/Web/API/fetch#parameters).
 */
const abort_fetch_event = new Event("abort_fetch");
let controller = new AbortController();
let abort_signal = controller.signal;
document.addEventListener("abort_fetch", send_abort_signal);
function send_abort_signal() {
    controller.abort();
    _restart_listener();
}
function _restart_listener() {
    controller = new AbortController();
    abort_signal = controller.signal;
}
/*
function remove_listener() {
    document.removeEventListener('abort_fetch', send_abort_signal)
}
*/
export { abort_fetch_event, abort_signal };
/*
 * Nice-to-have's:
 * 1. Make event listener attachments configurable.
 * 2. Calling remove_listener would make the event useless. Add a start()
 * function.
 */
