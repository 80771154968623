// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { createTheme } from "@mui/material/styles";
/**
 * A high contrast theme for testing component behavior.
 */
const hiContrTheme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
        },
        secondary: {
            main: "#ff0000",
            light: "#00ff00",
        },
    },
});
export default hiContrTheme;
