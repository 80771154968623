import { jsx as _jsx } from "react/jsx-runtime";
import { MenuItem, Select } from "@mui/material";
function SelectPlotSelector({ handleChange, activePlotIdx, plots, }) {
    const onChange = (event) => {
        handleChange(Number(event.target.value));
    };
    return (_jsx(Select, Object.assign({ labelId: "select-table-label", id: "select-table", value: activePlotIdx.toString(), label: "", onChange: onChange, size: "small" }, { children: plots.map((t, i) => (_jsx(MenuItem, Object.assign({ value: i, sx: { display: t.archived ? "none" : undefined } }, { children: t.name }), `opt${i}`))) })));
}
// function SelectPloTabs({
//   handleChange,
//   activePlotIdx,
//   plots,
// }: SelectPlotProps) {
//   const plotNames = plots.map((p) => {
//     return { name: p.name };
//   });
//   const callback = (val: string) => {
//     handleChange(plotNames.findIndex((p) => p.name === val));
//   };
//   return (
//     <DataVizTabs
//       tabs={plotNames}
//       activeTab={plots[activePlotIdx].name}
//       callback={callback}
//     />
//   );
// }
export function SelectPlot({ handleChange, activePlotIdx, plots, }) {
    // <SelectPloTabs
    //   handleChange={handleChange}
    //   activePlotIdx={activePlotIdx}
    //   plots={plots}
    // />
    return (_jsx(SelectPlotSelector, { handleChange: handleChange, activePlotIdx: activePlotIdx, plots: plots }));
}
