import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import "../static/css/LandingPage.css";
import { Box } from "@mui/material";
import { Raphael, Paper, Set, Circle, Text, Path } from "react-raphael";
export function AppsTimeline() {
    const colors = ["#4568B4", "#437FBC", "#3F90BC"];
    const apps = {
        0: [
            ["up", 30, -80, "MembraneBuilder", "/tools/MembraneBuilder"],
            ["up", 60, -50, "SystemBuilder", "/tools/SystemBuilder"],
            ["up", 90, -20, "AceProfiler", "/tools/aceprofiler"],
            ["down", 20, 80, "ProteinPrepare", "/tools/proteinPrepare"],
            ["down", 80, 50, "SimpleRun", "/tools/SimpleRun"],
            // ["down", 120, 20, "ISCA", "/tools/isca"],
            ["up", 260, -80, "DeepSite", "/tools/deepsite"],
            // ["down", 240, 80, "CrypticScout", "/tools/CrypticScout"],
            ["down", 270, 50, "PlexView", "/tools/PlexView"],
            // ["up", 380, -80, "AdaptiveSampling", "/tools/AdaptiveSampling"],
        ],
        1: [
            ["up", 430, -50, "AcePrep", "/tools/aceprep"],
            // ["up", 550, -80, "ISBA", "/isba"],
            ["down", 580, 80, "Generative", "/tools/generative"],
            // ["down", 580, 80, "BindScope", "/BindScope"],
            // ["down", 420, 80, "PathwayMap", "/PathwayMap"],
        ],
        2: [
            // ['up', 730, -50, 'AceDock', '/acedock'],
            ["down", 750, 80, "KDEEP", "/tools/Kdeep"],
            ["up", 850, -80, "Parameterize", "/tools/parameterize"],
            ["down", 950, 80, "AceDock", "/tools/AceDock"],
        ],
    };
    const arrowPoints = [
        [340, 0],
        [25, 25],
        [-25, 25],
        [-340, 0],
        [25, -25],
    ];
    let path1 = "M 0 150";
    let path2 = "M 350 150";
    let path3 = "M 700 150";
    arrowPoints.forEach(([x, y]) => {
        path1 += ` l ${x} ${y}`;
        path2 += ` l ${x} ${y}`;
        path3 += ` l ${x} ${y}`;
    });
    path1 += " z";
    path2 += " z";
    path3 += " z";
    const animationDurationMs = 800;
    const textAttrs = {
        fill: "#fff",
        "font-family": "Roboto, sans-serif",
        "font-size": 18,
        "font-weight": 400,
        opacity: 0,
    };
    const getBallHeight = (x) => (x === "up" ? [140, -7] : [210, 7]);
    const getLineAttrs = (color) => ({
        "stroke-width": 2,
        stroke: color,
        "stroke-opacity": 1,
    });
    const getAppTextAttrs = (href) => {
        return {
            href,
            fill: "#000",
            cursor: "pointer",
            opacity: 0,
            // target: "new",
            transform: "uppercase",
            "font-family": "Roboto, sans-serif",
            "font-size": 16,
            "font-weight": 200,
            "text-anchor": "start",
        };
    };
    return (_jsx(Box, Object.assign({ sx: {
            width: "100%",
            overflowX: "hidden",
            justifyContent: "center",
            display: "flex",
        } }, { children: _jsxs(Paper, Object.assign({ width: 1100, height: 400 }, { children: [_jsxs(Set, { children: [_jsx(Path, { d: ["M 0 150 l 0 0 l 25 25 l -25 25 l 0 0 25 -25 z"], attr: { fill: colors[0], stroke: "" }, animate: Raphael.animation({ path: [path1] }, animationDurationMs, "<>") }), _jsx(Text, { x: 185, y: 175, text: "Target identification & validation", attr: textAttrs, animate: Raphael.animation({ opacity: 1 }, 200).delay(800) }), apps[0].map(([direction, x, y, appName, appUrl]) => {
                            const ball = getBallHeight(direction);
                            return (_jsxs(Set, { children: [_jsx(Circle, { x: x, y: ball[0], r: 0, attr: { fill: colors[0], "stroke-width": 0, opacity: 1 }, animate: Raphael.animation({ r: 8 }, 200).delay(1000) }), _jsx(Path, { d: [`M ${x} ${ball[0] + ball[1]}`], attr: getLineAttrs(colors[0]), animate: Raphael.animation({ path: [`M ${x} ${ball[0] + ball[1]} l 0 ${y} l 20 0`] }, 200, "<>").delay(1200) }), _jsx(Text, { x: x + 26, y: ball[0] + Number(y) + ball[1], text: appName, attr: getAppTextAttrs(appUrl.toLowerCase()), animate: Raphael.animation({ opacity: 1 }, 200).delay(1400) })] }, appName));
                        })] }), _jsxs(Set, { children: [_jsx(Path, { d: ["M 0 150 l 0 0 l 25 25 l -25 25 l 0 0 25 -25 z"], attr: { fill: colors[1], stroke: "" }, animate: Raphael.animation({ path: [path2] }, animationDurationMs, "<>") }), _jsx(Text, { x: 530, y: 175, text: "Lead discovery", attr: textAttrs, animate: Raphael.animation({ opacity: 1 }, 200).delay(800) }), apps[2].map(([direction, x, y, appName, appUrl]) => {
                            const ball = getBallHeight(direction);
                            return (_jsxs(Set, { children: [_jsx(Circle, { x: x, y: ball[0], r: 0, attr: { fill: colors[1], "stroke-width": 0, opacity: 1 }, animate: Raphael.animation({ r: 8 }, 200).delay(1000) }), _jsx(Path, { d: [`M ${x} ${ball[0] + ball[1]}`], attr: getLineAttrs(colors[1]), animate: Raphael.animation({ path: [`M ${x} ${ball[0] + ball[1]} l 0 ${y} l 20 0`] }, 200, "<>").delay(1200) }), _jsx(Text, { x: x + 26, y: ball[0] + Number(y) + ball[1], text: appName, attr: getAppTextAttrs(appUrl.toLowerCase()), animate: Raphael.animation({ opacity: 1 }, 200).delay(1400) })] }, appName));
                        })] }), _jsxs(Set, { children: [_jsx(Path, { d: ["M 0 150 l 0 0 l 25 25 l -25 25 l 0 0 25 -25 z"], attr: { fill: colors[2], stroke: "" }, animate: Raphael.animation({ path: [path3] }, animationDurationMs, "<>") }), _jsx(Text, { x: 880, y: 175, text: "Lead optimization", attr: textAttrs, animate: Raphael.animation({ opacity: 1 }, 200).delay(800) }), apps[1].map(([direction, x, y, appName, appUrl]) => {
                            const ball = getBallHeight(direction);
                            return (_jsxs(Set, { children: [_jsx(Circle, { x: x, y: ball[0], r: 0, attr: { fill: colors[2], "stroke-width": 0, opacity: 1 }, animate: Raphael.animation({ r: 8 }, 200).delay(1000) }), _jsx(Path, { d: [`M ${x} ${ball[0] + ball[1]}`], attr: getLineAttrs(colors[2]), animate: Raphael.animation({ path: [`M ${x} ${ball[0] + ball[1]} l 0 ${y} l 20 0`] }, 200, "<>").delay(1200) }), _jsx(Text, { x: x + 26, y: ball[0] + Number(y) + ball[1], text: appName, attr: getAppTextAttrs(appUrl.toLowerCase()), animate: Raphael.animation({ opacity: 1 }, 200).delay(1400) })] }, appName));
                        })] })] })) })));
}
