import { jsx as _jsx } from "react/jsx-runtime";
import Draggable from "react-draggable";
import { Box, Fade } from "@mui/material";
import { RepresentationControlsPanel } from "./RepresentationControlsPanel";
import { shallow } from "zustand/shallow";
import { useResizeHandler } from "../positionRepresentationsPanel";
export function RepresentationsPanelEntryPoint({ NAPGenericStore, vss, molstar, pyodideWorker, }) {
    const [openBottomPanel, representationsPanelPosition, setRepresentationsPanelPosition, setRepresentationsPanelWasDragged,] = NAPGenericStore((state) => [
        state._openBottomPanel,
        state.representationsPanelPosition,
        state.setRepresentationsPanelPosition,
        state.setRepresentationsPanelWasDragged,
    ], shallow);
    const onControlledDrag = (e, position) => {
        const { x, y } = position;
        setRepresentationsPanelPosition({ x, y });
        setRepresentationsPanelWasDragged(true);
    };
    useResizeHandler();
    return (_jsx(Draggable, Object.assign({ bounds: "body", handle: ".drag-handle", position: representationsPanelPosition, onDrag: onControlledDrag }, { children: _jsx(Fade, Object.assign({ in: openBottomPanel }, { children: _jsx(Box, Object.assign({ sx: { zIndex: 1201 } }, { children: _jsx(RepresentationControlsPanel, { vss: vss, molstar: molstar, NAPGenericStore: NAPGenericStore, pyodide: pyodideWorker }) })) })) })));
}
