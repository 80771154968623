import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, } from "@mui/material";
import { shallow } from "zustand/shallow";
export function FileDetailsDialog({ NAPGenericStore, }) {
    const [openFileDetailsDialog, setOpenFileDetailsDialog, fileDetails] = NAPGenericStore((state) => [
        state._openFileDetailsDialog,
        state.setOpenFileDetailsDialog,
        state._fileDetails,
    ], shallow);
    return (_jsxs(Dialog, Object.assign({ open: openFileDetailsDialog, PaperProps: { sx: { width: "40%", height: "80%" } } }, { children: [_jsx(DialogTitle, { children: "Details" }), _jsxs(DialogContent, { children: [_jsxs("div", { children: ["Name: ", fileDetails.name] }), _jsxs("div", { children: ["isDir: ", fileDetails.isDir ? "yes" : "no"] }), _jsxs("div", { children: ["hidden: ", fileDetails.isHidden ? "yes" : "no"] }), _jsxs("div", { children: ["Full ID: ", fileDetails.id] })] }), _jsx(DialogActions, { children: _jsx(Button, Object.assign({ variant: "outlined", onClick: () => setOpenFileDetailsDialog(false) }, { children: "close" })) })] })));
}
