// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import * as yup from "yup";
function yupSchemaFieldGenerator(manifestElement) {
    var _a, _b, _c;
    let schema;
    const maxvalue = (_a = manifestElement.gui_options) === null || _a === void 0 ? void 0 : _a.max_value;
    const minvalue = (_b = manifestElement.gui_options) === null || _b === void 0 ? void 0 : _b.min_value;
    switch (manifestElement.type) {
        case "str":
            if (manifestElement.choices) {
                schema = yup.string();
            }
            else {
                // eslint-disable-next-line no-control-regex
                schema = yup.string().matches(/^[\x00-\x7F]*$/, "Invalid character.");
            }
            break;
        case "Path":
            schema = yup.string();
            break;
        case "int":
            schema = yup
                .number()
                .integer()
                .typeError(`${manifestElement.name} must be a number.`)
                .transform((currentValue) => !!currentValue || currentValue === 0 ? currentValue : undefined);
            if (minvalue || minvalue === 0) {
                schema = schema.min(minvalue, `Please enter a value that is no less than ${minvalue}.`);
            }
            if (maxvalue || maxvalue === 0) {
                schema = schema.max(maxvalue, `Please select a value that is no more than ${maxvalue}.`);
            }
            break;
        case "float":
            schema = yup
                .number()
                .typeError(`${manifestElement.name} must be a number`)
                .transform((currentValue) => !!currentValue || currentValue === 0 ? currentValue : undefined);
            if (minvalue || minvalue === 0) {
                schema = schema.min(minvalue, `Please enter a value that is no less than ${minvalue}.`);
            }
            if (maxvalue || maxvalue === 0) {
                schema = schema.max(maxvalue, `Please select a value that is no more than ${maxvalue}.`);
            }
            break;
        case "str_to_num_array":
            schema = yup
                .array(yup.number())
                .typeError(`${manifestElement.name} must be comma-separated numbers`)
                .transform((currentValue, oldValue) => {
                const numArr = oldValue.split(",").map((e) => Number(e));
                return numArr.some((e) => isNaN(e)) ? "" : numArr;
            });
            break;
        case "bool":
        case "str_to_bool":
            schema = yup.boolean();
    }
    if (manifestElement.mandatory) {
        schema = schema.required("Required field.");
    }
    else {
        schema = schema.optional();
    }
    const nargsVal = manifestElement.nargs;
    if (!((_c = manifestElement.gui_options) === null || _c === void 0 ? void 0 : _c.linked_group) && nargsVal != null) {
        if (typeof nargsVal === "number") {
            schema = yup
                .array()
                .of(schema)
                .length(nargsVal, `Please provide ${nargsVal} items`);
        }
        else {
            if (manifestElement.mandatory) {
                schema = yup.array().of(schema).min(1, "Required field.");
            }
            else {
                schema = yup.array().of(schema);
            }
        }
        schema = schema.transform((currentValue) => currentValue
            .map((val) => val[manifestElement.name])
            .filter((val) => !!val || val === 0));
    }
    return schema;
}
function yupSchemaLinkedGroup(linkedGroup) {
    const linkedGroupElements = linkedGroup.elements;
    const linkedElementsArr = [];
    const linkedGroupSchemas = {};
    linkedGroupElements.forEach((manifestEl) => {
        linkedElementsArr.push([
            manifestEl.name,
            yup
                .array(yupSchemaFieldGenerator(manifestEl))
                .transform((currentValue) => currentValue.every((el) => !el && el !== false && el !== 0)
                ? undefined
                : currentValue),
        ]);
        linkedGroupSchemas[manifestEl.name] = yup.ref(`${linkedGroup.name}.${manifestEl.name}`);
    });
    linkedGroupSchemas[linkedGroup.name] = yup
        .object(Object.fromEntries(linkedElementsArr))
        .transform((currentValue, originalValue) => {
        const d = {};
        originalValue.forEach((argFields) => {
            Object.entries(argFields).forEach(([name, val]) => {
                if (!(name in d)) {
                    d[name] = [];
                }
                d[name].push(val);
            });
        });
        return d;
    });
    return linkedGroupSchemas;
}
function fieldDefault(manifestElement, preselVal) {
    var _a;
    const origVal = preselVal !== undefined ? preselVal : manifestElement.value;
    let defVal = origVal || origVal === 0 || origVal === false ? origVal : undefined;
    let defaultFin;
    if (manifestElement.type == "Path" && defVal == undefined)
        defVal = "";
    if (manifestElement.nargs != null &&
        !((_a = manifestElement.gui_options) === null || _a === void 0 ? void 0 : _a.linked_group)) {
        defaultFin = [{ [manifestElement.name]: defVal }];
    }
    else {
        defaultFin = defVal;
    }
    return defaultFin;
}
export const defaultValues = (appManifestGroups, preselectedValues) => {
    const defaults = {};
    appManifestGroups.forEach((appGroup) => {
        appGroup.elements.forEach((appLinkedGroup) => {
            if (appLinkedGroup.isGroup) {
                const linkedDefaults = {};
                appLinkedGroup.elements.forEach((appField) => {
                    const preselVal = preselectedValues
                        ? preselectedValues[appField.name]
                        : undefined;
                    const defaultVal = fieldDefault(appField, preselVal);
                    linkedDefaults[appField.name] = defaultVal;
                });
                defaults[appLinkedGroup.name] = [linkedDefaults];
            }
            else {
                appLinkedGroup.elements.forEach((appField) => {
                    const preselVal = preselectedValues
                        ? preselectedValues[appField.name]
                        : undefined;
                    const defaultVal = fieldDefault(appField, preselVal);
                    defaults[appField.name] = defaultVal;
                });
            }
        });
    });
    defaults["name"] = ""; // job name
    return defaults;
};
export const formSchema = (appManifestGroups) => {
    let yupObj = {};
    appManifestGroups.forEach((appGroup) => {
        appGroup.elements.forEach((appLinkedGroup) => {
            if (appLinkedGroup.isGroup) {
                const linkedGroupSchemas = yupSchemaLinkedGroup(appLinkedGroup);
                yupObj = Object.assign(Object.assign({}, yupObj), linkedGroupSchemas);
            }
            else {
                appLinkedGroup.elements.forEach((appField) => {
                    yupObj[appField.name] = yupSchemaFieldGenerator(appField);
                });
            }
        });
    });
    // eslint-disable-next-line no-control-regex
    yupObj["name"] = yup.string().matches(/^[\x00-\x7F]*$/, "Invalid character.");
    return yup.object(yupObj).required();
};
