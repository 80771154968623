import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Grid, Typography } from "@mui/material";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { ActionIconButton } from "../../";
export function PreviousNextButtons({ handleClickFirst, handleClickPrevious, handleClickNext, handleClickLast, firstIsDisabled, previousIsDisabled, nextIsDisabled, lastIsDisabled, size, value, }) {
    return (_jsxs(Grid, Object.assign({ container: true, justifyContent: "space-around", alignItems: "center" }, { children: [_jsxs(Grid, Object.assign({ container: true, item: true, xs: "auto", justifyContent: "center", alignItems: "center" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(ActionIconButton, Object.assign({ disabled: firstIsDisabled, tooltipMsg: "Jump to first", onClick: handleClickFirst, iconHoverColor: "auto", backgroundColor: "auto", backgroundHoverColor: "auto" }, { children: _jsx(SkipPreviousIcon, { fontSize: size }) })) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(ActionIconButton, Object.assign({ disabled: previousIsDisabled, tooltipMsg: "Previous", onClick: handleClickPrevious, iconHoverColor: "auto", backgroundColor: "auto", backgroundHoverColor: "auto" }, { children: _jsx(ArrowLeftIcon, { fontSize: size }) })) }))] })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: value !== undefined ? (_jsxs(Typography, Object.assign({ variant: "caption", sx: { color: "#808080" } }, { children: [" ", value, " "] }))) : null })), _jsxs(Grid, Object.assign({ container: true, item: true, xs: "auto", justifyContent: "center", alignItems: "center" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(ActionIconButton, Object.assign({ tooltipMsg: "Next", onClick: handleClickNext, iconHoverColor: "auto", backgroundColor: "auto", backgroundHoverColor: "auto", disabled: nextIsDisabled }, { children: _jsx(ArrowRightIcon, { fontSize: size }) })) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(ActionIconButton, Object.assign({ disabled: lastIsDisabled, tooltipMsg: "Jump to last", onClick: handleClickLast, iconHoverColor: "auto", backgroundColor: "auto", backgroundHoverColor: "auto" }, { children: _jsx(SkipNextIcon, { fontSize: size }) })) }))] }))] })));
}
