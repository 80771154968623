import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * @author Timur Kuzhagaliyev <tim.kuzh@gmail.com>
 * @author Panagiotis Tourlas <p.tourlas@acellera.com>
 * @copyright 2022
 * @license MIT
 */
import { useCallback } from "react";
import { useClickHandler, useKeyDownHandler } from "./ClickableWrapper-hooks";
export const ClickableWrapper = (props) => {
    const { children, wrapperTag: WrapperTag, passthroughProps, onSingleClick, onDoubleClick, onKeyboardClick, setFocused, } = props;
    const handleClick = useClickHandler(onSingleClick, onDoubleClick);
    const handleKeyDown = useKeyDownHandler(onKeyboardClick);
    const compProps = {
        onFocus: useCallback(() => setFocused && setFocused(true), [setFocused]),
        onBlur: useCallback(() => setFocused && setFocused(false), [setFocused]),
    };
    if (onSingleClick || onDoubleClick || onKeyboardClick) {
        compProps.onClick = handleClick;
        compProps.onKeyDown = handleKeyDown;
        compProps.tabIndex = 0;
    }
    const mergedProps = Object.assign(Object.assign({}, compProps), passthroughProps);
    return _jsx(WrapperTag, Object.assign({}, mergedProps, { children: children }));
};
