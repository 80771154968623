import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { memo } from "react";
import { FileHelper } from "../../util/file-helper";
import { c, makeLocalChonkyStyles } from "../../util/styles";
import { useFileEntryHtmlProps, useFileEntryState } from "./FileEntry-hooks";
import { FileEntryName } from "./FileEntryName";
import { GridEntryPreviewFile, GridEntryPreviewFolder, } from "./GridEntryPreview";
export const GridEntry = memo(({ file, selected, focused, dndState }) => {
    const isDirectory = FileHelper.isDirectory(file);
    const entryState = useFileEntryState(file, selected, focused);
    const classes = useFileEntryStyles(entryState);
    const fileEntryHtmlProps = useFileEntryHtmlProps(file);
    const entryClassName = c({
        [classes.gridFileEntry]: true,
    });
    return (_jsxs("div", Object.assign({ className: entryClassName }, fileEntryHtmlProps, { children: [isDirectory ? (_jsx(GridEntryPreviewFolder, { className: classes.gridFileEntryPreview, entryState: entryState, dndState: dndState })) : (_jsx(GridEntryPreviewFile, { className: classes.gridFileEntryPreview, entryState: entryState, dndState: dndState })), _jsx("div", Object.assign({ className: classes.gridFileEntryNameContainer }, { children: _jsx(FileEntryName, { className: classes.gridFileEntryName, file: file }) }))] })));
});
GridEntry.displayName = "GridEntry";
const useFileEntryStyles = makeLocalChonkyStyles((theme) => ({
    gridFileEntry: {
        flexDirection: "column",
        display: "flex",
        height: "100%",
    },
    gridFileEntryPreview: {
        flexGrow: 1,
    },
    gridFileEntryNameContainer: {
        fontSize: theme.gridFileEntry.fontSize,
        wordBreak: "break-word",
        textAlign: "center",
        paddingTop: 5,
    },
    gridFileEntryName: {
        backgroundColor: (state) => state.selected ? "rgba(0,153,255, .25)" : "transparent",
        textDecoration: (state) => state.focused ? "underline" : "none",
        borderRadius: 3,
        padding: [2, 4],
    },
}));
