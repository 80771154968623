// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
export class Logger {
    static error(...args) {
        // eslint-disable-next-line no-console
        console.error("[Chonky runtime error]", ...args);
    }
    static warn(...args) {
        // eslint-disable-next-line no-console
        console.warn("[Chonky runtime warning]", ...args);
    }
    static debug(...args) {
        // eslint-disable-next-line no-console
        console.debug("[Chonky runtime debug]", ...args);
    }
    static formatBullets(bullets) {
        return `\n- ${bullets.join("\n- ")}`;
    }
}
