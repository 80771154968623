// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
import { Loci } from "molstar/lib/mol-model/loci";
import { Vec3 } from "molstar/lib/mol-math/linear-algebra";
export function distanceLabel(pair, molstar) {
    const unitLabel = molstar.managers.structure.measurement.state.options.distanceUnitLabel;
    const [cA, cB] = pair.loci.map((l) => Loci.getCenter(l));
    const distance = `${Vec3.distance(cA, cB).toFixed(2)} ${unitLabel}`;
    return distance;
}
function radToDeg(rad) {
    const PiDiv180 = Math.PI / 180;
    return rad / PiDiv180; // rad * 180 / Math.PI
}
export function angleLabel(triple) {
    const [cA, cB, cC] = triple.loci.map((l) => Loci.getCenter(l));
    const vAB = Vec3.sub(Vec3(), cA, cB);
    const vCB = Vec3.sub(Vec3(), cC, cB);
    const angle = `${radToDeg(Vec3.angle(vAB, vCB)).toFixed(2)}\u00B0`;
    return angle;
}
export function dihedralLabel(quad) {
    const [cA, cB, cC, cD] = quad.loci.map((l) => Loci.getCenter(l));
    const dihedral = `${radToDeg(Vec3.dihedralAngle(cA, cB, cC, cD)).toFixed(2)}\u00B0`;
    return dihedral;
}
