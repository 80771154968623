import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useCallback, useContext, useMemo, memo } from "react";
import { useSelector } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";
import { ChonkyActions } from "../../action-definitions/index";
import { selectCurrentFolder, selectFileViewConfig, selectors, } from "../../redux/selectors";
import { FileViewMode } from "../../types/file-view.types";
import { ChonkyIconName } from "../../types/icons.types";
import { useFileDrop } from "../../util/dnd";
import { ChonkyIconContext } from "../../util/icon-helper";
import { c, getStripeGradient, makeGlobalChonkyStyles, makeLocalChonkyStyles, } from "../../util/styles";
import { FileListEmpty } from "./FileListEmpty";
import { GridContainer } from "./GridContainer";
import { ListContainer } from "./ListContainer";
export const FileList = memo((props) => {
    const displayFileIds = useSelector(selectors.getDisplayFileIds);
    const viewConfig = useSelector(selectFileViewConfig);
    const currentFolder = useSelector(selectCurrentFolder);
    const { drop, dndCanDrop, dndIsOverCurrent } = useFileDrop({
        file: currentFolder,
    });
    const styleState = useMemo(() => ({ dndCanDrop, dndIsOverCurrent }), [dndCanDrop, dndIsOverCurrent]);
    const localClasses = useLocalStyles(styleState);
    const classes = useStyles(viewConfig);
    const { onScroll } = props;
    // In Chonky v0.x, this field was user-configurable. In Chonky v1.x+, we hardcode
    // this to `true` to simplify configuration. Users can just wrap Chonky in their
    // own `div` if they want to have finer control over the height.
    const fillParentContainer = true;
    const listRenderer = useCallback(({ width, height }) => {
        if (displayFileIds.length === 0) {
            return (_jsx(FileListEmpty, { width: width, height: viewConfig.entryHeight }));
        }
        else if (viewConfig.mode === FileViewMode.List) {
            return _jsx(ListContainer, { width: width, height: height });
        }
        else {
            return _jsx(GridContainer, { width: width, height: height });
        }
    }, [displayFileIds, viewConfig]);
    const ChonkyIcon = useContext(ChonkyIconContext);
    return (_jsxs("div", Object.assign({ onScroll: onScroll, ref: drop, className: c([classes.fileListWrapper, localClasses.fileListWrapper]), role: "list" }, { children: [_jsx("div", Object.assign({ className: localClasses.dndDropZone }, { children: _jsx("div", Object.assign({ className: localClasses.dndDropZoneIcon }, { children: _jsx(ChonkyIcon, { icon: dndCanDrop
                            ? ChonkyIconName.dndCanDrop
                            : ChonkyIconName.dndCannotDrop }) })) })), _jsx(AutoSizer, Object.assign({ disableHeight: !fillParentContainer }, { children: listRenderer }))] })));
});
FileList.displayName = "FileList";
const useLocalStyles = makeLocalChonkyStyles((theme) => ({
    fileListWrapper: {
        minHeight: ChonkyActions.EnableGridView.fileViewConfig.entryHeight + 2,
        background: (state) => state.dndIsOverCurrent && state.dndCanDrop
            ? state.dndCanDrop
                ? getStripeGradient(theme.dnd.fileListCanDropMaskOne, theme.dnd.fileListCanDropMaskTwo)
                : getStripeGradient(theme.dnd.fileListCannotDropMaskOne, theme.dnd.fileListCannotDropMaskTwo)
            : "none",
    },
    dndDropZone: {
        display: (state) => 
        // When we cannot drop, we don't show an indicator at all
        state.dndIsOverCurrent && state.dndCanDrop ? "block" : "none",
        borderRadius: theme.gridFileEntry.borderRadius,
        pointerEvents: "none",
        position: "absolute",
        height: "100%",
        width: "100%",
        zIndex: 2,
    },
    dndDropZoneIcon: {
        backgroundColor: (state) => state.dndCanDrop ? theme.dnd.canDropMask : theme.dnd.cannotDropMask,
        color: (state) => state.dndCanDrop ? theme.dnd.canDropColor : theme.dnd.cannotDropColor,
        borderRadius: theme.gridFileEntry.borderRadius,
        transform: "translateX(-50%) translateY(-50%)",
        position: "absolute",
        textAlign: "center",
        lineHeight: "60px",
        fontSize: "2em",
        left: "50%",
        height: 60,
        top: "50%",
        width: 60,
    },
}));
const useStyles = makeGlobalChonkyStyles(() => ({
    fileListWrapper: {
        height: "100%",
        maxHeight: "100%",
    },
}));
