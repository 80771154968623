export const setKeywordColors = (appList) => {
    const allKeys = [];
    appList.forEach((appGroup) => {
        appGroup.groupEntries.forEach((entry) => {
            if (entry["meta_keywords"]) {
                entry["meta_keywords"].forEach((key) => {
                    if (!allKeys.includes(key))
                        allKeys.push(key);
                });
            }
        });
    });
    const keyColors = [
        "#1166ee",
        "#ffa733",
        "#5570f7",
        "#34bb2a",
        "#90c224",
        "#e63900",
        "#ff4000",
        "#7644bb",
        "#1fb6c7",
        "#ff005d",
        "#37a0ae",
        "#00e66f",
        "#ff33f8",
        "#0f00e6",
        "#339933",
    ];
    const d = {};
    allKeys.forEach((key, i) => {
        const color = keyColors[i % keyColors.length];
        d[key] = color;
    });
    return d;
};
