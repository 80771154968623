import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState } from "react";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { ControlledAccordion } from "../../3dViewer/Controls/ControlledAccordion";
import { FormFieldGenerator } from "./FormFieldGeneratorManifest";
import { FormLinkedGroup } from "./FormLinkedGroup";
export function FormGroup({ pyodide, manifestGroup, disabledFields, }) {
    const { formState: { errors }, } = useFormContext();
    const hasErrors = checkHasErrors(manifestGroup.elements, errors);
    const [isOpen, setIsOpen] = useState(false);
    return (_jsx(Grid, Object.assign({ item: true, container: true, direction: "column", xs: true }, { children: manifestGroup.isGroup ? (_jsx(ControlledAccordion, Object.assign({ open: isOpen, callback: () => {
                setIsOpen((prev) => !prev);
            }, name: manifestGroup.name, variant: "outlined", errors: hasErrors }, { children: _jsx(_Fragment, { children: manifestGroup.elements.map((linkedGroup) => (_jsx(FormGroupElement, { pyodide: pyodide, manifestLinkedGroup: linkedGroup, disabledFields: disabledFields }, linkedGroup.name))) }) }))) : (manifestGroup.elements.map((linkedGroup) => (_jsx(FormGroupElement, { pyodide: pyodide, manifestLinkedGroup: linkedGroup, disabledFields: disabledFields }, linkedGroup.name)))) })));
}
function FormGroupElement({ pyodide, manifestLinkedGroup, disabledFields, }) {
    return (_jsx(Grid, Object.assign({ container: true, item: true, direction: "column", spacing: 1, xs: true, wrap: "nowrap" }, { children: manifestLinkedGroup.isGroup ? (_jsx(FormLinkedGroup, { linkedParams: manifestLinkedGroup.elements, pyodide: pyodide, disabledFields: disabledFields })) : (manifestLinkedGroup.elements.map((manifestItem) => {
            var _a;
            return (_jsx(Grid, Object.assign({ item: true }, { children: _jsx(FormFieldGenerator, Object.assign({}, manifestItem, { pyodide: pyodide, disabled: disabledFields && disabledFields.includes(manifestItem.name) })) }), `${(_a = manifestItem.gui_options) === null || _a === void 0 ? void 0 : _a.linked_group}-${manifestItem.name}`));
        })) })));
}
function checkHasErrors(groupElements, errors) {
    let hasErrors = false;
    groupElements.forEach((linkedGroup) => {
        if (linkedGroup.isGroup) {
            const fielderrors = errors[linkedGroup.name];
            if (fielderrors && Object.keys(fielderrors).length > 0) {
                hasErrors = true;
            }
        }
        else {
            linkedGroup.elements.forEach((fieldEl) => {
                const fielderrors = errors[fieldEl.name];
                if (fielderrors) {
                    hasErrors = true;
                }
            });
        }
    });
    return hasErrors;
}
