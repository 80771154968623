import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import "../static/css/LandingPage.css";
import { Box } from "@mui/material";
import { LandingPageNavigation } from "./LandingPageNavigation";
import { AppsTimeline } from "./AppsTimeline";
import { AppCards } from "./AppCards";
import { LandingPageBackground } from "./LandingPageBackground";
import { LandingPageContact } from "./LandingPageContact";
import { LandingPageFooter } from "./LandingPageFooter";
function LandingPageBody() {
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ id: "landingpage_intro", className: "intro_v2" }, { children: [_jsxs("h1", { children: ["PlayMolecule", _jsx("span", { children: "TM" })] }), _jsx("p", { children: "Molecular discovery" })] })), _jsx(Box, Object.assign({ justifyContent: "center", sx: { display: { xs: "none", md: "flex" } } }, { children: _jsx(AppsTimeline, {}) })), _jsx(Box, Object.assign({ sx: { px: { xs: "none", md: 10 }, pb: 5 } }, { children: _jsx(AppCards, {}) })), _jsx(Box, { children: _jsx(LandingPageContact, {}) })] }));
}
export function LandingPage() {
    return (_jsxs(_Fragment, { children: [_jsx(LandingPageBackground, {}), _jsxs(Box, Object.assign({ sx: {
                    zIndex: 1,
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    overflowY: "auto",
                } }, { children: [_jsx(LandingPageNavigation, {}), _jsx(LandingPageBody, {}), _jsx(LandingPageFooter, {})] }))] }));
}
