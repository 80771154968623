// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 *
 * @param text The text to be trimmed.
 * @param charLimit How many characters will be displayed, including 3 dots at the end.
 * @param trimStart If `true`, trims the text from the beginning; if `false`, trims from the end. Default: false.
 * @returns The original text if smaller in length than `charLimit`, else trimmed at position `charLimit-3` followed by 3 dots.
 */
function trimText(text, charLimit, trimStart = false) {
    if (typeof text !== "string")
        throw new TypeError();
    if (text.length < charLimit)
        return text;
    return trimStart
        ? `...${text.substring(text.length - charLimit + 3)}`
        : `${text.substring(0, charLimit - 3)}...`;
}
export default trimText;
