import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState } from "react";
import { Grid } from "@mui/material";
import { useFieldArray, useFormContext, } from "react-hook-form";
import { ControlledAccordion } from "../../3dViewer/Controls/ControlledAccordion";
import { FormFieldGenerator } from "./FormFieldGeneratorManifest";
import { CustomNumArgs } from "./CustomNumArgs";
function checkHasErrors(linkedParams, idx, errors) {
    var _a;
    const linked_group = (_a = linkedParams[0].gui_options) === null || _a === void 0 ? void 0 : _a.linked_group;
    const groupErrors = (linked_group ? errors[linked_group] : undefined);
    const hasErrors = groupErrors
        ? Object.values(groupErrors).some((argErrors) => !!argErrors[idx])
        : false;
    return hasErrors;
}
function FormLinkedGroupElements({ pyodide, linkedParams, groupName, idx, disabledFields, }) {
    const { formState: { errors }, } = useFormContext();
    const [isOpen, setIsOpen] = useState(false);
    const hasErrors = checkHasErrors(linkedParams, idx, errors);
    return (_jsx(ControlledAccordion, Object.assign({ open: isOpen, callback: () => {
            setIsOpen((prev) => !prev);
        }, name: groupName, variant: "outlined", errors: hasErrors }, { children: _jsx(Grid, Object.assign({ container: true, direction: "column", spacing: 1, sx: { pr: 2 }, wrap: "nowrap" }, { children: linkedParams.map((param) => {
                var _a, _b, _c;
                const _param = Object.assign(Object.assign({}, param), { customLabel: ((_a = param.gui_options) === null || _a === void 0 ? void 0 : _a.label)
                        ? (_b = param.gui_options) === null || _b === void 0 ? void 0 : _b.label
                        : param.name, name: `${(_c = param.gui_options) === null || _c === void 0 ? void 0 : _c.linked_group}.${idx}.${param.name}`, hideNargs: true });
                return (_jsx(Grid, Object.assign({ item: true }, { children: _jsx(FormFieldGenerator, Object.assign({}, _param, { pyodide: pyodide, disabled: disabledFields && disabledFields.includes(_param.name) })) }), _param.name));
            }) })) })));
}
export function FormLinkedGroup({ pyodide, linkedParams, disabledFields, }) {
    var _a, _b;
    const linkedGroupName = (_a = linkedParams[0].gui_options) === null || _a === void 0 ? void 0 : _a.linked_group;
    const { fields, append, remove } = useFieldArray({
        name: linkedGroupName ? linkedGroupName : "",
    });
    const handleAdd = () => {
        const newFields = linkedParams
            .map((param) => [
            param.name,
            param.value || param.value === 0 || param.value === false
                ? param.value
                : undefined,
        ])
            .reduce((a, v) => (Object.assign(Object.assign({}, a), { [v[0]]: v[1] })), {});
        append(newFields);
    };
    const handleRemove = () => {
        remove(-1);
    };
    const linked_group = (_b = linkedParams[0].gui_options) === null || _b === void 0 ? void 0 : _b.linked_group;
    const namePrefix = linked_group ? linked_group : "";
    const generateFormField = (i) => (_jsx(FormLinkedGroupElements, { pyodide: pyodide, linkedParams: linkedParams, groupName: `${namePrefix} ${i > 0 ? "#" + (i + 1) : ""}`, idx: i, disabledFields: disabledFields }, i));
    return (_jsx(CustomNumArgs, { fields: fields, handleAdd: handleAdd, handleRemove: handleRemove, repeatElement: generateFormField }));
}
