import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import RoundDeleteButton from "../../components/Buttons/DeleteButton/RoundDeleteButton";
export function DeleteTable({ onDelete }) {
    return (_jsx(RoundDeleteButton, { onClick: onDelete, tooltipMsg: "Close table", 
        // placement="top"
        confirmationMsg: `Are you sure you want to close this table?`, icon: "close" }));
}
