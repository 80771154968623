import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Button } from "@mui/material";
import { DCBrowserStore } from "../../DatacenterBrowser";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { shallow } from "zustand/shallow";
import { NewProjectDialog } from "./NewProjectDialog";
import { ProjectsMenu } from "./ProjectsMenu";
import { userAuthStore } from "../../Authentication/userAuthState";
export function ProjectSelector() {
    const [project, addSessionProject] = DCBrowserStore((state) => [state.project, state.addSessionProject], shallow);
    const [userInfo] = userAuthStore((state) => [state.userInfo], shallow);
    const userIsAuthenticated = !!userInfo;
    const [, setCookie] = useCookies(["PM_PROJECT"]);
    useEffect(() => {
        if (!userIsAuthenticated)
            return;
        window.highPriorityMsg({
            type: "fetch-projects",
            userId: userInfo.userId,
            url: window.location.origin,
        });
    }, [userIsAuthenticated]);
    // Control projects menu
    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    // Control new project dialog
    const [isNewProjectDialogOpen, setIsNewProjectDialogOpen] = useState(false);
    const openNewProjectDialog = () => {
        setIsNewProjectDialogOpen(true);
    };
    const closeNewProjectDialog = () => {
        setIsNewProjectDialogOpen(false);
    };
    const addProjectCallback = (projectName) => {
        addSessionProject(projectName, userInfo === null || userInfo === void 0 ? void 0 : userInfo.userId);
        setCookie("PM_PROJECT", projectName, {
            path: "/",
            domain: process.env.NODE_ENV === "development"
                ? undefined
                : process.env.REACT_APP_BACKEND_URL
                    ? `.${process.env.REACT_APP_BACKEND_URL.replace("https://", "")}`
                    : undefined,
            secure: process.env.NODE_ENV === "development" ? false : true,
            sameSite: "Strict",
            maxAge: 86400, // 86400 s = 90 days
        });
        handleCloseMenu();
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ variant: "text", onClick: handleOpenMenu }, { children: project.replace(/_/g, " ") })), _jsx(ProjectsMenu, { anchorEl: anchorEl, isMenuOpen: isMenuOpen, userId: userInfo === null || userInfo === void 0 ? void 0 : userInfo.userId, handleCloseMenu: handleCloseMenu, handleAddProject: openNewProjectDialog }), _jsx(NewProjectDialog, { isDialogOpen: isNewProjectDialogOpen, closeDialog: closeNewProjectDialog, addProjectCallback: addProjectCallback })] }));
}
