import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useMemo, memo } from "react";
import { useSelector } from "react-redux";
import { selectToolbarItems } from "../../redux/selectors";
import { makeGlobalChonkyStyles } from "../../util/styles";
import { SmartToolbarButton } from "./ToolbarButton";
import { ToolbarDropdown } from "./ToolbarDropdown";
import { ToolbarInfo } from "./ToolbarInfo";
import { ToolbarSearch } from "./ToolbarSearch";
export const FileToolbar = memo(() => {
    const classes = useStyles();
    const toolbarItems = useSelector(selectToolbarItems);
    const toolbarItemComponents = useMemo(() => {
        const components = [];
        for (let i = 0; i < toolbarItems.length; ++i) {
            const item = toolbarItems[i];
            const key = `toolbar-item-${typeof item === "string" ? item : item.name}`;
            const component = typeof item === "string" ? (_jsx(SmartToolbarButton, { fileActionId: item }, key)) : (_jsx(ToolbarDropdown, { name: item.name, fileActionIds: item.fileActionIds }, key));
            components.push(component);
        }
        return components;
    }, [toolbarItems]);
    return (_jsx("div", Object.assign({ className: classes.toolbarWrapper }, { children: _jsxs("div", Object.assign({ className: classes.toolbarContainer }, { children: [_jsxs("div", Object.assign({ className: classes.toolbarLeft }, { children: [_jsx(ToolbarSearch, {}), _jsx(ToolbarInfo, {})] })), _jsx("div", Object.assign({ className: classes.toolbarRight }, { children: toolbarItemComponents }))] })) })));
});
const useStyles = makeGlobalChonkyStyles((theme) => ({
    toolbarWrapper: {},
    toolbarContainer: {
        flexWrap: "wrap-reverse",
        display: "flex",
    },
    toolbarLeft: {
        paddingBottom: theme.margins.rootLayoutMargin,
        flexWrap: "nowrap",
        flexGrow: 10000,
        display: "flex",
    },
    toolbarLeftFiller: {
        flexGrow: 10000,
    },
    toolbarRight: {
        paddingBottom: theme.margins.rootLayoutMargin,
        flexWrap: "nowrap",
        display: "flex",
    },
}));
