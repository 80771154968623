import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState } from "react";
import { Box, Dialog, DialogTitle, DialogContent, IconButton, } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export function BugReportPanel({ open, setOpen, }) {
    const defaultDimensions = {
        width: "40%",
        height: "50%",
    };
    const [_dialogDimensions, _setDialogDimensions] = useState(defaultDimensions);
    const resetWindow = () => {
        _setDialogDimensions(defaultDimensions);
        setOpen(false);
    };
    return (_jsxs(Dialog, Object.assign({ open: open, PaperProps: {
            sx: {
                minWidth: _dialogDimensions.width,
                height: _dialogDimensions.height + 150,
            },
        } }, { children: [_jsx(DialogTitle, { children: _jsx(Box, Object.assign({ display: "flex", justifyContent: "flex-end" }, { children: _jsx(IconButton, Object.assign({ color: "primary", onClick: resetWindow }, { children: _jsx(CloseIcon, {}) })) })) }), _jsx(DialogContent, { children: _jsx("iframe", { title: "mojo-helpdesk", src: "https://acellera.mojohelpdesk.com/widgets/create_request#/ticket-form/28405", style: { width: "100%", height: "530px", border: "none" } }) })] })));
}
