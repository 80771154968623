import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useContext, useMemo, memo } from "react";
import { useLocalizedFileEntryStrings } from "../../util/i18n";
import { ChonkyIconContext } from "../../util/icon-helper";
import { c, makeLocalChonkyStyles } from "../../util/styles";
import { TextPlaceholder } from "../external/TextPlaceholder";
import { useDndIcon, useFileEntryHtmlProps, useFileEntryState, } from "./FileEntry-hooks";
import { FileEntryName } from "./FileEntryName";
import { useCommonEntryStyles } from "./GridEntryPreview";
export const ListEntry = memo(({ file, selected, focused, dndState }) => {
    const entryState = useFileEntryState(file, selected, focused);
    const dndIconName = useDndIcon(dndState);
    const { fileModDateString, fileSizeString } = useLocalizedFileEntryStrings(file);
    const styleState = useMemo(() => ({
        entryState,
        dndState,
    }), [dndState, entryState]);
    const classes = useStyles(styleState);
    const commonClasses = useCommonEntryStyles(entryState);
    const ChonkyIcon = useContext(ChonkyIconContext);
    const fileEntryHtmlProps = useFileEntryHtmlProps(file);
    return (_jsxs("div", Object.assign({ className: classes.listFileEntry }, fileEntryHtmlProps, { children: [_jsx("div", { className: commonClasses.focusIndicator }), _jsx("div", { className: c([
                    commonClasses.selectionIndicator,
                    classes.listFileEntrySelection,
                ]) }), _jsx("div", Object.assign({ className: classes.listFileEntryIcon }, { children: _jsx(ChonkyIcon, { icon: dndIconName !== null && dndIconName !== void 0 ? dndIconName : entryState.icon, spin: dndIconName ? false : entryState.iconSpin, fixedWidth: true }) })), _jsx("div", Object.assign({ className: classes.listFileEntryName, title: file ? file.name : undefined }, { children: _jsx(FileEntryName, { file: file }) })), _jsx("div", Object.assign({ className: classes.listFileEntryProperty }, { children: file ? (fileModDateString !== null && fileModDateString !== void 0 ? fileModDateString : _jsx("span", { children: "\u2014" })) : (_jsx(TextPlaceholder, { minLength: 5, maxLength: 15 })) })), _jsx("div", Object.assign({ className: classes.listFileEntryProperty }, { children: file ? (fileSizeString !== null && fileSizeString !== void 0 ? fileSizeString : _jsx("span", { children: "\u2014" })) : (_jsx(TextPlaceholder, { minLength: 10, maxLength: 20 })) }))] })));
});
const useStyles = makeLocalChonkyStyles((theme) => ({
    listFileEntry: {
        boxShadow: `inset ${theme.palette.divider} 0 -1px 0`,
        fontSize: theme.listFileEntry.fontSize,
        color: ({ dndState }) => dndState.dndIsOver
            ? dndState.dndCanDrop
                ? theme.dnd.canDropColor
                : theme.dnd.cannotDropColor
            : "inherit",
        alignItems: "center",
        position: "relative",
        display: "flex",
        height: "100%",
    },
    listFileEntrySelection: {
        opacity: 0.6,
    },
    listFileEntryIcon: {
        color: ({ entryState, dndState }) => dndState.dndIsOver
            ? dndState.dndCanDrop
                ? theme.dnd.canDropColor
                : theme.dnd.cannotDropColor
            : entryState.color,
        fontSize: theme.listFileEntry.iconFontSize,
        boxSizing: "border-box",
        padding: [2, 4],
        zIndex: 20,
    },
    listFileEntryName: {
        textOverflow: "ellipsis",
        boxSizing: "border-box",
        whiteSpace: "nowrap",
        overflow: "hidden",
        flex: "1 1 300px",
        paddingLeft: 8,
        zIndex: 20,
    },
    listFileEntryProperty: {
        fontSize: theme.listFileEntry.propertyFontSize,
        boxSizing: "border-box",
        whiteSpace: "nowrap",
        overflow: "hidden",
        flex: "0 1 250px",
        padding: [2, 8],
        zIndex: 20,
    },
}));
