var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import Box from "@mui/material/Box";
import { Autocomplete, TextField } from "@mui/material";
export function AutocompleteSelectPM({ hookFormRegisterFunc, formKey, disabled = false, label, error, size = "small", options, }) {
    const _a = hookFormRegisterFunc(formKey), { ref } = _a, inputProps = __rest(_a, ["ref"]);
    return (_jsx(Box, Object.assign({ sx: { minWidth: 100, marginTop: 1 } }, { children: _jsx(Autocomplete, { options: options, autoHighlight: true, getOptionLabel: (option) => option.text, renderOption: (props, option) => (_jsxs(Box, Object.assign({ component: "li", sx: { "& > img": { mr: 2, flexShrink: 0 } } }, props, { children: [_jsx("img", { loading: "lazy", width: "20", src: `https://flagcdn.com/w20/${option.flag}.png`, srcSet: `https://flagcdn.com/w40/${option.flag}.png 2x`, alt: "" }), option.text] }))), renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { inputRef: ref }, inputProps, { disabled: disabled, error: !!error, helperText: error, label: label, size: size, inputProps: Object.assign(Object.assign({}, params.inputProps), { autoComplete: "new-password" }) }))) }) })));
}
