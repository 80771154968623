// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { dispatchNotificationEvent } from "../NotificationSystem/utils";
export const addQueryParam = (urlString, param, newVal) => {
    try {
        const url = new URL(urlString);
        const params = url.searchParams;
        if (params.has(param)) {
            params.set("prompt", newVal);
        }
        else {
            params.append(param, newVal);
        }
        url.search = params.toString();
        return url.toString();
    }
    catch (_a) {
        return;
    }
};
export const handleGoogleAuthError = (error) => {
    let errorMsg = "Authentication failed. Please clear your browser cookies and try again. If the problem persists, contact support for assistance.";
    if (error) {
        console.log(error);
        errorMsg += ` ${error}`;
    }
    dispatchNotificationEvent({
        message: errorMsg,
        type: "error",
    });
};
