export const inputStyle = (disabled) => {
    return {
        fontSize: "0.875rem",
        color: disabled ? "#808080" : "black",
    };
};
export const extractValuesFromStr = (strValue) => {
    const re = /^[-+]?[0-9]+(?::[0-9]+){1,2}$/;
    const matches = strValue.match(re);
    if (matches === null)
        return;
    const numbers = strValue.split(":").map(Number);
    let from;
    let to;
    let step;
    if (numbers.length === 2) {
        [from, to] = numbers;
    }
    else if (numbers.length === 3) {
        [from, step, to] = numbers;
    }
    if (from === undefined || to == undefined)
        return;
    return {
        from,
        to,
        step,
    };
};
export const numberIsValid = (num, minVal, maxVal) => {
    return num <= maxVal && num >= minVal;
};
