import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Grid, Typography, Fade, Breadcrumbs, Chip, Link, AppBar, } from "@mui/material";
import { leftPanel } from "../UIPanels/panelDimensions";
import { LoaderStatus } from "./LoaderStatus";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TargetResultButtons from "./ImageButton";
function AccessChip() {
    return (_jsx(Chip, { label: "Free access", color: "secondary", variant: "filled", sx: {
            color: "#027a48",
            backgroundColor: "#ecfdf3",
        } }));
}
function TargetHeader({ targetName }) {
    return (_jsxs(Grid, Object.assign({ container: true, sx: { pb: 2 }, direction: "row", justifyContent: "space-between", alignItems: "center" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsxs(Breadcrumbs, Object.assign({ "aria-label": "breadcrumb", separator: _jsx(ArrowForwardIosIcon, { fontSize: "small", sx: { color: "#b7b7b7" } }) }, { children: [_jsx(Typography, Object.assign({ sx: {
                                fontSize: "1.5rem",
                                color: "#b7b7b7",
                                fontWeight: 100,
                            } }, { children: "Targets" })), _jsx(Typography, Object.assign({ sx: {
                                fontSize: "4rem",
                                color: "black",
                            } }, { children: targetName })), " "] })) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(AccessChip, {}) }))] })));
}
export function TargetSummaryComp({ targetID, targetData, onResultSelection, disabled, show, }) {
    const dimensions = Object.assign(Object.assign({}, leftPanel), { width: `100vw `, backgroundColor: "white", p: 10, zIndex: 3 });
    const targetName = targetID && targetID.toLowerCase() in targetIDConversion
        ? targetIDConversion[targetID.toLowerCase()]
        : targetID;
    return (_jsx(Fade, Object.assign({ in: show }, { children: _jsx(Box, Object.assign({ sx: dimensions }, { children: targetName ? (_jsxs(Box, { children: [_jsx(TargetHeader, { targetName: targetName }), _jsx(Box, { children: targetData ? (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ sx: { pb: 6 } }, { children: _jsx(TargetSummaryData, { summary: targetData.summary, targetID: targetID }) })), _jsx(Box, { children: _jsx(TargetResultButtons, { datasets: targetData.datasets, onResultSelection: onResultSelection, disabled: disabled }) })] })) : targetData === null ? (_jsx(_Fragment, { children: "No data found for this target." })) : (_jsx(LoaderStatus, {})) }, "dataportal-summary-data"), _jsx(AppBar, Object.assign({ position: "fixed", sx: {
                            top: "auto",
                            bottom: 0,
                            backgroundColor: "white",
                            width: "100%",
                            py: 2,
                            boxShadow: "none",
                            alignItems: "flex-end",
                            pr: 3,
                        } }, { children: _jsx("img", { alt: "acellera", src: "/static/images/acellera.png", style: {
                                width: "7em",
                            } }) }))] })) : (_jsx(Box, { children: "Please select a target." })) })) }), "dataportal-summary"));
}
function TargetSummaryData({ summary, targetID, }) {
    console.log(targetID === null || targetID === void 0 ? void 0 : targetID.toLowerCase());
    return (_jsxs(Grid, Object.assign({ container: true, item: true, direction: "row", spacing: 1, justifyContent: "space-evenly", alignItems: "center" }, { children: [_jsx(Grid, Object.assign({ container: true, item: true, md: true, direction: "column", spacing: 1 }, { children: Object.entries(summary).map(([key, val]) => (_jsxs(Grid, Object.assign({ item: true, container: true, direction: "row", spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsxs(Typography, Object.assign({ sx: { fontWeight: "bold" } }, { children: [key, ": "] })) }), `dataportal-data-${key}-key`), _jsx(Grid, Object.assign({ item: true }, { children: key.toLowerCase() === "uniprot" ? (_jsx(Link, Object.assign({ href: `https://www.uniprot.org/uniprotkb/${val}`, underline: "hover", target: "_blank", rel: "noreferrer" }, { children: val }))) : (_jsx(Typography, { children: val })) }), `dataportal-data-${key}-val`)] }), `dataportal-data-${key}`))) })), _jsx(Grid, Object.assign({ item: true, md: true }, { children: !!targetID && (_jsx("center", { children: _jsx("img", { alt: "", src: `/static/images/${targetID.toLowerCase()}.png`, style: {
                            width: "25em",
                        } }) })) }))] })));
}
// function TargetResultButtons({
//   datasets,
//   onResultSelection,
//   disabled,
// }: {
//   datasets: TargetDataset[];
//   onResultSelection: (x: TargetDataset) => void;
//   disabled?: boolean;
// }) {
//   return (
//     <Grid
//       container
//       item
//       direction="row"
//       spacing={1}
//       justifyContent="space-evenly"
//       alignItems="center"
//     >
//       {datasets.map((dataType) => {
//         return (
//           <Grid
//             item
//             key={`dataportal-data-result-${dataType.name.replace(" ", "_")}`}
//           >
//             <Paper
//               sx={{
//                 padding: 3,
//                 textAlign: "center",
//                 backgroundColor: disabled ? "#f5f5f5" : "#f3f6f9",
//                 cursor: disabled ? "progress" : "pointer",
//               }}
//               onClick={() => {
//                 onResultSelection(dataType);
//               }}
//             >
//               {dataType["name"]}
//             </Paper>
//           </Grid>
//         );
//       })}
//     </Grid>
//   );
// }
const targetIDConversion = {
    "7bf9kp2er5": "TNF-α",
};
