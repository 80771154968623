import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * @author Timur Kuzhagaliyev <tim.kuzh@gmail.com>
 * @author Panagiotis Tourlas <p.tourlas@acellera.com>
 * @copyright 2022
 * @license MIT
 */
import { useContext } from "react";
import { useIntl } from "react-intl";
import { ChonkyIconName } from "../../types/icons.types";
import { getI18nId, I18nNamespace } from "../../util/i18n";
import { ChonkyIconContext } from "../../util/icon-helper";
import { makeGlobalChonkyStyles } from "../../util/styles";
export const FileListEmpty = (props) => {
    const { width, height } = props;
    const classes = useStyles();
    const ChonkyIcon = useContext(ChonkyIconContext);
    const style = {
        width,
        height,
    };
    const intl = useIntl();
    const emptyString = intl.formatMessage({
        id: getI18nId(I18nNamespace.FileList, "nothingToShow"),
        defaultMessage: "Nothing to show",
    });
    return (_jsx("div", Object.assign({ className: classes.fileListEmpty, style: style }, { children: _jsxs("div", Object.assign({ className: classes.fileListEmptyContent }, { children: [_jsx(ChonkyIcon, { icon: ChonkyIconName.folderOpen }), "\u00A0 ", emptyString] })) })));
};
const useStyles = makeGlobalChonkyStyles((theme) => ({
    fileListEmpty: {
        color: theme.palette.text.disabled,
        position: "relative",
        textAlign: "center",
        fontSize: "1.2em",
    },
    fileListEmptyContent: {
        transform: "translateX(-50%) translateY(-50%)",
        position: "absolute",
        left: "50%",
        top: "50%",
    },
}));
