import { PluginCommands } from "molstar/lib/mol-plugin/commands";
export function highlightStructure(molstar, cellRefs) {
    const focusRefs = cellRefs
        .map((cellRef) => {
        const cellToFocus = molstar.state.data.selectQ((q) => q.byRef(cellRef));
        return cellToFocus.length > 0 ? cellToFocus[0].transform.ref : undefined;
    })
        .filter((e) => e !== undefined);
    PluginCommands.Interactivity.Object.Highlight(molstar, {
        state: molstar.state.data,
        ref: focusRefs,
    });
}
export function clearHighlight(molstar) {
    PluginCommands.Interactivity.ClearHighlights(molstar);
}
/* TODO: SDF files render separate models, all must be passed for highlights to work */
