import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
import { Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
export const HelpTooltip = ({ title }) => (_jsx(Tooltip, Object.assign({ arrow: true, placement: "top", title: title }, { children: _jsx(HelpOutlineIcon, { sx: style }) })));
const style = {
    color: "#888a85",
    ":hover": { color: "secondary.main" },
    fontSize: 20,
    verticalAlign: "middle",
};
