// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import sort from "fast-sort";
import FuzzySearch from "fuzzy-search";
import { createSelector } from "@reduxjs/toolkit";
import { OptionIds } from "../action-definitions/option-ids";
import { SortOrder } from "../types/sort.types";
import { FileHelper } from "../util/file-helper";
// Raw selectors
export const selectInstanceId = (state) => state.instanceId;
export const selectExternalFileActionHandler = (state) => state.externalFileActionHandler;
export const selectFileActionMap = (state) => state.fileActionMap;
export const selectFileActionIds = (state) => state.fileActionIds;
export const selectFileActionData = (fileActionId) => (state) => selectFileActionMap(state)[fileActionId];
export const selectToolbarItems = (state) => state.toolbarItems;
export const selectContextMenuItems = (state) => state.contextMenuItems;
export const selectFolderChain = (state) => state.folderChain;
export const selectCurrentFolder = (state) => {
    const folderChain = selectFolderChain(state);
    const currentFolder = folderChain.length > 0 ? folderChain[folderChain.length - 1] : null;
    return currentFolder;
};
export const selectParentFolder = (state) => {
    const folderChain = selectFolderChain(state);
    const parentFolder = folderChain.length > 1 ? folderChain[folderChain.length - 2] : null;
    return parentFolder;
};
export const selectRawFiles = (state) => state.rawFiles;
export const selectFileMap = (state) => state.fileMap;
export const selectCleanFileIds = (state) => state.cleanFileIds;
export const selectFileData = (fileId) => (state) => fileId ? selectFileMap(state)[fileId] : null;
export const selectHiddenFileIdMap = (state) => state.hiddenFileIdMap;
export const selectHiddenFileCount = (state) => Object.keys(selectHiddenFileIdMap(state)).length;
export const selectFocusSearchInput = (state) => state.focusSearchInput;
export const selectSearchString = (state) => state.searchString;
export const selectSelectionMap = (state) => state.selectionMap;
export const selectSelectedFileIds = (state) => Object.keys(selectSelectionMap(state));
export const selectSelectionSize = (state) => selectSelectedFileIds(state).length;
export const selectIsFileSelected = (fileId) => (state) => !!fileId && !!selectSelectionMap(state)[fileId];
export const selectSelectedFiles = (state) => {
    const fileMap = selectFileMap(state);
    return Object.keys(selectSelectionMap(state)).map((id) => fileMap[id]);
};
export const selectSelectedFilesForAction = (fileActionId) => (state) => {
    const { fileActionMap } = state;
    const action = fileActionMap[fileActionId];
    if (!action || !action.requiresSelection)
        return undefined;
    return getSelectedFiles(state, action.fileFilter);
};
export const selectSelectedFilesForActionCount = (fileActionId) => (state) => { var _a; return (_a = getSelectedFilesForAction(state, fileActionId)) === null || _a === void 0 ? void 0 : _a.length; };
export const selectDisableSelection = (state) => state.disableSelection;
export const selectFileViewConfig = (state) => state.fileViewConfig;
export const selectSortActionId = (state) => state.sortActionId;
export const selectSortOrder = (state) => state.sortOrder;
export const selectOptionMap = (state) => state.optionMap;
export const selectOptionValue = (optionId) => (state) => selectOptionMap(state)[optionId];
export const selectThumbnailGenerator = (state) => state.thumbnailGenerator;
export const selectDoubleClickDelay = (state) => state.doubleClickDelay;
export const selectIsDnDDisabled = (state) => state.disableDragAndDrop;
export const selectClearSelectionOnOutsideClick = (state) => state.clearSelectionOnOutsideClick;
export const selectContextMenuMounted = (state) => state.contextMenuMounted;
export const selectContextMenuConfig = (state) => state.contextMenuConfig;
export const selectContextMenuTriggerFile = (state) => {
    var _a;
    const config = selectContextMenuConfig(state);
    if (!config || !config.triggerFileId)
        return null;
    const fileMap = selectFileMap(state);
    return (_a = fileMap[config.triggerFileId]) !== null && _a !== void 0 ? _a : null;
};
// Raw selectors
const getFileActionMap = (state) => state.fileActionMap;
const getOptionMap = (state) => state.optionMap;
const getFileMap = (state) => state.fileMap;
const getFileIds = (state) => state.fileIds;
const getCleanFileIds = (state) => state.cleanFileIds;
const getSortActionId = (state) => state.sortActionId;
const getSortOrder = (state) => state.sortOrder;
const getSearchString = (state) => state.searchString;
const _getLastClick = (state) => state.lastClick;
// Memoized selectors
const makeGetAction = (fileActionSelector) => createSelector([getFileActionMap, fileActionSelector], (fileActionMap, fileActionId) => fileActionId && fileActionMap[fileActionId]
    ? fileActionMap[fileActionId]
    : null);
const makeGetOptionValue = (optionId, defaultValue = undefined) => createSelector([getOptionMap], (optionMap) => {
    const value = optionMap[optionId];
    if (value === undefined) {
        return defaultValue;
    }
    return value;
});
const makeGetFiles = (fileIdsSelector) => createSelector([getFileMap, fileIdsSelector], (fileMap, fileIds) => fileIds.map((fileId) => fileId && fileMap[fileId] ? fileMap[fileId] : null));
const getSortedFileIds = createSelector([
    getFileIds,
    getSortOrder,
    makeGetFiles(getFileIds),
    makeGetAction(getSortActionId),
    makeGetOptionValue(OptionIds.ShowFoldersFirst, false),
], (fileIds, sortOrder, files, sortAction, showFolderFirst) => {
    if (!sortAction) {
        // We allow users to set the sort action ID to `null` if they want to use their
        // own sorting mechanisms instead of relying on Chonky built-in sort.
        return fileIds;
    }
    const prepareSortKeySelector = (selector) => (file) => selector(file);
    const sortFunctions = [];
    if (showFolderFirst) {
        // If option is undefined (relevant actions is not enabled), we don't show
        // folders first.
        sortFunctions.push({
            desc: prepareSortKeySelector(FileHelper.isDirectory),
        });
    }
    if (sortAction.sortKeySelector) {
        const configKeyName = sortOrder === SortOrder.ASC ? "asc" : "desc";
        sortFunctions.push({
            [configKeyName]: prepareSortKeySelector(sortAction.sortKeySelector),
        });
    }
    if (sortFunctions.length === 0)
        return fileIds;
    // We copy the array because `fast-sort` mutates it
    const sortedFileIds = sort([...files])
        .by(sortFunctions)
        .map((file) => (file ? file.id : null));
    return sortedFileIds;
});
const getSearcher = createSelector([makeGetFiles(getCleanFileIds)], (cleanFiles) => new FuzzySearch(cleanFiles, ["name"], {
    caseSensitive: false,
}));
const getSearchFilteredFileIds = createSelector([getCleanFileIds, getSearchString, getSearcher], (cleanFileIds, searchString, searcher) => searchString ? searcher.search(searchString).map((f) => f.id) : cleanFileIds);
const getHiddenFileIdMap = createSelector([
    getSearchFilteredFileIds,
    makeGetFiles(getCleanFileIds),
    makeGetOptionValue(OptionIds.ShowHiddenFiles),
], (searchFilteredFileIds, cleanFiles, showHiddenFiles) => {
    const searchFilteredFileIdsSet = new Set(searchFilteredFileIds);
    const hiddenFileIdMap = {};
    cleanFiles.forEach((file) => {
        if (!file)
            return;
        else if (!searchFilteredFileIdsSet.has(file.id)) {
            // Hidden by seach
            hiddenFileIdMap[file.id] = true;
        }
        else if (!showHiddenFiles && FileHelper.isHidden(file)) {
            // Hidden by options
            hiddenFileIdMap[file.id] = true;
        }
    });
    return hiddenFileIdMap;
});
const getDisplayFileIds = createSelector([getSortedFileIds, getHiddenFileIdMap], 
/** Returns files that will actually be shown to the user. */
(sortedFileIds, hiddenFileIdMap) => sortedFileIds.filter((id) => !id || !hiddenFileIdMap[id]));
const getLastClickIndex = createSelector([_getLastClick, getSortedFileIds], 
/** Returns the last click index after ensuring it is actually still valid. */
(lastClick, displayFileIds) => {
    if (!lastClick ||
        lastClick.index > displayFileIds.length - 1 ||
        lastClick.fileId != displayFileIds[lastClick.index]) {
        return null;
    }
    return lastClick.index;
});
export const selectors = {
    // Raw selectors
    getFileActionMap,
    getOptionMap,
    getFileMap,
    getFileIds,
    getCleanFileIds,
    getSortActionId,
    getSortOrder,
    getSearchString,
    _getLastClick,
    // Memoized selectors
    getSortedFileIds,
    getSearcher,
    getSearchFilteredFileIds,
    getHiddenFileIdMap,
    getDisplayFileIds,
    getLastClickIndex,
    // Parametrized selectors
    makeGetAction,
    makeGetOptionValue,
    makeGetFiles,
};
// Selectors meant to be used outside of Redux code
export const getFileData = (state, fileId) => fileId ? selectFileMap(state)[fileId] : null;
export const getIsFileSelected = (state, file) => {
    // !!! We deliberately don't use `FileHelper.isSelectable` here as we want to
    //     reflect the state of Redux store accurately.
    return !!selectSelectionMap(state)[file.id];
};
export const getSelectedFiles = (state, ...filters) => {
    const { fileMap, selectionMap } = state;
    const selectedFiles = Object.keys(selectionMap).map((id) => fileMap[id]);
    const filteredSelectedFiles = filters.reduce((prevFiles, filter) => (filter ? prevFiles.filter(filter) : prevFiles), selectedFiles);
    return filteredSelectedFiles;
};
export const getSelectedFilesForAction = (state, fileActionId) => selectSelectedFilesForAction(fileActionId)(state);
