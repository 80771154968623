var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { shallow } from "zustand/shallow";
import { useEffect, useState } from "react";
import { Box, IconButton, Divider, Paper, Typography, Tooltip, } from "@mui/material";
import { indexSelector, addReverseIndexing, trimText, } from "../..";
import CloseIcon from "@mui/icons-material/Close";
import { selectSystemByIndexArray, updateStateTreeRepresentation, } from "../stateTree";
import { RepresentationParamsSection, SelectVolumeParams, } from "./SelectRepresentationParam";
import { RepresentationListWithActions } from "./SystemRepresentations";
import { setVisibility } from "../VisibilityToggle";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { repsPanelWidth } from "../positionRepresentationsPanel";
import { copyActiveRepresentation } from "./copyActiveRepresentation";
// Representations panel for structural files (pdb, sdf, etc.)
function GeneralRepresentationControlsPanel({ vss, molstar, pyodide, activeSystemPosition, NAPGenericStore, }) {
    const [loaded_structures] = vss((state) => [state.loaded_structures], shallow);
    const activeSystem = activeSystemPosition
        ? selectSystemByIndexArray(activeSystemPosition, loaded_structures)
        : undefined;
    const [selectedRepID, setSelectedRepID] = useState(0);
    const [wait, setWait] = useState(false);
    // Visibility indicator in the UI.
    const strucRefLi = (activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.cellRef) ? activeSystem.cellRef : [];
    useEffect(() => {
        // When the active system changes, select the 1st representation and hide the "add representation" panel
        // Also when the cellRefs of the active system change
        if (activeSystemPosition !== undefined) {
            setSelectedRepID(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSystemPosition, strucRefLi.join(".")]);
    const selectedRepresentation = (activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.representations)
        ? activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.representations[selectedRepID]
        : undefined;
    const handleToggleRepVisibility = () => {
        if (!selectedRepresentation || !selectedRepresentation.refs)
            return;
        let repRef = selectedRepresentation.refs.map((ref) => ref.repRef);
        if (activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.visibleElementIDs) {
            // Handle the SDF case where only specific molecules are shown
            try {
                repRef = indexSelector(activeSystem.visibleElementIDs, addReverseIndexing(repRef));
            }
            catch (_a) { }
        }
        const _repRef = repRef.filter((e) => e !== undefined);
        const repIsHidden = selectedRepresentation.visibility === false;
        setVisibility(molstar, _repRef, repIsHidden);
        if (activeSystemPosition)
            updateStateTreeRepresentation(vss, activeSystemPosition, selectedRepID, {
                visibility: repIsHidden,
            });
    };
    const handleAddRepresentation = () => __awaiter(this, void 0, void 0, function* () {
        if (!activeSystem)
            return;
        setWait(true);
        const newRepIdx = yield copyActiveRepresentation(activeSystem, selectedRepID, molstar, pyodide, vss);
        if (newRepIdx === undefined) {
            setWait(false);
            return;
        }
        setSelectedRepID(newRepIdx);
        setWait(false);
    });
    return (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ sx: { mb: 3, mx: 2, mt: 2 } }, { children: _jsx(Box, Object.assign({ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", gap: 1 }, { children: _jsx(RepresentationListWithActions, { vss: vss, molstar: molstar, selectedRepID: selectedRepID, setSelectedRepID: setSelectedRepID, handleToggleRepVisibility: handleToggleRepVisibility, NAPGenericStore: NAPGenericStore, handleAddRepresentation: handleAddRepresentation, disabled: wait }) })) })), selectedRepresentation && (_jsx(RepresentationParamsSection, { vss: vss, pyodide: pyodide, molstar: molstar, moleculeID: activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.moleculeID, selectedRepID: selectedRepID, NAPGenericStore: NAPGenericStore, activeSystem: activeSystem, isSmallMol: (activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.type) === "sdf", disabled: wait }))] }));
}
export function RepresentationControlsPanel({ vss, molstar, pyodide, NAPGenericStore, }) {
    const [activeSystemPosition, activePanels, closeSystemRepresentations, representationsPanelPosition,] = NAPGenericStore((state) => [
        state._activeSystemPosition,
        state._activePanels,
        state.closeSystemRepresentations,
        state.representationsPanelPosition,
    ], shallow);
    // Close reps panel when systems panel is closed
    const show = activePanels.statetree;
    useEffect(() => {
        if (!show) {
            closePanel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);
    const [systemTree] = vss((state) => [state.loaded_structures], shallow);
    const activeSystem = activeSystemPosition
        ? selectSystemByIndexArray(activeSystemPosition, systemTree)
        : undefined;
    const closePanel = () => {
        closeSystemRepresentations();
    };
    useEffect(() => {
        function handleEsc(event) {
            if (event.key === "Escape") {
                closePanel();
            }
        }
        document.addEventListener("keydown", handleEsc);
        return () => {
            document.removeEventListener("keydown", handleEsc);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const systName = (activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.name) ? activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.name : "";
    return (
    // div ref={myRef}
    _jsx(Paper, Object.assign({ variant: "outlined", square: false, sx: { borderRadius: "6px" } }, { children: _jsxs(Box, Object.assign({ sx: {
                width: `${repsPanelWidth}px`,
                maxHeight: `calc(100vh - ${representationsPanelPosition.y}px)`,
                overflowY: "hidden",
                display: "flex",
                flexDirection: "column",
            }, gap: "0.25em" }, { children: [_jsxs(Box, Object.assign({ display: "flex", flexDirection: "column", gap: "0.25em" }, { children: [_jsx(Box, Object.assign({ className: "drag-handle", sx: {
                                cursor: "move",
                                width: "100%",
                                justifyContent: "center",
                                display: "flex",
                                backgroundColor: "rgba(0,0,0,0.04)",
                            } }, { children: _jsx(DragHandleIcon, { fontSize: "small" }) })), _jsxs(Box, Object.assign({ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", sx: { pl: "16px", pr: "8px" } }, { children: [_jsxs(Box, Object.assign({ display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(Typography, Object.assign({ sx: { fontWeight: "bold" }, display: "inline" }, { children: "Representations" })), _jsx(Typography, Object.assign({ sx: { mx: "8px", color: "rgba(0,0,0,0.5)" }, display: "inline" }, { children: "-" })), _jsx(Box, Object.assign({ sx: { maxWidth: "12em" } }, { children: systName.length > 15 ? (_jsx(Tooltip, Object.assign({ title: systName, arrow: true, enterDelay: 1000, placement: "top" }, { children: _jsx(Typography, { children: trimText(systName, 15, true) }) }))) : (_jsx(Typography, { children: systName })) }))] })), _jsx(IconButton, Object.assign({ size: "small", onClick: closePanel }, { children: _jsx(CloseIcon, { fontSize: "small" }) }))] }))] })), _jsx(Divider, {}), _jsx(Box, Object.assign({ sx: {
                        overflowY: "auto",
                        flexGrow: 1,
                    } }, { children: (activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.type) === "volume" ? (_jsx(SelectVolumeParams, { vss: vss, molstar: molstar, activeSystem: activeSystem, NAPGenericStore: NAPGenericStore })) : (_jsx(GeneralRepresentationControlsPanel, { vss: vss, pyodide: pyodide, molstar: molstar, activeSystemPosition: activeSystemPosition, NAPGenericStore: NAPGenericStore })) }))] })) })));
}
