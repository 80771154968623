// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
export var FileViewMode;
(function (FileViewMode) {
    FileViewMode["List"] = "list";
    FileViewMode["Compact"] = "compact";
    FileViewMode["Grid"] = "grid";
})(FileViewMode || (FileViewMode = {}));
