// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { create } from "zustand";
export const DataPortalStore = create()((set, get) => ({
    activeSession: undefined,
    completedSessions: [],
    targetSessions: {},
    setActiveSession: (sessionFile) => set({ activeSession: sessionFile }),
    addCompletedSession: (session) => set({
        completedSessions: [...get().completedSessions, session],
    }),
    setTargetSessions: (x) => set({ targetSessions: x }),
}));
