var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getDatasets } from "../../apps/results/fetchDcData";
import { DCBrowserStore } from "../../DatacenterBrowser";
export function getDatasetOptions(userId, datasetArgs, pyodide) {
    return __awaiter(this, void 0, void 0, function* () {
        const datasetParams = {
            tags: datasetArgs.tags ? datasetArgs.tags : undefined,
            taggedonly: datasetArgs.taggedonly ? datasetArgs.taggedonly : undefined,
            completedonly: datasetArgs.completedonly
                ? datasetArgs.completedonly[0] === true
                : undefined,
        };
        const datasets = yield getDatasets(datasetParams);
        if (!datasets)
            return;
        datasets.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
        let response = [];
        if ("include_none" in datasetArgs) {
            response.push({
                id: null,
                filepath: "None",
                comments: "No data",
                updated_at: "",
                filesize: "",
                public: true,
            });
        }
        const project = DCBrowserStore.getState().project;
        datasets.forEach((dataset) => {
            const filepath = dataset.filepath;
            const filepath_short = dataset.filepath.replace(`${userId}/${project}/`, "");
            const filesizePre = dataset.filesize;
            const fileSize = pyodide.RunPythonAsync({
                context: { filesizePre },
                script: `
        from js import filesizePre
        from playmolecule.utils import format_filesize
        format_filesize(filesizePre)
        `,
            });
            let ds_dict = {
                id: dataset.id,
                execid: dataset.execid,
                filepath: filepath_short,
                comments: dataset.comments,
                updated_at: new Date(dataset.updated_at).toISOString(),
                filesize: fileSize,
                public: dataset.public,
            };
            if ("default" in datasetArgs) {
                const argsDefault = typeof datasetArgs.default === "string"
                    ? datasetArgs.default
                    : datasetArgs.default[0];
                if (filepath === argsDefault) {
                    response.unshift(ds_dict);
                    return;
                }
            }
            if (datasetArgs.filters &&
                !datasetArgs.filters.some((filter) => new RegExp(filter).test(filepath))) {
                return;
            }
            response.push(ds_dict);
        });
        return response;
    });
}
