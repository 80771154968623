import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Fade, IconButton } from "@mui/material";
import { leftPanel } from "../UIPanels/panelDimensions";
import { docsSections } from "../Documentation/constants/constants";
import { DocsSectionApps } from "../Documentation/Documentation";
import CloseIcon from "@mui/icons-material/Close";
export function AppDocs({ appName, appDocsIsOpen, closeAppDocs, }) {
    var _a;
    const boxStyle = {
        top: 0,
        position: "fixed",
        display: "block",
        left: (theme) => `calc(${theme.spacing(7)} + ${leftPanel.width})`,
        width: (theme) => `calc(100vw - ${theme.spacing(7)} - ${leftPanel.width})`,
        height: `calc(${leftPanel.height} - 1.75em)`,
        zIndex: 1,
        backgroundColor: "white",
        overflowY: "auto",
        overflowX: "auto",
        borderLeft: "1px solid #DCDCDC",
    };
    const docsApps = (_a = docsSections.find((d) => d.name === "Applications")) === null || _a === void 0 ? void 0 : _a.subSections;
    const appDocs = docsApps === null || docsApps === void 0 ? void 0 : docsApps.find((d) => d.name.toLowerCase() === appName.toLowerCase());
    return (_jsx(Fade, Object.assign({ in: appDocsIsOpen && !!appDocs }, { children: _jsx(Box, Object.assign({ sx: boxStyle, id: "appDocs" }, { children: _jsxs(Box, Object.assign({ sx: { p: 4 } }, { children: [_jsx(Box, Object.assign({ display: "flex", justifyContent: "flex-end" }, { children: _jsx(IconButton, Object.assign({ color: "primary", onClick: closeAppDocs }, { children: _jsx(CloseIcon, {}) })) })), appDocs ? (_jsx(DocsSectionApps, Object.assign({}, appDocs, { showCitationButton: false }))) : null] })) })) })));
}
