// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState, useEffect, useRef } from "react";
export const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);
    return [debouncedValue, setDebouncedValue];
};
const UNINITIALIZED_SENTINEL = {};
export const useStaticValue = (factory) => {
    const valueRef = useRef(UNINITIALIZED_SENTINEL);
    if (valueRef.current === UNINITIALIZED_SENTINEL)
        valueRef.current = factory();
    return valueRef.current;
};
export const useInstanceVariable = (value) => {
    const ref = useRef(value);
    useEffect(() => {
        ref.current = value;
    }, [ref, value]);
    return ref;
};
