// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { create } from "zustand";
import { NAPGenericStore } from "../GenericViewerState";
import { executeWithTokenRefresh } from ".";
import { dispatchNotificationEvent } from "../NotificationSystem/utils";
import { dispatchCompleteUserInfoEvent } from "./CompleteUserInfoDialog";
/*
  TODO: I am under the impression this state store is redundant.
  May the reader of this statement reach a similar conclusion and do the dirty
  work on my behalf.
*/
export const userAuthStore = create()((set, get) => ({
    waitUserInfo: true,
    userInfo: undefined,
    login: (email, password) => __awaiter(void 0, void 0, void 0, function* () {
        const apiCallFactory = () => {
            return fetch(`${window.location.origin}/v2/auth/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    mail: email,
                    password: password,
                }),
            });
        };
        const response = yield executeWithTokenRefresh(apiCallFactory, true);
        if (!response.success) {
            return;
        }
        const respJson = yield response.result.json();
        return respJson.data;
    }),
    logout: (verbose) => __awaiter(void 0, void 0, void 0, function* () {
        const apiCallFactory = () => {
            return fetch(`${window.location.origin}/v2/auth/logout`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
        };
        const response = yield executeWithTokenRefresh(apiCallFactory, true);
        if (!response.success) {
            console.error("Could not log out");
            return;
        }
        if (window.highPriorityMsg)
            window.highPriorityMsg({ type: "stop-job-polling" });
        NAPGenericStore.getState().resetActivePanels();
        set({ userInfo: undefined });
        if (verbose)
            dispatchNotificationEvent({
                message: "You are logged out.",
                type: "info",
            });
    }),
    getUserInfo: () => __awaiter(void 0, void 0, void 0, function* () {
        const apiCallFactory = () => {
            return fetch(`${window.location.origin}/v2/auth/user-info`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
        };
        const response = yield executeWithTokenRefresh(apiCallFactory, true);
        let userData;
        if (response.success) {
            const resp = yield response.result.json();
            userData = {
                email: resp.data.email,
                username: resp.data.username,
                userId: resp.data.user_id,
                country: resp.data.country,
            };
        }
        return userData;
    }),
    setUserInfo: () => __awaiter(void 0, void 0, void 0, function* () {
        const userData = yield get().getUserInfo();
        set({ userInfo: userData, waitUserInfo: false });
        return userData;
    }),
    addMissingDataAndSetUserInfo: () => __awaiter(void 0, void 0, void 0, function* () {
        let userInfo = yield get().getUserInfo();
        if (userInfo) {
            const userDetailsAvailable = userInfo.country;
            if (!userDetailsAvailable) {
                const success = yield dispatchCompleteUserInfoEvent();
                if (!success) {
                    userInfo = undefined;
                }
            }
        }
        set({ userInfo: userInfo, waitUserInfo: false });
        return userInfo;
    }),
    startWorkerCalls: (userId, project) => {
        window.highPriorityMsg({
            type: "start-job-polling",
            userId: userId,
            url: window.location.origin,
            project: project,
        });
        const extraApps = process.env.REACT_APP_EXTRA_APPS
            ? JSON.parse(process.env.REACT_APP_EXTRA_APPS)
            : [];
        window.highPriorityMsg({
            type: "fetch-app-list",
            url: window.location.origin,
            appIds: [
                "acepockets",
                "membranebuilder",
                "systembuilder",
                "kdeeptrainer",
                "deepsite",
                "kdeep",
                "kdeep2d",
                "acedock",
                "aceprep",
                "aceprofiler",
                "proteinprepare",
                "simplerun",
                "parameterize",
                "generative",
                "pkace",
                "plexview",
                "pathwaymap",
                ...extraApps,
            ],
        });
        window.highPriorityMsg({
            type: "fetch-datasets",
            userId: userId,
            url: window.location.origin,
            project: project,
            setCurrentDir: true,
        });
    },
    onSuccessfulLogin: (project, closeCallback) => __awaiter(void 0, void 0, void 0, function* () {
        /**
         * Once the login request succeeds, this function:
         * 1. starts "job polling/app manifest fetching" worker
         * 2. (if passed) triggers an appropriate callback function
         */
        const userInfo = yield get().setUserInfo();
        const userId = userInfo === null || userInfo === void 0 ? void 0 : userInfo.userId;
        if (!userId)
            return;
        get().startWorkerCalls(userId, project);
        if (closeCallback)
            closeCallback();
    }),
}));
