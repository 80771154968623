import { docsViewerSections } from "./viewerConstants";
import { docsAppsSections } from "./applicationsConstants";
import { docsPolicies } from "./policiesConstants";
export const docsSections = [
    {
        name: "Viewer",
        subSections: docsViewerSections,
    },
    { name: "Policies", subSections: docsPolicies },
    {
        name: "Applications",
        subSections: docsAppsSections,
        //   subSections: appList.filter((e) =>
        //     currentApps.includes(e.name)
        //   ) as AppManifest[],
    },
];
export const pmvCitation = {
    url: "https://pubs.acs.org/doi/10.1021/acs.jcim.3c01776",
    bibtex: `
@article{doi:10.1021/acs.jcim.3c01776,
author = {Torrens-Fontanals, Mariona and Tourlas, Panagiotis and Doerr, Stefan and De Fabritiis, Gianni},
title = {PlayMolecule Viewer: A Toolkit for the Visualization of Molecules and Other Data},
journal = {Journal of Chemical Information and Modeling},
volume = {64},
number = {3},
pages = {584-589},
year = {2024},
doi = {10.1021/acs.jcim.3c01776},
    note ={PMID: 38266194},  
URL = {https://doi.org/10.1021/acs.jcim.3c01776},
eprint = {https://doi.org/10.1021/acs.jcim.3c01776}
}  
`,
};
