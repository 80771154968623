import { isEmptyObject, posIsHighlighted } from "./utils";
import { detailedDiff } from "deep-object-diff";
export const areSequencePropsEqual = (oldProps, newProps) => {
    const diffSelectedSystResidues = detailedDiff(oldProps.selectedSystResidues, newProps.selectedSystResidues);
    const selectedSystResiduesIsEqual = isEmptyObject(diffSelectedSystResidues.added) &&
        isEmptyObject(diffSelectedSystResidues.deleted) &&
        isEmptyObject(diffSelectedSystResidues.updated);
    return (oldProps.cellRef === newProps.cellRef &&
        oldProps.name === newProps.name &&
        oldProps.isVisible === newProps.isVisible &&
        oldProps.seqIsClicked === newProps.seqIsClicked &&
        !!oldProps.molstar === !!newProps.molstar &&
        selectedSystResiduesIsEqual);
};
export const areSegmentPropsEqual = (oldProps, newProps) => {
    if (oldProps.cellRef !== newProps.cellRef)
        return false;
    //mouseDownPosId
    if (oldProps.mouseDownPosId !== newProps.mouseDownPosId) {
        return false;
    }
    else if (newProps.mouseDownPosId !== undefined) {
        return false;
    }
    //lastMouseOverPosition
    if (typeof oldProps.lastMouseOverPosition !==
        typeof newProps.lastMouseOverPosition) {
        return false;
    }
    else if (oldProps.lastMouseOverPosition) {
        //same type and not undefined: check if the old or the new chain correspond to this chain
        if (oldProps.lastMouseOverPosition.chain === oldProps.chain ||
            newProps.lastMouseOverPosition.chain === newProps.chain)
            return false;
    }
    //selectedChainResidues
    if (typeof oldProps.selectedChainResidues !==
        typeof newProps.selectedChainResidues) {
        return false;
    }
    else if (oldProps.selectedChainResidues) {
        //same type and not undefined: check if the array has changed
        return false;
    }
    return true;
};
export const arePositionPropsEqual = (oldProps, newProps) => {
    if (oldProps.cellRef !== newProps.cellRef)
        return false;
    if (!!oldProps.selected !== !!newProps.selected)
        return false;
    if (oldProps.lastMouseOverPosId !== newProps.lastMouseOverPosId ||
        oldProps.mouseDownPosId !== newProps.mouseDownPosId) {
        const highlightedOld = posIsHighlighted(oldProps.mouseDownPosId, oldProps.lastMouseOverPosId, oldProps.positionId);
        const highlightedNew = posIsHighlighted(newProps.mouseDownPosId, newProps.lastMouseOverPosId, newProps.positionId);
        if (highlightedOld !== highlightedNew)
            return false;
    }
    return true;
};
