import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * @author Timur Kuzhagaliyev <tim.kuzh@gmail.com>
 * @author Panagiotis Tourlas <p.tourlas@acellera.com>
 * @copyright 2022
 * @license MIT
 */
import { useDragLayer } from "react-dnd";
import { ChonkyDndFileEntryType, } from "../../types/dnd.types";
import { makeGlobalChonkyStyles } from "../../util/styles";
const layerStyles = {
    position: "fixed",
    pointerEvents: "none",
    zIndex: 100,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
};
const getItemStyles = (initialCursorOffset, initialFileOffset, currentFileOffset) => {
    if (!initialCursorOffset || !initialFileOffset || !currentFileOffset) {
        return {
            display: "none",
        };
    }
    const x = initialCursorOffset.x + (currentFileOffset.x - initialFileOffset.x);
    const y = initialCursorOffset.y + (currentFileOffset.y - initialFileOffset.y);
    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform,
    };
};
export const DnDFileListDragLayer = () => {
    const classes = useStyles();
    const { itemType, item, initialCursorOffset, initialFileOffset, currentFileOffset, isDragging, } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialCursorOffset: monitor.getInitialClientOffset(),
        initialFileOffset: monitor.getInitialSourceClientOffset(),
        currentFileOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));
    if (!isDragging || itemType !== ChonkyDndFileEntryType || !item.payload) {
        return null;
    }
    const selectionSize = item.payload.selectedFiles.length;
    return (_jsx("div", Object.assign({ style: layerStyles }, { children: _jsx("div", Object.assign({ style: getItemStyles(initialCursorOffset, initialFileOffset, currentFileOffset) }, { children: _jsxs("div", Object.assign({ className: classes.fileDragPreview }, { children: [_jsx("b", { children: item.payload.draggedFile.name }), selectionSize > 1 && (_jsxs(_Fragment, { children: [" and ", _jsxs("strong", { children: [selectionSize - 1, " other file", selectionSize - 1 !== 1 ? "s" : ""] })] }))] })) })) })));
};
const useStyles = makeGlobalChonkyStyles((theme) => ({
    fileDragPreview: {
        boxShadow: `2px 2px 5px ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.dragLayer.borderRadius,
        fontSize: theme.fontSizes.rootPrimary,
        color: theme.palette.text.primary,
        padding: theme.dragLayer.padding,
        border: theme.dragLayer.border,
        display: "inline-block",
    },
}));
