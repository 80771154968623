import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Collapse, ListItem, ListItemButton, ListItemText, List, } from "@mui/material";
import { useState } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useLocation } from "wouter";
export function NestedMenuSection({ name, subSections, desktopSections, }) {
    const [open, setOpen] = useState(true);
    const handleClickSection = () => {
        setOpen(!open);
    };
    const excludedInDesktop = ["Tutorial"];
    const excludedInWeb = ["End user license agreement"];
    const _desktopSections = desktopSections
        ? //@ts-ignore
            subSections.filter((subs) => !excludedInDesktop.includes(subs.name))
        : //@ts-ignore
            subSections.filter((subs) => !excludedInWeb.includes(subs.name));
    const [location, setLocation] = useLocation();
    return (_jsxs(_Fragment, { children: [_jsx(ListItem, Object.assign({ disablePadding: true }, { children: _jsxs(ListItemButton, Object.assign({ onClick: handleClickSection }, { children: [_jsx(ListItemText, { primary: name, primaryTypographyProps: {
                                variant: "body2",
                            } }), open ? _jsx(ExpandLess, {}) : _jsx(ExpandMore, {})] })) })), _jsx(Collapse, Object.assign({ in: open, timeout: "auto", unmountOnExit: true }, { children: _jsx(List, Object.assign({ component: "div", disablePadding: true }, { children: _desktopSections.map((subSect) => {
                        const sectId = subSect.id.toLowerCase();
                        const sectionLocation = `/docs/${sectId}`;
                        return (_jsx(ListItem, Object.assign({ disablePadding: true }, { children: _jsx(ListItemButton, Object.assign({ sx: { pl: 4 }, selected: location === sectionLocation, onClick: () => {
                                    setLocation(sectionLocation);
                                } }, { children: _jsx(ListItemText, { primary: subSect.short_name ? subSect.short_name : subSect.name, primaryTypographyProps: {
                                        variant: "body2",
                                    } }) })) }), sectId));
                    }) })) }))] }));
}
