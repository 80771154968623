import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogTitle, DialogContent, IconButton, } from "@mui/material";
import { CSVtable } from "../CSVReader/CSVReader";
import { dsInfoFromId } from "../apps/results/utils";
import { getRawFileContent } from "../apps/results/fetchDcData";
import { Buffer } from "buffer";
export function FileContentPanel({ NAPGenericStore, pyodide }) {
    const [open, setOpen, fileId, setFileId, localFilePath, setLocalFilePath] = NAPGenericStore((state) => [
        state._openFileContentPanel,
        state.setOpenFileContentPanel,
        state._contentPanelFileId,
        state.setContentPanelFileId,
        state._contentPanelLocalFilePath,
        state.setContentPanelLocalFilePath,
    ], shallow);
    const [fileContent, setFileContent] = useState("");
    const [fileType, setFileType] = useState("");
    const [fileName, setFileName] = useState("");
    const defaultDimensions = {
        width: "80%",
        height: "80%",
    };
    const [_dialogDimensions, _setDialogDimensions] = useState(defaultDimensions);
    const [hasLoaded, setHasLoaded] = useState(false);
    const setDialogDimensions = ({ target: img }) => {
        const { naturalHeight, naturalWidth } = img;
        _setDialogDimensions({
            height: naturalHeight > 1000 ? window.innerHeight * 0.8 : naturalHeight,
            width: naturalWidth > 1000 ? window.innerWidth * 0.8 : naturalWidth,
        });
    };
    const resetWindow = () => {
        setOpen(false);
        setTimeout(() => {
            _setDialogDimensions(defaultDimensions);
            setFileContent("");
            setFileType("");
            setFileId("");
            setFileName("");
            setLocalFilePath("");
        }, 200);
    };
    function setContentDetails(data, fileName) {
        const isBin = fileName.endsWith(".png");
        let finalData = data;
        if (isBin) {
            const buffer = data ? Buffer.from(data, "binary") : undefined;
            finalData = buffer ? buffer.toString("base64") : undefined;
        }
        const file_extension = fileName.split(".").pop();
        setFileContent(finalData);
        setFileType(file_extension);
        setHasLoaded(true);
        setFileName(fileName);
    }
    useEffect(() => {
        if (fileId) {
            const { files } = dsInfoFromId(fileId);
            const fileName = files && files.length > 0 ? files[0] : "";
            const isBin = fileName.endsWith(".png");
            getRawFileContent(fileId, pyodide, isBin).then((data) => {
                setContentDetails(data, fileName);
            });
        }
        if (localFilePath) {
            const fileName = localFilePath.split("/").pop();
            //@ts-ignore
            const wIPCR = window.ipcr;
            if (!wIPCR)
                return;
            wIPCR.invoke("read-local-file", localFilePath).then((fileContent) => {
                if (!fileContent)
                    return;
                setContentDetails(fileContent.data, fileName);
            });
        }
    }, [fileId, localFilePath, pyodide]);
    return (_jsxs(Dialog, Object.assign({ open: open, PaperProps: {
            sx: {
                minWidth: _dialogDimensions.width,
                height: _dialogDimensions.height + 150,
            },
        }, onClose: resetWindow }, { children: [_jsxs(DialogTitle, { children: [_jsx(Box, Object.assign({ display: "flex", justifyContent: "flex-end", sx: { py: 0 } }, { children: _jsx(IconButton, Object.assign({ color: "primary", onClick: resetWindow }, { children: _jsx(CloseIcon, {}) })) })), fileName] }), _jsx(DialogContent, { children: hasLoaded && (_jsx(FileViewer, { type: fileType, content: fileContent, onImgLoad: setDialogDimensions })) })] })));
}
function FileViewer({ type, content, onImgLoad }) {
    if (type === "png")
        return (_jsx(Box, Object.assign({ display: "flex", justifyContent: "center" }, { children: _jsx("img", { style: { width: "100%", height: "auto" }, alt: "", src: `data:image/${type};base64,${content}`, onLoad: onImgLoad }) })));
    if (type === "svg") {
        return (_jsx(Box, Object.assign({ display: "flex", justifyContent: "center" }, { children: _jsx("img", { style: { width: "100%", height: "auto" }, alt: "", src: `data:image/svg+xml;utf8,${encodeURIComponent(content)}`, onLoad: onImgLoad }) })));
    }
    if (type === "csv")
        return (_jsx(Box, Object.assign({ display: "flex", justifyContent: "center" }, { children: _jsx(CSVtable, { data: content }) })));
    return _jsx("pre", { children: content });
}
