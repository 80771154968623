import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Typography, Box } from "@mui/material";
export function HiglightedText({ children, }) {
    return (_jsx(Typography, Object.assign({ variant: "body2", display: "inline" }, { children: children })));
}
export function ItalicsBoldText({ children, }) {
    return (_jsx(Box, Object.assign({ component: "span", sx: { fontStyle: "italic", fontWeight: 700 } }, { children: children })));
}
export function Paragraph({ children, }) {
    return _jsx(Box, Object.assign({ sx: { mb: 2 } }, { children: children }));
}
export function MonoSpaceText({ children, }) {
    return (_jsx(Typography, Object.assign({ sx: { fontFamily: "Monospace" }, display: "inline" }, { children: children })));
}
export function CodeBlock({ children }) {
    return (_jsx(Box, Object.assign({ sx: { pl: 2 } }, { children: _jsx(Typography, Object.assign({ sx: { fontFamily: "Monospace" }, display: "inline" }, { children: children })) })));
}
export function DocsText({ children }) {
    return (_jsx(Typography, Object.assign({ sx: {
            lineHeight: "1.7em",
            "& .MuiSvgIcon-root": { fontSize: "1.2em", color: "#666666" },
        } }, { children: children })));
}
