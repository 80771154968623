// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { useStaticValue } from "../util/hooks-helpers";
import { rootReducer } from "./reducers";
import { initialRootState } from "./state";
import { useStoreWatchers } from "./watchers";
export const useChonkyStore = (chonkyInstanceId) => {
    const store = useStaticValue(() => {
        const preloadedState = Object.assign(Object.assign({}, initialRootState), { instanceId: chonkyInstanceId });
        return configureStore({
            preloadedState: preloadedState,
            reducer: rootReducer,
            middleware: (getDefaultMiddleware) => getDefaultMiddleware({
                serializableCheck: false,
            }),
            devTools: { name: `chonky_${chonkyInstanceId}` },
        });
    });
    useStoreWatchers(store);
    return store;
};
/**
 * Hook that can be used with parametrized selectors.
 */
export const useParamSelector = (parametrizedSelector, ...selectorParams) => {
    const selector = useCallback((state) => parametrizedSelector(...selectorParams)(state), 
    // eslint-disable-next-line
    [parametrizedSelector, ...selectorParams]);
    return useSelector(selector);
};
/**
 * DTE - DispatchThunkEffect. This method is used to decrease code duplication in
 * main Chonky method.
 */
export const useDTE = (actionCreator, ...selectorParams) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actionCreator(...selectorParams));
    }, 
    // eslint-disable-next-line
    [dispatch, actionCreator, ...selectorParams]);
};
export const usePropReduxUpdate = (actionCreator, payload) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actionCreator(payload));
    }, [dispatch, actionCreator, payload]);
};
