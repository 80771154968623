// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { defineFileAction, ChonkyIconName, ChonkyActions, } from "..";
export const viewInTable = defineFileAction({
    id: "view_selection_in_table",
    button: {
        name: "View in Table",
        toolbar: false,
        contextMenu: true,
        icon: ChonkyIconName.list,
    },
});
export const sendToViewer = defineFileAction({
    id: "show_raw_file_content",
    button: {
        name: "Raw file content",
        toolbar: false,
        contextMenu: true,
        icon: ChonkyIconName.file,
    },
});
export const fileDetails = defineFileAction({
    id: "view_file_details",
    button: {
        name: "Details...",
        toolbar: false,
        contextMenu: true,
        icon: ChonkyIconName.info,
    },
});
export const deleteFolder = defineFileAction({
    id: "delete_folder",
    button: {
        name: "Delete",
        toolbar: false,
        contextMenu: true,
        icon: ChonkyIconName.trash,
    },
});
export const renameFolder = defineFileAction({
    id: "rename_folder",
    button: {
        name: "Rename",
        toolbar: false,
        contextMenu: true,
        icon: ChonkyIconName.terminal,
    },
});
export const addFile = defineFileAction({
    id: "add_file",
    button: {
        name: "Add File",
        toolbar: true,
        contextMenu: false,
        icon: ChonkyIconName.upload,
    },
});
export const renderBatch = defineFileAction({
    id: "render_batch",
    button: {
        name: "Render PDB Batch",
        toolbar: true,
        contextMenu: true,
        icon: ChonkyIconName.model,
    },
});
export const renderAll = defineFileAction({
    id: "render_all",
    button: {
        name: "View",
        toolbar: true,
        contextMenu: true,
        icon: ChonkyIconName.model,
    },
    // customVisibility: () => CustomVisibilityState.Disabled,
});
export const deleteJob = defineFileAction({
    id: "deleteJob",
    requiresSelection: true,
    fileFilter: (file) => /^dc:\/\/\d+$/.test(file.id),
    button: {
        name: "Delete job",
        // toolbar: true,
        contextMenu: true,
        icon: ChonkyIconName.trash,
    },
    // customVisibility: () => CustomVisibilityState.Disabled,
});
// export const downloadFiles = defineFileAction({
//   id: "downloadFiles",
//   requiresSelection: true,
//   // fileFilter: (file: any) => file && file.name.endsWith(".ckpt"),
//   button: {
//     name: "Download",
//     toolbar: true,
//     contextMenu: true,
//     icon: ChonkyIconName.download,
//   },
//   // customVisibility: () => CustomVisibilityState.Disabled,
// } as const);
export const customFileActions = {
    // renderAll: renderAll,
    deleteJob: deleteJob,
    // downloadFiles: downloadFiles,
    // renderBatch: renderBatch,
    // viewInTable: viewInTable,
    // sendToViewer: sendToViewer,
    // fileDetails: fileDetails,
    // deleteFolder: deleteFolder,
    // renameFolder: renameFolder,
    // addFile: addFile,
};
export const actionsToDisable = [
    ChonkyActions.OpenSelection.id,
    ChonkyActions.ClearSelection.id,
];
