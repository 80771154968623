import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import "../static/css/LandingPage.css";
import { useState } from "react";
import { AppBar, Box, Button, CircularProgress, IconButton, Toolbar, } from "@mui/material";
//@ts-ignore
import logo_black from "../static/images/pm_logo_black.webp";
//@ts-ignore
import discord_logo from "../static/images/LandingPage/discord-round-icon.webp";
//@ts-ignore
import x_logo from "../static/images/LandingPage/X_icon.png";
import { DiscordInvitationPanel } from "../LogosImages/DiscordInvitationPanel";
import { useLocation } from "wouter";
import { userAuthStore } from "../Authentication/userAuthState";
import { shallow } from "zustand/shallow";
import { UserOptions } from "../Authentication/UserOptions/UserOptionsMenu";
function NavUserOptions() {
    const [, setLocation] = useLocation();
    const [logout, userInfo, waitUserInfo] = userAuthStore((state) => [state.logout, state.userInfo, state.waitUserInfo], shallow);
    const isAuthenticated = !!userInfo;
    const handleLogoutClick = () => {
        logout();
    };
    return (_jsx(_Fragment, { children: !isAuthenticated ? (_jsx(Button, Object.assign({ disabled: waitUserInfo, variant: "contained", sx: { width: waitUserInfo ? "13rem" : "11rem", ml: 1 }, color: "secondary", onClick: () => {
                setLocation("/login?from=/landing");
            }, endIcon: waitUserInfo ? _jsx(CircularProgress, { size: 15 }) : null }, { children: "Log In / Register" }))) : (_jsx(UserOptions, { userInfo: userInfo, handleLogoutClick: handleLogoutClick, landingPage: true })) }));
}
export function LandingPageNavigation() {
    const [discordPanel, setDiscordPanel] = useState(false);
    return (_jsxs(AppBar, Object.assign({ component: "nav", color: "transparent", elevation: 0, sx: {
            backgroundColor: "rgba(255, 255, 255, 0.5)",
        } }, { children: [_jsx(Toolbar, Object.assign({ sx: { "&.MuiToolbar-root": { pl: 0 } } }, { children: _jsxs(Box, Object.assign({ sx: {
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                    } }, { children: [_jsx(Box, { children: _jsx("img", { id: "logotype", style: {
                                    opacity: "0.9",
                                    width: "30px",
                                }, src: logo_black }) }), _jsx(Box, Object.assign({ sx: { marginLeft: "auto" } }, { children: _jsx(IconButton, Object.assign({ color: "inherit", onClick: () => {
                                    setDiscordPanel(true);
                                } }, { children: _jsx("img", { src: discord_logo, style: {
                                        height: "30px",
                                    }, alt: "discord" }) })) })), _jsx(Box, { children: _jsx(IconButton, Object.assign({ color: "inherit", href: "https://twitter.com/PlayMolecule", target: "_blank", rel: "noopener noreferrer" }, { children: _jsx("img", { src: x_logo, style: {
                                        height: "30px",
                                    }, alt: "discord" }) })) }), _jsx(Box, Object.assign({ sx: { justifyItems: "flex-end" } }, { children: _jsx(NavUserOptions, {}) }))] })) })), _jsx(DiscordInvitationPanel, { open: discordPanel, setOpen: setDiscordPanel })] })));
}
