// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect } from "react";
import watch from "redux-watch";
import { ChonkyActions } from "../action-definitions";
import { selectSelectedFileIds, selectSelectionMap } from "./selectors";
import { thunkRequestFileAction } from "./thunks/dispatchers.thunks";
export const useStoreWatchers = (store) => {
    useEffect(() => {
        const selectionWatcher = watch(() => selectSelectionMap(store.getState()));
        const onSelectionChange = (newSelection, oldSelection) => {
            // We don't check for deep equality here as we expect the
            // reducers to prevent all unnecessary updates.
            if (newSelection === oldSelection)
                return;
            // Notify users the selection has changed.
            const selectedFilesIds = selectSelectedFileIds(store.getState());
            const selection = new Set(selectedFilesIds);
            store.dispatch(thunkRequestFileAction(ChonkyActions.ChangeSelection, {
                selection,
            }));
        };
        const unsubscribeCallbacks = [
            store.subscribe(selectionWatcher(onSelectionChange)),
        ];
        return () => {
            for (const unsubscribe of unsubscribeCallbacks)
                unsubscribe();
        };
    }, [store]);
};
