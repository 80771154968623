import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { shallow } from "zustand/shallow";
import { useState, useEffect } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, } from "@mui/material";
import { DatacenterBrowser } from "./MainComponent";
import { ActiveDataset, DCBrowserStore } from "./datacenterBrowser.store";
export function FileBrowserDialog({ vss, molstar, pyodide, pyodideReady, NAPGenericStore, }) {
    const [openFileBrowserDialog, setOpenFileBrowserDialog] = NAPGenericStore((state) => [state._openFileBrowserDialog, state.setOpenFileBrowserDialog], shallow);
    const [selectedFiles, setSelectedFiles, getActiveDatasetEntries] = DCBrowserStore((state) => [
        state.selectedFiles,
        state.setSelectedFiles,
        state.getActiveDatasetEntries,
    ], shallow);
    const datacenterEntries = getActiveDatasetEntries();
    const defaults = {
        resolve: () => { },
        filterExtension: [],
        dialogTitle: "",
        dataset: ActiveDataset.user,
        dialogNotReady: true,
    };
    const [dialogProps, setDialogProps] = useState(defaults);
    const closeAndReturnUndefined = () => {
        dialogProps.resolve(undefined);
        setOpenFileBrowserDialog(false);
        setSelectedFiles([]);
        setDialogProps(defaults);
    };
    const closeAndReturnFileData = (file) => {
        dialogProps.resolve(file);
        setOpenFileBrowserDialog(false);
        setSelectedFiles([]);
        setDialogProps(defaults);
    };
    const closeAndReturnSelectedFileData = () => {
        const [selectedFileID] = selectedFiles;
        const selFile = datacenterEntries.fileMap[selectedFileID];
        closeAndReturnFileData(selFile);
    };
    useEffect(() => {
        const _cb = (e) => {
            const { resolve, filterExtension, dialogTitle, dataset } = e.detail;
            setDialogProps({
                resolve: resolve,
                dialogTitle: dialogTitle,
                filterExtension: filterExtension,
                dataset: dataset ? dataset : ActiveDataset.user,
                dialogNotReady: false,
            });
            setOpenFileBrowserDialog(true);
        };
        window.addEventListener("PM-FileBrowserDialog", _cb);
        return () => {
            window.removeEventListener("PM-FileBrowserDialog", _cb);
        };
    }, [setOpenFileBrowserDialog]);
    return (_jsxs(Dialog, Object.assign({ open: openFileBrowserDialog, PaperProps: { sx: { minWidth: 800, height: "80%" } } }, { children: [dialogProps.dialogTitle ? (_jsx(DialogTitle, { children: dialogProps.dialogTitle })) : null, _jsx(DialogContent, { children: !dialogProps.dialogNotReady && (_jsx(DatacenterBrowser, { dialogMode: true, vss: vss, molstar: molstar, pyodide: pyodide, pyodideReady: pyodideReady, resolve: closeAndReturnFileData, filterExtension: dialogProps.filterExtension, NAPGenericStore: NAPGenericStore, dataset: dialogProps.dataset })) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ variant: "outlined", onClick: closeAndReturnUndefined }, { children: "close" })), _jsx(Button, Object.assign({ variant: "contained", disabled: Array.from(selectedFiles).length !== 1, onClick: closeAndReturnSelectedFileData }, { children: "Select" }))] })] })));
}
export function dispatchFileSelectionEvent(filterExtension = [], dialogTitle = "", dataset) {
    return new Promise((resolve, reject) => {
        window.dispatchEvent(new CustomEvent("PM-FileBrowserDialog", {
            detail: { resolve, filterExtension, dialogTitle, dataset },
        }));
    });
}
