import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Grid } from "@mui/material";
import { ActionIconButton } from "../..";
import FilterTiltShiftIcon from "@mui/icons-material/FilterTiltShift";
import { dispatchEchartResetPosition } from "./DispatchEvents";
export function InteractivePlotButtons() {
    return (_jsxs(Grid, Object.assign({ container: true }, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(ActionIconButton, Object.assign({ tooltipMsg: "Focus plot", onClick: () => {
                        dispatchEchartResetPosition();
                    }, iconHoverColor: "auto", backgroundColor: "auto", backgroundHoverColor: "auto" }, { children: _jsx(FilterTiltShiftIcon, { fontSize: "small" }) })) })), " "] })));
}
