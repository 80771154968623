import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * @author Timur Kuzhagaliyev <tim.kuzh@gmail.com>
 * @author Panagiotis Tourlas <p.tourlas@acellera.com>
 * @copyright 2022
 * @license MIT
 */
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reduxActions } from "../../redux/reducers";
import { selectClearSelectionOnOutsideClick, selectFileActionIds, selectIsDnDDisabled, } from "../../redux/selectors";
import { useDndContextAvailable } from "../../util/dnd-fallback";
import { elementIsInsideButton } from "../../util/helpers";
import { makeGlobalChonkyStyles } from "../../util/styles";
import { useContextMenuTrigger } from "../external/FileContextMenu-hooks";
import { DnDFileListDragLayer } from "../file-list/DnDFileListDragLayer";
import { HotkeyListener } from "./HotkeyListener";
export const ChonkyPresentationLayer = ({ children }) => {
    const dispatch = useDispatch();
    const fileActionIds = useSelector(selectFileActionIds);
    const dndDisabled = useSelector(selectIsDnDDisabled);
    const clearSelectionOnOutsideClick = useSelector(selectClearSelectionOnOutsideClick);
    // Deal with clicks outside of Chonky
    const handleClickAway = useCallback((event) => {
        if (!clearSelectionOnOutsideClick ||
            elementIsInsideButton(event.target)) {
            // We only clear out the selection on outside click if the click target
            // was not a button. We don't want to clear out the selection when a
            // button is clicked because Chonky users might want to trigger some
            // selection-related action on that button click.
            return;
        }
        dispatch(reduxActions.clearSelection());
    }, [dispatch, clearSelectionOnOutsideClick]);
    // Generate necessary components
    const hotkeyListenerComponents = useMemo(() => fileActionIds.map((actionId) => (_jsx(HotkeyListener, { fileActionId: actionId }, `file-action-listener-${actionId}`))), [fileActionIds]);
    const dndContextAvailable = useDndContextAvailable();
    const showContextMenu = useContextMenuTrigger();
    const classes = useStyles();
    return (_jsx(ClickAwayListener, Object.assign({ onClickAway: handleClickAway }, { children: _jsxs(Box, Object.assign({ className: classes.chonkyRoot, onContextMenu: showContextMenu }, { children: [!dndDisabled && dndContextAvailable && _jsx(DnDFileListDragLayer, {}), hotkeyListenerComponents, children ? children : null] })) })));
};
const useStyles = makeGlobalChonkyStyles((theme) => ({
    chonkyRoot: {
        backgroundColor: theme.palette.background.paper,
        border: `solid 1px ${theme.palette.divider}`,
        padding: theme.margins.rootLayoutMargin,
        fontSize: theme.fontSizes.rootPrimary,
        color: theme.palette.text.primary,
        touchAction: "manipulation",
        fontFamily: "sans-serif",
        flexDirection: "column",
        boxSizing: "border-box",
        textAlign: "left",
        borderRadius: 4,
        display: "flex",
        height: "100%",
        // Disabling select
        webkitTouchCallout: "none",
        webkitUserSelect: "none",
        mozUserSelect: "none",
        msUserSelect: "none",
        userSelect: "none",
    },
}));
