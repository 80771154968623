import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { FormControl, MenuItem, Select, } from "@mui/material";
import { useEffect, useState } from "react";
import { TrajectoryModeEnum } from "../../utils";
export function TrajectoryModeSelect({ currentTrajectoryMode, callback, disabled, }) {
    const [mode, setMode] = useState(TrajectoryModeEnum.currentFrame);
    useEffect(() => {
        setMode(currentTrajectoryMode);
    }, [currentTrajectoryMode]);
    const handleChange = (event) => {
        const mode = event.target.value;
        setMode(mode);
        callback(mode);
    };
    return (_jsx(FormControl, Object.assign({ fullWidth: true, size: "small", variant: "standard", disabled: disabled }, { children: _jsxs(Select, Object.assign({ size: "small", labelId: "select-traj-display-mode", value: mode, label: "", onChange: handleChange, sx: {
                fontSize: "0.8rem",
            } }, { children: [_jsx(MenuItem, Object.assign({ sx: {
                        fontSize: "0.875rem",
                    }, value: TrajectoryModeEnum.currentFrame }, { children: "Current" })), _jsx(MenuItem, Object.assign({ sx: {
                        fontSize: "0.875rem",
                    }, value: TrajectoryModeEnum.multipleFrames }, { children: "Multiple" }))] })) })));
}
