// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { PluginCommands } from "molstar/lib/mol-plugin/commands";
export function removeRepresentations(repLi, molstar) {
    var _a, repLi_1, repLi_1_1;
    var _b, e_1, _c, _d, _e, e_2, _f, _g;
    return __awaiter(this, void 0, void 0, function* () {
        try {
            for (_a = true, repLi_1 = __asyncValues(repLi); repLi_1_1 = yield repLi_1.next(), _b = repLi_1_1.done, !_b;) {
                _d = repLi_1_1.value;
                _a = false;
                try {
                    const rep = _d;
                    if (!rep.refs)
                        continue;
                    try {
                        for (var _h = true, _j = (e_2 = void 0, __asyncValues(rep.refs)), _k; _k = yield _j.next(), _e = _k.done, !_e;) {
                            _g = _k.value;
                            _h = false;
                            try {
                                const ref = _g;
                                const repRef = ref.repRef;
                                if (!repRef)
                                    continue;
                                yield PluginCommands.State.RemoveObject(molstar, {
                                    state: molstar.state.data,
                                    ref: repRef,
                                });
                            }
                            finally {
                                _h = true;
                            }
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (!_h && !_e && (_f = _j.return)) yield _f.call(_j);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                }
                finally {
                    _a = true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (!_a && !_b && (_c = repLi_1.return)) yield _c.call(repLi_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    });
}
