import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect } from "react";
import { shallow } from "zustand/shallow";
import { Box, IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
export function PyodideTerminal({ NAPGenericStore, }) {
    useEffect(() => {
        // @ts-ignore
        window.console_ready();
    }, []);
    const [activePanels, setActivePanels] = NAPGenericStore((state) => [state._activePanels, state.setActivePanels], shallow);
    const toggleConsole = () => setActivePanels(Object.assign(Object.assign({}, activePanels), { console: !activePanels.console }));
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, Object.assign({ sx: { position: "fixed", right: 0, zIndex: 1 }, onClick: toggleConsole, color: "primary" }, { children: activePanels.console ? (_jsx(KeyboardArrowDownIcon, { sx: { color: "secondary.light" } })) : (_jsx(KeyboardArrowUpIcon, { sx: { color: "secondary.light" } })) })), _jsx(Box, { id: "term_demo", sx: style }), ";"] }));
}
const style = {
    background: "#202020",
    height: "100%",
    "& .terminal-scroller": {
        display: "flex",
        flexDirection: "column-reverse",
        pt: 0,
    },
    "& .terminal-fill": { padding: 0, height: 0 },
};
