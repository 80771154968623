import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { IconButton, Tooltip } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { lightenColor } from "./utils";
import CloseIcon from "@mui/icons-material/Close";
export function TabIcons({ color, isActive, onDeleteAll, tabName, }) {
    return (_jsxs(_Fragment, { children: [color ? (_jsx(TabColorIcon, { color: isActive ? color : lightenColor(color) })) : undefined, onDeleteAll ? (_jsx(CloseTabButton, { onClick: onDeleteAll, isActive: isActive, tabName: tabName })) : undefined] }));
}
function CloseTabButton({ onClick, isActive, tabName, }) {
    return (_jsx(Tooltip, Object.assign({ title: `Close all ${tabName.toLowerCase()}`, arrow: true, enterDelay: 1000 }, { children: _jsx(IconButton, Object.assign({ size: "small", onClick: (e) => {
                e.stopPropagation();
                onClick();
            }, sx: { mb: "3px" }, color: isActive ? "primary" : "default" }, { children: _jsx(CloseIcon, { fontSize: "small" }) })) })));
}
function TabColorIcon({ color }) {
    return (_jsx(CircleIcon, { sx: {
            fontSize: "1em",
            color: color,
            mb: "3px",
        } }));
}
