// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { styled } from "@mui/material/styles";
import { Drawer } from "@mui/material";
/** The styled() function takes the original component and applies some extra
 * styling rules based on the available theme and the props passed.
 * Notice that the 'shouldForwardProp' parameter means
 * "pass the prop to the original component as is".
 */
export const CollapsibleDrawerRightSide = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => (Object.assign(Object.assign({ flexShrink: 0, whiteSpace: "nowrap", backgroundColor: "grey", overflowX: "hidden", overflowY: "hidden" }, (open && Object.assign(Object.assign({}, openingTransition(theme)), { "& .MuiDrawer-paper": openingTransition(theme) }))), (!open && Object.assign(Object.assign({}, closingTransition(theme)), { "& .MuiDrawer-paper": closingTransition(theme) })))));
const openingTransition = (theme) => ({
    width: "400px",
    transition: widthTransition(theme),
});
const closingTransition = (theme) => ({
    width: 0,
    transition: widthTransition(theme),
});
const widthTransition = (theme) => theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
});
