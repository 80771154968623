// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Chip } from "@mui/material";
import { styled } from "@mui/material/styles";
export const StyledChip = styled(Chip, {
    shouldForwardProp: (prop) => prop !== "colorCustom" && prop !== "small",
})(({ colorCustom, variant, small }) => (Object.assign(Object.assign({}, (colorCustom && {
    color: variant === "outlined" ? colorCustom : "#4d4d4d",
    backgroundColor: `${variant === "outlined" ? "transparent" : colorCustom} !important`,
    borderColor: "outlined" ? colorCustom : undefined,
    // filter: "brightness(50%)",
    "&:hover": {
        backgroundColor: colorCustom,
        filter: "brightness(120%)",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: colorCustom,
        borderColor: colorCustom,
    },
})), (small && {
    fontSize: `${0.75 * 0.8125}rem`,
    height: `${0.5 * 32}px`,
    "& .MuiChip-label": {
        paddingRight: "7px",
        paddingLeft: "7px",
    },
}))));
