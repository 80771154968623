import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect, useState } from "react";
import { Button, Dialog, DialogContent, DialogActions, List, ListItem, ListItemButton, ListItemText, } from "@mui/material";
import { getDatasetOptions } from "./utils";
import { userAuthStore } from "../../Authentication/userAuthState";
export function DatacenterSelectorDialog({ pyodide, open, handleClose, handleSelect, datasetArgs, }) {
    const [options, setOptions] = useState([]);
    useEffect(() => {
        if (open) {
            const userInfo = userAuthStore.getState().userInfo;
            if (!userInfo)
                return;
            getDatasetOptions(userInfo.userId, datasetArgs, pyodide).then((response) => setOptions(response ? response : []));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
    return (_jsxs(Dialog, Object.assign({ open: open, onClose: handleClose }, { children: [_jsx(DialogContent, { children: _jsx(List, Object.assign({ sx: { pt: 0 } }, { children: options.length > 0
                        ? options.map((e) => (_jsx(ListItem, Object.assign({ disableGutters: true }, { children: _jsx(ListItemButton, Object.assign({ onClick: () => handleSelect(e) }, { children: _jsx(ListItemText, { primary: e.filepath }) }), e.filepath) }), `$dc-${e.id}`)))
                        : "No data" })) }), _jsx(DialogActions, { children: _jsx(Button, Object.assign({ onClick: handleClose }, { children: "Close" })) })] })));
}
