import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { DCBrowserStore } from "../..";
import { useLocation } from "wouter";
import { Fade, Box, Grid, IconButton, Typography, CircularProgress, } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { leftPanel } from "../panelDimensions";
import { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import { DatacenterBrowser } from "../../DatacenterBrowser/MainComponent";
import { ActiveDataset } from "../../DatacenterBrowser/datacenterBrowser.store";
export const CATHPanel = ({ vss, molstar, pyodide, NAPGenericStore, pyodideReady, }) => {
    const [currLoc, setLoc] = useLocation();
    const isActive = currLoc.toLowerCase().startsWith("/mdcath");
    const [CATHDatasetIsReady] = DCBrowserStore((state) => [state.CATHDatasetIsReady], shallow);
    const [fetchTriggered, setFetchTriggered] = useState(false);
    useEffect(() => {
        if (!pyodideReady || !isActive || fetchTriggered)
            return;
        window.highPriorityMsg({
            type: "fetch-cath-dataset",
            url: window.location.origin,
            priority: true,
        });
        setFetchTriggered(true);
    }, [isActive, pyodideReady]);
    return (_jsx(Fade, Object.assign({ in: isActive }, { children: _jsx(Box, Object.assign({ id: "cath-entrypoint" }, { children: _jsx(Box, Object.assign({ sx: dimensions }, { children: _jsxs(Grid, Object.assign({ container: true, direction: "column", sx: { height: "100%", pb: 2 }, wrap: "nowrap" }, { children: [_jsxs(Grid, Object.assign({ container: true, item: true, xs: "auto", direction: "row", sx: { p: 1, pb: 0 }, justifyContent: "flex-start", alignItems: "center" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(IconButton, Object.assign({ color: "primary", onClick: () => setLoc("/tools") }, { children: _jsx(ArrowBackIcon, {}) })) })), _jsx(Grid, Object.assign({ item: true, xs: true, sx: { justifyContent: "center" } }, { children: _jsx(Typography, Object.assign({ sx: { textAlign: "center", pr: "32px" }, variant: "h6" }, { children: "mdCATH dataset" })) }))] })), isActive ? (CATHDatasetIsReady ? (_jsx(Grid, Object.assign({ item: true, xs: true, sx: { overflowY: "auto" } }, { children: _jsx(DatacenterBrowser, { vss: vss, molstar: molstar, pyodide: pyodide, showFileActions: true, pyodideReady: pyodideReady, NAPGenericStore: NAPGenericStore, dataset: ActiveDataset.CATH }) }))) : (_jsx(Box, Object.assign({ sx: {
                                display: "flex",
                                justifyContent: "center",
                                p: 2,
                            } }, { children: _jsx(CircularProgress, { size: "25px" }) })))) : null] })) })) })) })));
};
const dimensions = Object.assign(Object.assign({}, leftPanel), { left: (theme) => `calc(${theme.spacing(7)})`, backgroundColor: "white" });
