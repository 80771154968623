import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { TextFieldPM } from "../SharedFormParts/TextField";
export function JobName() {
    var _a;
    const { register, formState } = useFormContext();
    const { errors } = formState;
    return (_jsx(Grid, Object.assign({ item: true, sx: { width: "100%" } }, { children: _jsx(TextFieldPM, { formKey: "name", label: "Job Name", type: "text", error: (_a = errors["name"]) === null || _a === void 0 ? void 0 : _a.message, marginTop: 0 }) })));
}
