// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { TableClickAction } from "..";
import { getSystemEntryToggleColValues } from "./utils";
function findToggleColnamesInConf(rowActions, actionColTypes) {
    const _actionColTypes = actionColTypes
        ? actionColTypes
        : [
            TableClickAction.toggleVisibility,
            TableClickAction.loadFile,
            TableClickAction.showSystemEntry,
        ];
    return rowActions === null || rowActions === void 0 ? void 0 : rowActions.filter((act) => _actionColTypes.includes(act.action)).reduce((allCols, act) => {
        // const addCols = act.column.filter((col) => col !== "protein");
        return [...allCols, ...act.column];
    }, []);
}
function findToggleColnamesInTable(colNames, toggleVizColnamesConf) {
    let toggleVizColnames = [];
    colNames.forEach((colName) => {
        const isToggleCol = typeof colName === "string" &&
            toggleVizColnamesConf.some((toggleCol) => toggleCol.toLowerCase() === colName.toLowerCase());
        if (isToggleCol)
            toggleVizColnames.push(colName);
    });
    return toggleVizColnames;
}
function cleanFilePath(originalString, substringToRemove) {
    if (substringToRemove) {
        const _substringToRemove = substringToRemove.endsWith("/")
            ? substringToRemove
            : substringToRemove + "/";
        if (originalString.startsWith(_substringToRemove)) {
            return originalString.slice(_substringToRemove.length);
        }
    }
    if (originalString.startsWith("dc://")) {
        const fileName = originalString.split("=").pop();
        if (fileName)
            return fileName;
    }
    return originalString;
}
export function getRowIsActive(row, toggleVizColnames, visibleSystemsFiles, basePath, DCtable) {
    const rowToggleData = toggleVizColnames
        .map((colName) => row.original[colName])
        .filter((e) => !!e);
    if (rowToggleData.length === 0)
        return false;
    const visibleSystemFilesFlat = visibleSystemsFiles.flat();
    const visibleSystemFilesFlat_relPath = visibleSystemFilesFlat.map((systFile) => basePath || DCtable ? cleanFilePath(systFile, basePath) : systFile);
    const rowIsActive = rowToggleData
        .filter((rowSystem) => !!rowSystem)
        .every((rowSystem) => rowSystem.charAt(0) === "/"
        ? visibleSystemFilesFlat.includes(rowSystem)
        : visibleSystemFilesFlat_relPath.includes(rowSystem));
    return rowIsActive;
}
export function getRowIsActiveEntries(row, rowOnClickActions, visibleSystemsFiles, visibleSystemsFileEntries, basePath, DCtable) {
    if (!rowOnClickActions)
        return false;
    const visibleSystemFilesFlat = visibleSystemsFiles.flat();
    const visibleSystemFilesFlat_relPath = visibleSystemFilesFlat.map((systFile) => basePath || DCtable ? cleanFilePath(systFile, basePath) : systFile);
    const visibleSystemsFileEntries_relPath = Object.entries(visibleSystemsFileEntries).reduce((prev, curr) => {
        const syst = basePath || DCtable ? cleanFilePath(curr[0], basePath) : curr[0];
        return Object.assign(Object.assign({}, prev), { [syst]: curr[1] });
    }, {});
    const rowData = getSystemEntryToggleColValues(row, rowOnClickActions);
    const rowIsActive = rowData.every((rowEntryData) => {
        const rowSystem = rowEntryData.system;
        if (!rowSystem)
            return false;
        const systVisible = rowSystem.charAt(0) === "/"
            ? visibleSystemFilesFlat.includes(rowSystem)
            : visibleSystemFilesFlat_relPath.includes(rowSystem);
        const systActiveElements = rowSystem.charAt(0) === "/"
            ? visibleSystemsFileEntries[rowSystem]
            : visibleSystemsFileEntries_relPath[rowSystem];
        const entryVisible = systActiveElements === null || systActiveElements === void 0 ? void 0 : systActiveElements.includes(rowEntryData.entryIdx);
        return !!systVisible && !!entryVisible;
    });
    return rowIsActive;
}
export function getToggleVizColnames(rowActions, colNames, actionColTypes) {
    const toggleVizColnamesConf = rowActions
        ? findToggleColnamesInConf(rowActions, actionColTypes)
        : [];
    if (toggleVizColnamesConf.length === 0)
        return [];
    const toggleVizColnames = findToggleColnamesInTable(colNames, toggleVizColnamesConf);
    return toggleVizColnames;
    // if (toggleVizColnames.length <= 1) return toggleVizColnames;
    // return [];
}
