import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import React from "react";
import { VirtualizedTable } from "./virtualizedTable";
const MemoTable = ({ data }) => {
    const columns = [];
    const lines = data.split("\n");
    const headers = lines[0].split(",");
    const rows = [];
    for (let idx = 1; idx < lines.length; idx++) {
        const _row = {};
        lines[idx].split(",").forEach((value, index) => {
            _row[headers[index]] = value.substring(0, 8);
        });
        rows.push(_row);
    }
    lines[0].split(",").forEach((_header) => {
        columns.push({
            Header: _header,
            accessor: _header,
            minWidth: 80,
        });
    });
    return _jsx(VirtualizedTable, { data: rows, columns: columns });
};
/** Custom diff function that tells the MemoTable component when to re-render. */
function propsComparison(prevProps, nextProps) {
    if (prevProps.data.length === nextProps.data.length) {
        /* COULD PASS THE FILEID !*/
        console.log("skip");
        return true;
    }
    else {
        console.log("re-render");
        return false;
    }
}
export const CSVtable = React.memo(MemoTable, propsComparison);
