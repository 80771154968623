var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export function sdfFileFromSMILES(smiles, pyodide, setError) {
    return __awaiter(this, void 0, void 0, function* () {
        const rdkit = window.RDKit;
        const mol = rdkit.get_mol(smiles);
        if (!mol.is_valid()) {
            setError("Invalid SMILES string.");
            return;
        }
        // let sdf_content = mol.get_v3Kmolblock();
        const tmpDir = new Date(Date.now())
            .toISOString()
            .slice(0, 19)
            .replace(/\D/g, "");
        const tmpPath = `/tmp/${tmpDir}`;
        yield pyodide.FS.mkdir(tmpPath);
        const filePath = `${tmpPath}/molecule.smi`;
        yield pyodide.FS.writeFile(filePath, smiles, "utf8");
        // const filePath = `${tmpPath}/molecule.sdf`;
        // await pyodide.FS.writeFile(filePath, sdf_content, "utf8");
        return filePath;
    });
}
