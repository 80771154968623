// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Structure } from "molstar/lib/mol-model/structure";
import { PluginStateObject as SO } from "molstar/lib/mol-plugin-state/objects";
import { PluginStateObject } from "molstar/lib/mol-plugin-state/objects";
export function getSystemLoci(cellRef, molstar) {
    const cellToFocus = molstar.state.data.selectQ((q) => q.byRef(cellRef));
    if (cellToFocus.length === 0) {
        console.error("Molstar: q.byRef query failed");
        return;
    }
    const cellObj = cellToFocus[0].obj;
    if (!cellObj)
        return;
    let lociArray;
    if (SO.Molecule.Structure.is(cellObj)) {
        const l = Structure.Loci(cellObj.data);
        lociArray = [l];
    }
    if (SO.isRepresentation3D(cellObj)) {
        const l = cellObj === null || cellObj === void 0 ? void 0 : cellObj.data.repr.getAllLoci();
        lociArray = l;
    }
    if (SO.Molecule.Trajectory.is(cellObj)) {
        const subElements = molstar.state.data.selectQ((q) => q.byRef(cellRef).subtree().ofType(PluginStateObject.Molecule.Structure));
        if (!subElements || subElements.length === 0) {
            console.error("Molstar: No sub elements found");
            return;
        }
        const model = subElements[0];
        const loci = model.obj ? Structure.Loci(model.obj.data) : undefined;
        if (!loci) {
            console.error("Molstar: No loci found");
            return;
        }
        lociArray = [loci];
    }
    return lociArray;
}
