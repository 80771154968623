import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * @author Timur Kuzhagaliyev <tim.kuzh@gmail.com>
 * @author Panagiotis Tourlas <p.tourlas@acellera.com>
 * @copyright 2022
 * @license MIT
 */
import Button from "@mui/material/Button";
import { useContext, memo } from "react";
import { selectFileActionData } from "../../redux/selectors";
import { useParamSelector } from "../../redux/store";
import { ChonkyIconName } from "../../types/icons.types";
import { CustomVisibilityState } from "../../types/action.types";
import { useFileActionProps, useFileActionTrigger, } from "../../util/file-actions";
import { useLocalizedFileActionStrings } from "../../util/i18n";
import { ChonkyIconContext } from "../../util/icon-helper";
import { c, important, makeGlobalChonkyStyles } from "../../util/styles";
export const ToolbarButton = memo((props) => {
    const { className: externalClassName, text, tooltip, active, icon, iconOnly, onClick, disabled, dropdown, } = props;
    const classes = useStyles();
    const ChonkyIcon = useContext(ChonkyIconContext);
    const iconComponent = icon || iconOnly ? (_jsx("div", Object.assign({ className: iconOnly ? "" : classes.iconWithText }, { children: _jsx(ChonkyIcon, { icon: icon ? icon : ChonkyIconName.fallbackIcon, fixedWidth: true }) }))) : null;
    const className = c({
        [externalClassName !== null && externalClassName !== void 0 ? externalClassName : ""]: true,
        [classes.baseButton]: true,
        [classes.iconOnlyButton]: iconOnly,
        [classes.activeButton]: !!active,
    });
    return (_jsxs(Button, Object.assign({ className: className, onClick: onClick, title: tooltip ? tooltip : text, disabled: disabled || !onClick }, { children: [iconComponent, text && !iconOnly && _jsx("span", { children: text }), dropdown && (_jsx("div", Object.assign({ className: classes.iconDropdown }, { children: _jsx(ChonkyIcon, { icon: icon ? icon : ChonkyIconName.dropdown, fixedWidth: true }) })))] })));
});
const useStyles = makeGlobalChonkyStyles((theme) => ({
    baseButton: {
        fontSize: important(theme.toolbar.fontSize),
        textTransform: important("none"),
        letterSpacing: important(0),
        minWidth: important("auto"),
        lineHeight: theme.toolbar.lineHeight,
        height: theme.toolbar.size,
        paddingBottom: important(0),
        paddingTop: important(0),
    },
    iconWithText: {
        marginRight: 8,
    },
    iconOnlyButton: {
        width: theme.toolbar.size,
        textAlign: "center",
    },
    iconDropdown: {
        fontSize: "0.7em",
        marginLeft: 2,
        marginTop: 1,
    },
    activeButton: {
        color: important(theme.colors.textActive),
    },
}));
export const SmartToolbarButton = memo((props) => {
    const { fileActionId } = props;
    const action = useParamSelector(selectFileActionData, fileActionId);
    const triggerAction = useFileActionTrigger(fileActionId);
    const { icon, active, disabled } = useFileActionProps(fileActionId);
    const { buttonName, buttonTooltip } = useLocalizedFileActionStrings(action);
    if (!action)
        return null;
    const { button } = action;
    if (!button)
        return null;
    if (action.customVisibility !== undefined &&
        action.customVisibility() === CustomVisibilityState.Hidden)
        return null;
    return (_jsx(ToolbarButton, { text: buttonName, tooltip: buttonTooltip, icon: icon, iconOnly: button.iconOnly, active: active, onClick: triggerAction, disabled: disabled }));
});
