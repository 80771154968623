// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import * as yup from "yup";
export const sendResetMailDefaults = {
    mail: "",
};
export const SendResetMailSchema = yup.object({
    mail: yup
        .string()
        .email("Must be a valid email")
        .required("Email is required"),
});
export const defaultValues = {
    // oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
};
export const ResetPasswordSchemaSchema = yup.object({
    // oldPassword: yup.string().required("Old password is required"),
    newPassword: yup
        .string()
        .required("New password is required")
        .test("password-strength", "Password MUST have at least 8 characters and contain at least: a lower-case letter, an upper-case letter, a number and a symbol/special character.", (value) => {
        if (!value)
            return true;
        const isLongEnough = value && value.length >= 8;
        const hasLowerCase = /[a-z]/.test(value);
        const hasUpperCase = /[A-Z]/.test(value);
        const hasDigit = /\d/.test(value);
        const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(value);
        const result = isLongEnough && hasLowerCase && hasUpperCase && hasDigit && hasSymbol;
        return !!result;
    }),
    newPasswordConfirm: yup
        .string()
        .required("Password confirmation is required")
        .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});
