import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
export function UploadLigandModeSelector({ uplMethod, handleSelect, }) {
    return (_jsxs(FormControl, Object.assign({ sx: { my: 1, minWidth: "10rem" }, size: "small" }, { children: [_jsx(InputLabel, Object.assign({ id: "demo-simple-select-label", size: "small" }, { children: "Input type" })), _jsx(Select, Object.assign({ size: "small", labelId: "upload-ligand-mode-select", id: "demo-simple-select", value: uplMethod, label: "Input type", onChange: (e) => {
                    handleSelect(e.target.value);
                } }, { children: uploader_label_mappings.map((uploader) => (_jsx(MenuItem, Object.assign({ value: uploader.value }, { children: uploader.label }), uploader.value))) }))] })));
}
const uploader_label_mappings = [
    {
        label: "File",
        value: "file",
    },
    {
        label: "SMILES string",
        value: "SMILES",
    },
    {
        label: "Draw Molecule",
        value: "ketcher",
    },
];
