var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import DeleteIcon from "@mui/icons-material/Delete";
import { dispatchConfirmationDialogEvent } from "../../../utils/EventDispatchers";
import CloseIcon from "@mui/icons-material/Close";
import { RoundActionIconButton } from "../ActionIconButton/RoundActionIconButton";
const RoundDeleteButton = ({ onClick, tooltipMsg, confirmationMsg, disabled, icon, }) => {
    const confirmDeletion = () => __awaiter(void 0, void 0, void 0, function* () {
        const ok = yield dispatchConfirmationDialogEvent({
            message: confirmationMsg || "",
        });
        if (ok)
            onClick();
    });
    const _onClick = () => __awaiter(void 0, void 0, void 0, function* () {
        if (disabled)
            return;
        if (confirmationMsg)
            yield confirmDeletion();
        else
            onClick();
    });
    return (_jsx(RoundActionIconButton, Object.assign({ size: "small", tooltipMsg: tooltipMsg, onClick: _onClick, paletteCategory: "error", colorOnHover: true, disabled: disabled }, { children: icon === "close" ? (_jsx(CloseIcon, { className: "delIcon", fontSize: "small" })) : (_jsx(DeleteIcon, { className: "delIcon", fontSize: "small" })) })));
};
export default RoundDeleteButton;
