var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import "../static/css/LandingPage.css";
import particlesConfig from "../static/images/LandingPage/particles-config.json";
import { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { Box } from "@mui/material";
export function LandingPageBackground() {
    const [init, setInit] = useState(false);
    useEffect(() => {
        initParticlesEngine((engine) => __awaiter(this, void 0, void 0, function* () {
            yield loadSlim(engine);
        })).then(() => {
            setInit(true);
        });
    }, []);
    return (_jsx(Box, Object.assign({ sx: {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "linear-gradient(to top left, #a3bdd6, #ffffff)",
        } }, { children: init && _jsx(Particles, { options: particlesConfig }) })));
}
