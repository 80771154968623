var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { PluginCommands } from "molstar/lib/mol-plugin/commands";
/**
 * Cleans up all molecular systems from the shared viewer state and
 * deletes all structures from molstar as well.
 * @param molstar
 * @param vss
 * @param NAPTableStore
 */
export function resetAllState(molstar, vss, NAPTableStore) {
    return __awaiter(this, void 0, void 0, function* () {
        vss.getState().reset();
        NAPTableStore.getState().set_tables([]);
        yield PluginCommands.State.RemoveObject(molstar, {
            state: molstar.state.data,
            ref: molstar.state.data.tree.root.ref,
        });
    });
}
