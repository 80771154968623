import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import "../static/css/LandingPage.css";
export function LandingPageContact() {
    return (_jsxs("div", Object.assign({ id: "suggestion-section", style: { paddingTop: "1em", paddingBottom: "5em" } }, { children: [_jsx("h3", Object.assign({ style: { fontSize: "30px", fontWeight: 200 } }, { children: "Any suggestion?" })), _jsx("div", Object.assign({ id: "suggestion-wrapper" }, { children: _jsx("iframe", { id: "mojo-desk", src: "https://acellera.mojohelpdesk.com/widgets/create_request#/ticket-form/28405", style: {
                        width: "100%",
                        height: "400px",
                        overflow: "hidden",
                        border: 0,
                    } }) }))] })));
}
