// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Color } from "molstar/lib/mol-util/color";
import { MeasurementType, SelectionStore, } from "../../../StateStores/selection.store";
function clearSelection(molstar) {
    molstar.managers.interactivity.lociSelects.deselectAll();
    SelectionStore.getState().setSelectedResidues({});
}
export function measureDistance(molstar) {
    const loci_arr = molstar.managers.structure.selection.additionsHistory;
    if (loci_arr.length === 2) {
        molstar.managers.structure.measurement
            .addDistance(loci_arr[0].loci, loci_arr[1].loci, {
            labelParams: {
                borderWidth: 0,
                background: true,
                backgroundColor: Color(0xffffff),
                backgroundOpacity: 0.25,
            },
        })
            .then(() => {
            SelectionStore.getState().setMeasurementLoci(MeasurementType.distance, molstar.managers.structure.measurement.state.distances);
        });
        clearSelection(molstar);
    }
}
export function measureAngle(molstar) {
    const loci_arr = molstar.managers.structure.selection.additionsHistory;
    if (loci_arr.length === 3) {
        molstar.managers.structure.measurement
            .addAngle(loci_arr[0].loci, loci_arr[1].loci, loci_arr[2].loci)
            .then(() => {
            SelectionStore.getState().setMeasurementLoci(MeasurementType.angle, molstar.managers.structure.measurement.state.angles);
        });
        clearSelection(molstar);
    }
}
export function measureDihedral(molstar) {
    const loci_arr = molstar.managers.structure.selection.additionsHistory;
    if (loci_arr.length === 4) {
        molstar.managers.structure.measurement
            .addDihedral(loci_arr[0].loci, loci_arr[1].loci, loci_arr[2].loci, loci_arr[3].loci)
            .then(() => {
            SelectionStore.getState().setMeasurementLoci(MeasurementType.dihedral, molstar.managers.structure.measurement.state.dihedrals);
        });
        clearSelection(molstar);
    }
}
