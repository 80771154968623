import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Grid, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
export function TableInfo({ text }) {
    return (_jsxs(Grid, Object.assign({ container: true, direction: "row", justifyContent: "flex-start", alignItems: "center", spacing: 1, sx: { order: 4, pl: "10px", py: 1 } }, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(InfoOutlinedIcon, { color: "secondary", sx: { height: "1rem", width: "1rem" } }) })), _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsxs(Typography, Object.assign({ component: Box, variant: "caption", sx: { pb: "0.2rem" } }, { children: [" ", text] })) }))] })));
}
