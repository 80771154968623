// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
export const catchIndexEdgeCasesAndBark = (idx) => {
    if (Number(idx) === 0)
        throw new SyntaxError(`${idx} is invalid, 1-based indexing is used!`);
    if (idx.toLowerCase() === "all")
        throw new SyntaxError(`Cannot mix "all" keyword with indexes!`);
};
export const catchSequenceEdgeCasesAndBark = (idx, start, end, refArray) => {
    if (start === 0)
        throw new SyntaxError(`${idx} is invalid, 1-based indexing is used!`);
    if (end > refArray.length)
        throw new RangeError(`${idx} sequence goes beyond array length (${refArray.length})!`);
    if (start > end)
        throw new RangeError(`${idx} is an invalid sequence, start < end required!`);
};
export const selectSingleIndex = (arr, idx) => {
    if (idx > arr.length)
        throw new RangeError(`Out-of-bounds index: ${idx}`);
    return arr[idx - 1];
};
export const selectIndexSequence = (arr, idxArr) => idxArr.map((idx) => arr[idx - 1]);
export const constructSequenceFromEndpoints = (start, end) => [...Array(end - start + 1).keys()].map((x) => x + start);
