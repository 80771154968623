import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Accordion, AccordionDetails, AccordionSummary, Typography, } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
export function NAPAccordion({ name, children, open, callback, variant = "outlined", summaryline, }) {
    const handleChange = (event) => {
        if (callback)
            callback();
    };
    return (_jsxs(Accordion, Object.assign({ expanded: open, onChange: handleChange, sx: {
            width: "100%",
            marginTop: 0,
            maxWidth: "100%",
            "&:before": {
                display: "none",
            },
            "& .MuiAccordionDetails-root": { pt: 0, pr: 0, pb: 0, pl: 2 },
            "& .MuiAccordionSummary-root": { minHeight: 0 },
        }, disableGutters: true, elevation: 0, variant: variant === "undefined" ? undefined : "outlined" }, { children: [_jsx(AccordionSummary, Object.assign({ expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": "panel1a-content", id: "panel1a-header", sx: {
                    borderBottom: summaryline
                        ? "1px solid rgba(0, 0, 0, 0.12)"
                        : undefined,
                } }, { children: _jsx(Typography, { children: name }) })), _jsx(AccordionDetails, Object.assign({ sx: { mb: 2 } }, { children: children }))] })));
}
