var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { diff } from "deep-object-diff";
import { shallow } from "zustand/shallow";
import { Paper, Box, Grid, Collapse } from "@mui/material";
import { DeleteButton, TreeNodeVisibilityButton, FileType, ActionIconButton, TreeNodeToggleOptionsButton, IsLoadingGroup, } from "..";
import BrushIcon from "@mui/icons-material/Brush";
import { VolumeIsoControls } from "./VolumeIsoControls/VolumeIsoControls";
import { CustomAccordion } from "./SystemGroups/CustomAccordion";
import { setVisibility } from "./VisibilityToggle";
import { removeSystem } from "./Controls/utils";
import { updateStateTreeVisibility, getSystemStateVisibility, selectSystemByIndexArray, updateStateTreeParameter, } from "./stateTree";
import { cameraFocus } from "./Controls/CameraControls/cameraFocus";
import { CameraFocusButton } from "./Controls/CameraControls/FocusButton";
import { TrajectoryFrameControls } from "./TrajectoryControls/TrajectoryFrameControls";
import { SystemCheckbox } from "./Controls/Groups/CreateGroupCheckbox";
import { SystemRadioButton } from "./SystemRadioButtons";
import { Panels } from "../GenericViewerState/SharedState";
import { dispatchSystemVisibilityChange } from "./DispatchEvents";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { getTrajectoryNumFrames, getTrajectoryRef, } from "./TrajectoryControls/utils";
import CircularProgress from "@mui/material/CircularProgress";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MoreSystemOptionsPopover } from "./MoreSystemOptions";
import { handleSelectFrameMode, showTrajectoryAsFrameEnsemble, } from "./TrajectoryControls/displayMultipleFrames";
import { downloadSystem as _downloadSystem } from "./DownloadSystem/downloadSystem";
import { truncateString } from "./utils";
import { clearHighlight, highlightStructure, } from "../utils/HighlightStructure";
import { positionRepresentationsPanel } from "./positionRepresentationsPanel";
import { MultipleEntryOptions } from "./MultipleEntrySystemControls/MultipleEntryOptions";
import { setTrajSmoothVal } from "./TrajectoryControls/setTrajSmoothVal";
/** Diff function that tells the component when to re-render. */
export function propsComparison(prevProps, nextProps) {
    const prevSystems = prevProps.vss.getState().loaded_structures;
    const nextSystems = nextProps.vss.getState().loaded_structures;
    const _diff = diff(prevSystems, nextSystems);
    if (_diff)
        return false;
    return true;
}
export const MolSystemsPanel = memo(MolSystemsPanelMEMO, propsComparison);
function MolSystemsPanelMEMO({ vss, molstar, pyodide, positionInTree = [], onCheckSystem, checkedSystems, areSystemsCheckboxActive, NAPGenericStore, simplified, onCloneRepresentationClick, disabledCheckboxSystems, }) {
    const [systems] = vss((state) => [state.loaded_structures, shallow]);
    // const systems = vss.getState().loaded_structures;
    if (!systems)
        return _jsx("div", {});
    let _systems;
    if (positionInTree.length === 0) {
        _systems = systems;
    }
    else {
        const groupSystem = selectSystemByIndexArray(positionInTree, systems);
        _systems = (groupSystem === null || groupSystem === void 0 ? void 0 : groupSystem.children) ? groupSystem === null || groupSystem === void 0 ? void 0 : groupSystem.children : [];
    }
    return (_jsx(_Fragment, { children: _systems.map(function (s, idx) {
            let node;
            s.stateTreePosition = [...positionInTree, idx];
            const isGroup = s.type === FileType.group;
            if (isGroup) {
                node = (_jsx(CustomAccordion, Object.assign({ vss: vss, name: s.name, molstar: molstar, pyodide: pyodide, positionInTree: s.stateTreePosition, NAPGenericStore: NAPGenericStore, simplified: simplified, loading: !!s.isLoading, disabled: s.isLoading === IsLoadingGroup.all || areSystemsCheckboxActive }, { children: _jsx(Grid, Object.assign({ container: true, direction: "column", spacing: 1, sx: { pt: 1 } }, { children: _jsx(MolSystemsPanelMEMO, { vss: vss, molstar: molstar, pyodide: pyodide, positionInTree: s.stateTreePosition, onCheckSystem: onCheckSystem, checkedSystems: checkedSystems, disabledCheckboxSystems: disabledCheckboxSystems, areSystemsCheckboxActive: areSystemsCheckboxActive, NAPGenericStore: NAPGenericStore, simplified: simplified, onCloneRepresentationClick: onCloneRepresentationClick }) })) }), s.name));
            }
            else {
                node = (_jsx(SystemOptions, { system: s, vss: vss, molstar: molstar, pyodide: pyodide, positionInTree: s.stateTreePosition, NAPGenericStore: NAPGenericStore, simplified: simplified, onCloneRepresentationClick: onCloneRepresentationClick, disableButtons: areSystemsCheckboxActive }, s.name));
            }
            return (_jsxs(Grid, Object.assign({ sx: { display: s.archived ? "none" : "flex" }, item: true, container: true, alignItems: "flex-start" }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: "auto", id: `checkbox-${s.stateTreePosition.join("-")}` }, { children: [isGroup ? null : (_jsx(SystemRadioButton, { vss: vss, pyodide: pyodide, boxKey: s.stateTreePosition.join("-"), name: s.name, NAPGenericStore: NAPGenericStore })), _jsx(SystemCheckbox, { boxKey: s.stateTreePosition.join("-"), onCheckSystem: onCheckSystem, checkedSystems: checkedSystems, disabledCheckboxSystems: disabledCheckboxSystems, areSystemsCheckboxActive: areSystemsCheckboxActive })] })), _jsx(Grid, Object.assign({ item: true, xs: true, zeroMinWidth: true }, { children: node }))] }), `grid-${s.stateTreePosition.join("-")}`));
        }) }));
}
function SystemControlButtonsSimplified({ systemName, onDetailsClick, onVisibilityClick, onFocusClick, isDetailsOpen, visibility = true, isHighlighted, toggleSpecificOptions, hasSpecificOptions, hideSpecificOptions, isLoading, }) {
    return (_jsxs(Grid, Object.assign({ container: true }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true, zeroMinWidth: true }, { children: _jsx(TreeNodeToggleOptionsButton, { disabled: !visibility || !!isLoading, onClick: toggleSpecificOptions, name: systemName, active: visibility && !isLoading, isHighlighted: isHighlighted, hideSpecificOptions: hideSpecificOptions, notClickable: !hasSpecificOptions }) })), isLoading ? (_jsx(CircularProgress, { size: "20px", sx: { m: "5px", color: "#757575" } })) : (_jsxs(_Fragment, { children: [visibility && (_jsxs(_Fragment, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(ActionIconButton, Object.assign({ onClick: onDetailsClick, tooltipMsg: "Representations", disabled: !visibility, active: isDetailsOpen, backgroundHoverColor: "#0b5394", iconHoverColor: "#ffab40" }, { children: _jsx(BrushIcon, {}) })) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(CameraFocusButton, { tooltipMsg: "Focus System", focusCb: onFocusClick, disabled: !visibility }) }))] })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(ActionIconButton, Object.assign({ onClick: onVisibilityClick, tooltipMsg: visibility ? "Hide" : "Show", backgroundHoverColor: "#0b5394", iconHoverColor: "#ffab40" }, { children: visibility ? _jsx(VisibilityIcon, {}) : _jsx(VisibilityOffIcon, {}) })) }))] }))] })));
}
function SystemControlButtons({ molstar, systemName, onDetailsClick, onDeleteClick, onVisibilityClick, onFocusClick, isDetailsOpen, visibility = true, isHighlighted, updateSystemName, cloneRepresentations, downloadSystem, numFrames, disableButtons, waitIcon, }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClosePopover = () => {
        setAnchorEl(null);
    };
    const popoverOpen = Boolean(anchorEl);
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, Object.assign({ container: true }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true, zeroMinWidth: true, sx: { minWidth: "20px" } }, { children: _jsx(TreeNodeVisibilityButton, { onClick: onVisibilityClick, name: systemName, active: visibility, isHighlighted: isHighlighted, notClickable: disableButtons, waitIcon: waitIcon }) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(CameraFocusButton, { tooltipMsg: "Focus System", focusCb: onFocusClick, disabled: !visibility }) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(ActionIconButton, Object.assign({ onClick: onDetailsClick, tooltipMsg: "Edit Representations", disabled: !visibility || disableButtons, active: isDetailsOpen, backgroundHoverColor: "#0b5394", iconHoverColor: "#ffab40" }, { children: _jsx(BrushIcon, {}) })) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(DeleteButton, { onClick: () => onDeleteClick(molstar), tooltipMsg: "Delete System", 
                            // placement="right"
                            confirmationMsg: `Are you sure you want to delete system "${systemName}" ?`, disabled: disableButtons }) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(ActionIconButton, Object.assign({ onClick: handleOpenPopover, backgroundHoverColor: "#0b5394", iconHoverColor: "#ffab40", active: popoverOpen, disabled: disableButtons }, { children: _jsx(MoreVertIcon, {}) })) }))] })), _jsx(MoreSystemOptionsPopover, { anchorEl: anchorEl, handleClosePopover: handleClosePopover, updateSystemName: updateSystemName, downloadSystem: downloadSystem, cloneRepresentations: cloneRepresentations, numFrames: numFrames })] }));
}
function VolumeOptions({ vss, molstar, system, positionInTree, visibility, }) {
    const volParams = system.volumeRepresentation;
    return (_jsx(VolumeIsoControls, { vss: vss, molstar: molstar, cellRef: system.cellRef ? system.cellRef[0] : "", visibility: visibility, maxIsoValue: volParams["maxIsoValue"], minIsoValue: volParams["minIsoValue"], positionInTree: positionInTree }));
}
function SystemOptions({ vss, pyodide, molstar, system, positionInTree, NAPGenericStore, simplified, onCloneRepresentationClick, disableButtons, }) {
    var _a, _b, _c, _d;
    const [loaded_structures] = vss((state) => [state.loaded_structures]);
    const [activeSystemPosition, setActiveSystemPosition, setOpenBottomPanel, activePanels, setActivePanels, highligtedTableSystemRef, addVisibleSystemsFiles, removeVisibleSystemsFiles, closeSystemRepresentations, setRepresentationsPanelWasDragged, representationsPanelWasDragged,] = NAPGenericStore((state) => [
        state._activeSystemPosition,
        state.setActiveSystemPosition,
        state.setOpenBottomPanel,
        state._activePanels,
        state.setActivePanels,
        state.highligtedTableSystemRef,
        state.addVisibleSystemsFiles,
        state.removeVisibleSystemsFiles,
        state.closeSystemRepresentations,
        state.setRepresentationsPanelWasDragged,
        state.representationsPanelWasDragged,
    ], shallow);
    const visibility = useMemo(() => getSystemStateVisibility(vss, positionInTree), [positionInTree, vss]);
    useEffect(() => {
        const systFiles = system.files;
        if (!systFiles || systFiles.length == 0)
            return;
        if (visibility)
            addVisibleSystemsFiles(systFiles);
        else
            removeVisibleSystemsFiles(systFiles);
    }, [visibility]);
    const fileType = system.type;
    const isHighlighted = system.cellRef
        ? system.cellRef.some((ref) => highligtedTableSystemRef.includes(ref))
        : false;
    const cellRefs = system.cellRef ? system.cellRef : [""];
    function onDetailsClick() {
        if (activeSystemPosition &&
            JSON.stringify(activeSystemPosition) === JSON.stringify(positionInTree)) {
            closeSystemRepresentations();
        }
        else {
            if (!activeSystemPosition || !representationsPanelWasDragged) {
                //If the panel was closed, open it under the system buttons
                setRepresentationsPanelWasDragged(false);
                positionRepresentationsPanel(systemOptionsRef, system.type === FileType.volume);
            }
            setOpenBottomPanel(true);
            setActiveSystemPosition(positionInTree);
        }
    }
    function onDeleteClick(molstar) {
        return __awaiter(this, void 0, void 0, function* () {
            closeSystemRepresentations();
            removeSystem(molstar, pyodide, vss, cellRefs, positionInTree);
            if (loaded_structures.length === 0)
                setActivePanels(Object.assign(Object.assign({}, activePanels), { [Panels.statetree]: false, [Panels.sequence]: false }));
            if (system.files)
                removeVisibleSystemsFiles(system.files);
        });
    }
    function onVisibilityClick() {
        const visibility = getSystemStateVisibility(vss, positionInTree); // This was not necessary before
        setVisibility(molstar, cellRefs, !visibility);
        updateStateTreeVisibility(vss, positionInTree, !visibility, molstar);
        if (visibility &&
            activeSystemPosition &&
            JSON.stringify(activeSystemPosition) === JSON.stringify(positionInTree)) {
            //This closes the system representation panel if system is made not visible
            closeSystemRepresentations();
        }
        dispatchSystemVisibilityChange({ system: system, visibility: !visibility });
    }
    const onFocusClick = () => cameraFocus(molstar, cellRefs);
    const isSystemRepsOpen = JSON.stringify(activeSystemPosition) === JSON.stringify(positionInTree);
    let multiSelection = fileType == FileType.sdf && system.numEntries && system.numEntries > 1;
    const isovalueSelection = fileType === FileType.volume;
    const systemIsTrajectory = fileType === FileType.trajectory;
    let trajectoryRef;
    let numFrames;
    if (systemIsTrajectory) {
        if (system.cellRef) {
            trajectoryRef = getTrajectoryRef(molstar, system.cellRef);
            if (trajectoryRef) {
                numFrames = getTrajectoryNumFrames(trajectoryRef, molstar);
            }
        }
    }
    const hasSpecificOptions = multiSelection || isovalueSelection || trajectoryRef;
    const [specificOptionsCollapsed, setSpecificOptionsCollapsed] = useState(false);
    useEffect(() => {
        if (simplified)
            setSpecificOptionsCollapsed(true);
    }, []);
    const updateSystemName = (name) => __awaiter(this, void 0, void 0, function* () {
        var _e, e_1, _f, _g;
        if (!system.stateTreePosition)
            return;
        if (cellRefs.length === 0)
            return;
        // Update name of the molstar cells
        if (system.type !== FileType.volume) {
            try {
                for (var _h = true, cellRefs_1 = __asyncValues(cellRefs), cellRefs_1_1; cellRefs_1_1 = yield cellRefs_1.next(), _e = cellRefs_1_1.done, !_e;) {
                    _g = cellRefs_1_1.value;
                    _h = false;
                    try {
                        const cellRef = _g;
                        const cellToUpdateArr = molstar.state.data.selectQ((q) => q
                            .byRef(cellRef)
                            .ancestor((e) => e.transform.params.name === system.name));
                        if (cellToUpdateArr.length === 0) {
                            console.error("Could not update the system name in molstar cell");
                            continue;
                        }
                        yield molstar
                            .build()
                            .to(cellToUpdateArr[0])
                            .update((old) => {
                            return Object.assign(Object.assign({}, old), { name: truncateString(name, 30) });
                        })
                            .commit();
                    }
                    finally {
                        _h = true;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (!_h && !_e && (_f = cellRefs_1.return)) yield _f.call(cellRefs_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        // Update name in the systems tree
        updateStateTreeParameter(vss, { name }, system.stateTreePosition);
    });
    const downloadSystem = isovalueSelection
        ? undefined
        : (format, selection, from, to, stride) => __awaiter(this, void 0, void 0, function* () {
            if (!system.moleculeID)
                throw new Error("Molecule not found.");
            yield _downloadSystem(pyodide, format, selection, system.moleculeID, system.name, from, to, stride);
        });
    const [wait, setWaiting] = useState(false);
    const handleDisplayMultipleFrames = (from, to, step) => __awaiter(this, void 0, void 0, function* () {
        if (!trajectoryRef)
            return;
        setWaiting(true);
        yield showTrajectoryAsFrameEnsemble(system, trajectoryRef, from, to, step, molstar, pyodide, vss);
        setWaiting(false);
    });
    const handleSmoothTraj = (smoothVal) => __awaiter(this, void 0, void 0, function* () {
        setWaiting(true);
        closeSystemRepresentations();
        yield setTrajSmoothVal(system, smoothVal, molstar, pyodide, vss);
        setWaiting(false);
    });
    const _handleSelectFrameMode = (mode, currFrame) => __awaiter(this, void 0, void 0, function* () {
        if (!trajectoryRef)
            return;
        closeSystemRepresentations();
        setWaiting(true);
        yield handleSelectFrameMode(mode, positionInTree, vss, trajectoryRef, system, molstar, pyodide, currFrame);
        setWaiting(false);
    });
    const hideFormatSpecificOptions = specificOptionsCollapsed || !visibility || !!disableButtons;
    const cloneRepresentations = onCloneRepresentationClick
        ? () => {
            if (system.stateTreePosition)
                onCloneRepresentationClick(system.stateTreePosition);
        }
        : undefined;
    const systemOptionsRef = useRef(null);
    return (_jsx(Box, Object.assign({ sx: { width: "100%", px: 1, pl: 1 } }, { children: _jsxs(Paper, Object.assign({ ref: systemOptionsRef }, { children: [simplified ? (_jsx(SystemControlButtonsSimplified, { molstar: molstar, isDetailsOpen: isSystemRepsOpen, onDetailsClick: onDetailsClick, onDeleteClick: () => onDeleteClick(molstar), onVisibilityClick: onVisibilityClick, onFocusClick: onFocusClick, visibility: visibility, systemName: system.name, isHighlighted: isHighlighted, toggleSpecificOptions: () => {
                        setSpecificOptionsCollapsed((prev) => !prev);
                    }, hideSpecificOptions: hideFormatSpecificOptions, hasSpecificOptions: hasSpecificOptions, isLoading: system.isLoading })) : (_jsx(Box, Object.assign({ onMouseEnter: () => {
                        highlightStructure(molstar, cellRefs);
                    }, onMouseLeave: () => clearHighlight(molstar), onTouchStart: () => {
                        highlightStructure(molstar, cellRefs);
                    }, onTouchEnd: () => clearHighlight(molstar) }, { children: _jsx(SystemControlButtons, { molstar: molstar, isDetailsOpen: isSystemRepsOpen, onDetailsClick: onDetailsClick, onDeleteClick: () => onDeleteClick(molstar), onVisibilityClick: onVisibilityClick, onFocusClick: onFocusClick, visibility: visibility, systemName: system.name, isHighlighted: isHighlighted, updateSystemName: updateSystemName, downloadSystem: downloadSystem, cloneRepresentations: cloneRepresentations, numFrames: numFrames, disableButtons: disableButtons || wait, waitIcon: wait }) }))), _jsx(Collapse, Object.assign({ in: !hideFormatSpecificOptions }, { children: multiSelection ? (
                    // <SDFandPdbBatchOptions
                    //   system={system}
                    //   vss={vss}
                    //   NAPGenericStore={NAPGenericStore}
                    //   molstar={molstar}
                    //   visibility={visibility}
                    //   positionInTree={positionInTree}
                    //   cellRefs={cellRefs}
                    //   fileType={fileType}
                    // />
                    _jsx(MultipleEntryOptions, { system: system, vss: vss, NAPGenericStore: NAPGenericStore, molstar: molstar, visibility: visibility, pyodide: pyodide })) : isovalueSelection ? (_jsx(VolumeOptions, { vss: vss, molstar: molstar, system: system, positionInTree: positionInTree, visibility: visibility })) : trajectoryRef ? (_jsx(TrajectoryFrameControls, { vss: vss, molstar: molstar, trajCellRef: trajectoryRef, visibility: visibility, positionInTree: positionInTree, defaultFrame: (_a = system.trajectoryParameters) === null || _a === void 0 ? void 0 : _a.frame, defaultStep: (_b = system.trajectoryParameters) === null || _b === void 0 ? void 0 : _b.step, defaultSmooth: (_c = system.trajectoryParameters) === null || _c === void 0 ? void 0 : _c.smooth, handleDisplayMultipleFrames: handleDisplayMultipleFrames, handleSelectFrameMode: _handleSelectFrameMode, handleSmoothTraj: handleSmoothTraj, trajectoryMode: (_d = system.trajectoryParameters) === null || _d === void 0 ? void 0 : _d.mode, wait: wait })) : null }))] })) })));
}
