var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Dialog, DialogTitle, DialogContent, IconButton, List, ListItem, ListItemIcon, ListItemText, Divider, Box, Button, DialogActions, CircularProgress, DialogContentText, FormHelperText, Grid, } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { PasswordReset } from "../ResetPassword/ResetPassword";
import { executeWithTokenRefresh } from "../executeWithTokenRefresh";
import { DeleteAccount } from "../DeleteAccount/DeleteAccount";
function UserData({ userInfo }) {
    return (_jsxs(List, Object.assign({ dense: true, sx: { "& .MuiListItem-root": { pl: 0 } } }, { children: [_jsxs(ListItem, { children: [_jsx(ListItemIcon, Object.assign({ style: { minWidth: "30px" } }, { children: _jsx(AccountCircleIcon, {}) })), _jsx(ListItemText, { primary: userInfo.username })] }), _jsxs(ListItem, { children: [_jsx(ListItemIcon, Object.assign({ style: { minWidth: "30px" } }, { children: _jsx(MailOutlineIcon, {}) })), _jsx(ListItemText, { primary: userInfo.email })] })] })));
}
function UserMenu({ userInfo, setMode, }) {
    const [waitingPassword, setWaitingPassword] = useState(false);
    const [waitingDelete, setWaitingDelete] = useState(false);
    const [error, setError] = useState("");
    const handleResetPasswordClick = () => __awaiter(this, void 0, void 0, function* () {
        setWaitingPassword(true);
        setError("");
        const response = yield executeWithTokenRefresh(() => fetch(`${window.location.origin}/v2/auth/get-password-reset-code`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                mail: userInfo.email,
            },
        }), true);
        if (response.success) {
            setTimeout(() => setWaitingPassword(false), 200);
            setMode("password-reset");
        }
        else {
            setWaitingPassword(false);
            const errorMsg = response.error
                ? response.error
                : "Something went wrong. Please try again or contact support if the issue continues.";
            setError(errorMsg);
        }
    });
    const handleDeleteAccountClick = () => __awaiter(this, void 0, void 0, function* () {
        setWaitingDelete(true);
        setError("");
        const response = yield executeWithTokenRefresh(() => fetch(`${window.location.origin}/v2/auth/get-account-deletion-code`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                mail: userInfo.email,
            },
        }), true);
        if (response.success) {
            setTimeout(() => setWaitingDelete(false), 200);
            setMode("delete");
        }
        else {
            setWaitingDelete(false);
            const errorMsg = response.error
                ? response.error
                : "Something went wrong. Please try again or contact support if the issue continues.";
            setError(errorMsg);
        }
    });
    return (_jsxs(_Fragment, { children: [_jsx(UserData, { userInfo: userInfo }), _jsx(Box, Object.assign({ sx: { my: 1 } }, { children: _jsx(Divider, {}) })), _jsxs(Grid, Object.assign({ container: true, direction: "column", justifyContent: "center" }, { children: [!!error && (_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(DialogContentText, Object.assign({ component: FormHelperText, textAlign: "center", error: true }, { children: error })) }))), _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsxs(DialogActions, Object.assign({ sx: {
                                justifyContent: "center",
                                display: "flex",
                                flexDirection: "column",
                                gap: 1,
                            } }, { children: [_jsx(Button, Object.assign({ onClick: handleResetPasswordClick, variant: "contained", disabled: waitingPassword || waitingDelete, endIcon: waitingPassword ? _jsx(CircularProgress, { size: 20 }) : undefined }, { children: "Reset password" })), _jsx(Button, Object.assign({ onClick: handleDeleteAccountClick, disabled: waitingPassword || waitingDelete, endIcon: waitingDelete ? _jsx(CircularProgress, { size: 20 }) : undefined, sx: {
                                        color: "red",
                                        "&:hover": { backgroundColor: "rgb(255, 0, 0, 0.1)" },
                                    } }, { children: "Delete Account" }))] })) }))] }))] }));
}
export function UserSettingsDialog({ open, handleClose, userInfo, }) {
    const [mode, setMode] = useState("menu");
    const _handleClose = () => {
        setMode("menu");
        handleClose();
    };
    const handleSuccess = () => {
        setMode("menu");
    };
    let dialogTitle;
    let dialogContent;
    switch (mode) {
        case "password-reset":
            dialogTitle = "Reset Password";
            dialogContent = (_jsx(PasswordReset, { onSuccess: handleSuccess, authenticatedEmail: userInfo.email }));
            break;
        case "delete":
            dialogTitle = "Delete Your Account";
            dialogContent = (_jsx(DeleteAccount, { onSuccess: handleSuccess, userEmail: userInfo.email }));
            break;
        default:
            dialogTitle = "Account Settings";
            dialogContent = _jsx(UserMenu, { userInfo: userInfo, setMode: setMode });
            break;
    }
    return (_jsxs(Dialog, Object.assign({ open: open, onClose: _handleClose, sx: { "& .MuiPaper-root": { p: 1 } } }, { children: [_jsxs(DialogTitle, Object.assign({ variant: "h5", textAlign: "center" }, { children: [dialogTitle, _jsx(IconButton, Object.assign({ "aria-label": "close", onClick: _handleClose, sx: {
                            position: "absolute",
                            right: 4,
                            top: 4,
                        } }, { children: _jsx(CloseIcon, {}) }))] })), _jsx(DialogContent, { children: dialogContent })] })));
}
