// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { ChonkyActions } from "../action-definitions/index";
import { ChonkyIconPlaceholder } from "../components/internal/ChonkyIconPlaceholder";
export const defaultConfig = {
    fileActions: null,
    onFileAction: null,
    thumbnailGenerator: null,
    doubleClickDelay: 300,
    disableSelection: false,
    disableDefaultFileActions: false,
    disableDragAndDrop: false,
    disableDragAndDropProvider: false,
    defaultSortActionId: ChonkyActions.SortFilesByName.id,
    defaultFileViewActionId: ChonkyActions.EnableGridView.id,
    clearSelectionOnOutsideClick: true,
    iconComponent: ChonkyIconPlaceholder,
    darkMode: false,
    i18n: {},
};
export const setChonkyDefaults = (config) => {
    for (const key of Object.keys(defaultConfig)) {
        if (key in config) {
            defaultConfig[key] = config[key];
        }
    }
};
